import { Container, makeStyles, Grid, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import BaseContainer from '../../containers/BaseContainer/BaseContainer';
import { useDispatch, useSelector } from 'react-redux';
import ListAndTiledView from '../../containers/ListAndTiledView/ListAndTiledView';
import { clearBillerForm, getBiller, getBillerList } from '../../store/Biller/BillerAction';
import BillerListTable from '../../containers/BillerListTable/BillerListTable';
import BillerListTiled from '../../containers/BillerListTiled/BillerListTiled';
import { clearPayment, clearPaymentVerified } from '../../store/Payment/PaymentAction';
import { getBillerCount, removeTransactionId } from '../../utils/Auth';
import ModalDialog from '../../containers/ModalDialog/ModalDialog';
import { clearCategoryBillers, getCategoryBillers, getCategoryList } from '../../store/Category/CategoryAction';
import CategoryListHorizontal from '../../containers/CategoryListHorizontal/CategoryListHorizontal';

const myStyles = makeStyles((theme) => ({
  merchantBillerContainter: {
    paddingBottom: '60px',
  },
  paddingTop: {
    paddingTop: 20,
  },
  loadingContainer: {
    textAlign: 'center',
    paddingTop: 20,
  },
  loading: {
    color: '#F16122',
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MerchantBiller = (props) => {
  const classes = myStyles();
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const [categoryType, setCategoryType] = useState('');
  const categoryList = useSelector((state) => state.category.categoryList);
  const listTypeQuery = query.get('listType');
  const [viewType, setViewType] = useState('list');
  const [searchText, setSearchText] = useState('');
  const allMerchantBillers = useSelector((state) => state.biller.billerList);
  const errorAllMerchantBillers = useSelector((state) => state.biller.error);
  const categoryBillers = useSelector((state) => state.category.categoryBillers);
  const errorCategoryBillers = useSelector((state) => state.category.error);
  const [billerList, setBillerList] = useState([]);
  const [biller, setBiller] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [isCHClick, setCHClick] = useState(false);

  const handlerView = (type) => {
    setSearchText('');
    setViewType(type);
  };

  const handleSearchChange = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    if (listTypeQuery === null) history.push('/v1/0?msg=Please check that URL is correct and and try again.');
  }, []);

  useEffect(() => {
    if (listTypeQuery && !isCHClick) {
      setSearchText('');
      dispatch(clearPaymentVerified());
      dispatch(clearPayment());
      dispatch(clearBillerForm());
      removeTransactionId();
      setBillerList([]);
      switch (listTypeQuery) {
        case 'category':
          if (query.get('type') !== null && query.get('categoryId') !== null) {
            setCategoryType(query.get('type'));
            if (getBillerCount() > 20) {
              dispatch(getCategoryBillers(query.get('categoryId')));
            }
          } else {
            history.push('/v1/0?msg=Please check that URL is correct and and try again.');
          }
          break;
        case 'all':
          setCategoryType('');
          if (allMerchantBillers && allMerchantBillers.length !== 0) {
            setBillerList([...allMerchantBillers]);
          } else {
            setBillerList([]);
            dispatch(getBillerList());
            dispatch(getCategoryList());
          }
          break;
        default:
          history.push('/v1/0?msg=Please check that URL is correct and and try again.');
          break;
      }
    }
  }, [listTypeQuery]);

  useEffect(() => {
    if (allMerchantBillers && allMerchantBillers.length !== 0 && listTypeQuery === 'all') {
      setCategoryType('');
      setBillerList([...allMerchantBillers]);
    }
  }, [allMerchantBillers, listTypeQuery]);

  useEffect(() => {
    if (categoryBillers && listTypeQuery === 'category') {
      if (Object.keys(categoryBillers).length > 0) setBillerList([...categoryBillers.billers]);
    }
  }, [categoryBillers]);

  const handlerGetBiller = (billerData) => {
    setBiller(billerData);
    dispatch(clearBillerForm());
    dispatch(getBiller(billerData));
    history.push('/v1/biller-form?billerCode=' + billerData.code);
  };

  const handleCategoryClick = (data) => {
    if (billerList.length > 0 || errorAllMerchantBillers.flag) {
      setCHClick(true);
      setBillerList([]);
      dispatch(clearCategoryBillers());
      dispatch(getCategoryBillers(data.category_id));
      setCategoryType(data.category);
      history.push('/v1/merchant-biller?listType=category&type=' + data.category + '&categoryId=' + data.category_id);
    }
  };

  useEffect(() => {
    setOpenModal(false);
    if (errorAllMerchantBillers.flag && listTypeQuery === 'all') {
      setOpenModal(true);
    }
    if (errorCategoryBillers.flag && listTypeQuery === 'category') {
      setOpenModal(true);
    }
  }, [errorAllMerchantBillers, errorCategoryBillers]);

  const handleModalClose = () => { };

  const handleClear = (action) => {
    if (action) {
      setCategoryType('');
      setBillerList([...allMerchantBillers]);
      history.push('/v1/merchant-biller?listType=all');
      // switch (listTypeQuery) {
      //   case 'category':
      //     setBillerList([...categoryBillers.billers]);
      //     break;
      //   case 'all':
      //     setBillerList([...allMerchantBillers]);
      //     break;
      // }
    }
  };

  return (
    <>
      <BaseContainer contentWidth={'lg'} showFooter={true}>
        <Container maxWidth='lg' className={classes.merchantBillerContainter}>
          <ListAndTiledView
            enableSearchFocus={true}
            viewType={(type) => {
              handlerView(type);
            }}
            searchClear={(action) => {
              handleClear(action);
            }}
            searchValue={(value) => {
              handleSearchChange(value);
            }}
            showClear={true}
          />
          {/* {categoryList && categoryBillers ? (
              <> */}
          <CategoryListHorizontal handleCategoryClick={handleCategoryClick} category={categoryType} />
          <Grid container className={classes.paddingTop}>
            {viewType === 'list' ? (
              <Grid item xs={12} sm={12}>
                <BillerListTable handlerGetBiller={handlerGetBiller} selectedBiller={biller} searchBiller={searchText} billerList={billerList} />
              </Grid>
            ) : (
              <Grid item xs={12} sm={12}>
                <BillerListTiled handlerGetBiller={handlerGetBiller} selectedBiller={biller} searchBiller={searchText} billerList={billerList} />
              </Grid>
            )}
          </Grid>
          {/* </>
            ) : (
              <Container className={classes.loadingContainer}>
                <CircularProgress className={classes.loading} />{' '}
              </Container>
            )} */}
        </Container>
        <ModalDialog modalOpen={openModal} addBtnCloseCallback={true} handleModalClose={handleModalClose} />
      </BaseContainer>
    </>
  );
};

export default MerchantBiller;
