import { makeStyles, Grid, Typography, Card, Container, Button, Tooltip, Snackbar, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import pendingEmailStatus from '../../assets/image-logo/pending-email-status.png';
import Constants from '../../utils/Constants';
import clsx from 'clsx';
import { Alert } from '@material-ui/lab';
import bayadExpressLogo from '../../assets/image-logo/bayad-express-logo.png';
import failedEmailStatus from '../../assets/image-logo/failed-email-status.png';
import successEmailStatus from '../../assets/image-logo/success-email-status.png';
import { useDispatch, useSelector } from 'react-redux';
import { getCommaSeparatedTwoDecimalsNumber } from '../BillerFields/Controls/Controls';
import { useHistory, useLocation } from 'react-router-dom';
import { getTransactionId, terminateSession } from '../../utils/Auth';
import { clearTransaction } from '../../store/Transaction/TransactionAction';
import Loader from '../Loader/Loader';

const myStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    textAlign: 'center',
  },
  container: {
    // backgroundImage: `url(${containerBackground})`,
    // minHeight: '100%',
    // maxWidth: '100%',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
  },
  bold600: {
    fontWeight: 600,
  },
  bold500: {
    fontWeight: 500,
  },
  opacity09: {
    opacity: 0.9,
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  gridContent: {
    [theme.breakpoints.down('xs')]: {
      width: 'inherit',
    },
  },
  card: {
    boxShadow: '0px 1px 6px rgb(0 0 0 / 23%)',
    borderRadius: '8px',
    paddingTop: 20,
    padding: 20,
  },
  cardContainer: {
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 550,
    width: 550,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 30,
      paddingRight: 30,
      width: 'inherit',
    },
  },
  scrollBar: {
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E05A29 !important',
      borderRadius: '21px',
    },
    maxHeight: '125px',
    height: '125px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  cardFooter: {
    maxWidth: 600,
    width: 600,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  btnAction: {
    letterSpacing: '1px',
    font: 'normal normal normal 13px/20px Poppins',
    width: '224px',
    height: '39px',
    borderRadius: '21px',
    backgroundColor: '#E05A29 !important',
    color: 'white',
  },
  mail: {
    color: 'black',
  },
  transcationLogo: {},
  alert: {
    backgroundColor: '#F16122',
    color: 'white',
    '& .MuiAlert-icon': {
      color: 'white',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const TransactionDetails = (props) => {
  const classes = myStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const [openCopyTooltip, setOpenCopyTooltip] = useState(false);
  const [transcationStatus, setTransactionStatus] = useState(Constants.status_success);
  const [transcationLogo, setTransactionLogo] = useState(null);
  const transactionInquiry = useSelector((state) => state.transaction.transactionInquiry);
  const [titleStatusDesc, setTitleStatusDesc] = useState('');
  const [paymentList, setPaymentList] = useState([]);
  const status = query.get('status');
  const transactionId = getTransactionId();

  useEffect(() => {
    if (transactionId === null) history.push('/v1/0?msg=Please check that URL is correct and and try again.');
  }, []);

  useEffect(() => {
    if (transactionInquiry) {
      const transactionStatus = [Constants.status_pending_posting, Constants.status_completed_payment, Constants.status_failed_payment];
      if (!transactionStatus.includes(status) || status === null || status !== transactionInquiry.status) {
        terminateSession();
        history.push('/v1/0?msg=Please check that URL is correct and and try again.');
      }
      setPaymentList(transactionInquiry.biller_info);
      switch (transactionInquiry.status) {
        case Constants.status_pending_posting:
          setTransactionStatus(Constants.inquiry_in_progress);
          setTransactionLogo(pendingEmailStatus);
          setTitleStatusDesc(Constants.title_status_in_progress);
          break;
        case Constants.status_completed_payment:
          setTransactionStatus(Constants.inquiry_success);
          setTransactionLogo(successEmailStatus);
          setTitleStatusDesc(Constants.title_status_success);
          break;
        case Constants.status_failed_payment:
          setTransactionStatus(Constants.inquiry_failed);
          setTransactionLogo(failedEmailStatus);
          setTitleStatusDesc(Constants.title_status_failed);
          break;
      }
    }
  }, [transactionInquiry]);

  const handleCopyRefNumber = () => {
    setOpenCopyTooltip(true);
    const referenceNumber = 'Reference Number: ' + transactionInquiry.transaction_reference_number;
    navigator.clipboard.writeText(referenceNumber);
  };

  const handleCopyClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenCopyTooltip(false);
  };

  const handleClose = () => {
    dispatch(clearTransaction());
    terminateSession();
    history.push('/v1/email-inquiry?transactionId=' + getTransactionId());
  };

  return (
    <>
      {transactionId !== null ? (
        <Container maxWidth={'xl'} className={classes.container}>
          <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2} className={classes.root}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <img className={classes.transcationLogo} src={transcationLogo} alt={'transcationLogo'}></img>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant='h6' className={clsx(classes.bold500, classes.opacity09)}>
                {transcationStatus}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridContent}>
              <Grid container className={classes.cardContainer} direction='column' spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {transactionInquiry.status === Constants.status_pending_posting ? (
                    <Typography variant='subtitle2' className={clsx(classes.bold500, classes.textLeft)}>
                      Payment Confirmed! Your payment of PHP {getCommaSeparatedTwoDecimalsNumber(transactionInquiry.total_amount)} for{' '}
                      {transactionInquiry.biller_info[0].biller_name} is {titleStatusDesc}.
                    </Typography>
                  ) : transactionInquiry.status === Constants.status_completed_payment ? (
                    <Typography variant='subtitle2' className={clsx(classes.bold500, classes.textLeft)}>
                      Payment Confirmed! Your payment of PHP {getCommaSeparatedTwoDecimalsNumber(transactionInquiry.total_amount)} for{' '}
                      {transactionInquiry.biller_info[0].biller_name} has been {titleStatusDesc}.
                    </Typography>
                  ) : transactionInquiry.status === Constants.status_failed_payment ? (
                    <Typography variant='subtitle2' className={clsx(classes.bold500, classes.textLeft)}>
                      Your payment of PHP {getCommaSeparatedTwoDecimalsNumber(transactionInquiry.total_amount)} for{' '}
                      {transactionInquiry.biller_info[0].biller_name} was {titleStatusDesc}.
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
                <Card className={classes.card}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant='subtitle1' noWrap={true} className={clsx(classes.textLeft, classes.bold500)}>
                      Transaction Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                        <Typography variant='subtitle1'>Date and Time:</Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                        <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                          {transactionInquiry.transaction_date_time}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                        <Typography variant='subtitle1'>Reference No.:</Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                        <Tooltip title='Copy' placement='right'>
                          <Typography
                            variant='subtitle1'
                            noWrap={true}
                            className={clsx(classes.bold500, classes.pointer)}
                            onClick={handleCopyRefNumber}>
                            {transactionInquiry.transaction_reference_number}
                          </Typography>
                        </Tooltip>
                        <Snackbar
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          open={openCopyTooltip}
                          autoHideDuration={3000}
                          onClose={handleCopyClose}>
                          <Alert onClose={handleCopyClose} severity='info' sx={{ width: '100%' }} className={classes.alert}>
                            {Constants.copy_to_clipboard}
                          </Alert>
                        </Snackbar>
                      </Grid>
                      {/* <Box className={classes.scrollBar}> */}
                      {paymentList
                        ? paymentList.map((payment, index) => (
                            <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                                  <Typography variant='subtitle1'>Biller Name:</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                                  <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                                    {payment.biller_name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                                  <Typography variant='subtitle1'>Account Number:</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                                  <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                                    {payment.account_number}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                                  <Typography variant='subtitle1'>Amount</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                                  <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                                    PHP {getCommaSeparatedTwoDecimalsNumber(payment.amount)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                                  <Typography variant='subtitle1'>Biller Fee</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                                  <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                                    PHP {getCommaSeparatedTwoDecimalsNumber(payment.service_fee)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                                  <Typography variant='subtitle1'>Convenience Fee</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                                  <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                                    PHP {getCommaSeparatedTwoDecimalsNumber(payment.bank_fee)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))
                        : ''}
                      {/* </Box> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                        <Typography variant='subtitle1' className={classes.bold500}>
                          Total Amount:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                        <Typography variant='subtitle1' className={classes.bold500}>
                          PHP {getCommaSeparatedTwoDecimalsNumber(transactionInquiry.total_amount)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Grid item>
              <Container>
                <Grid container className={classes.cardFooter} direction='column' justifyContent='center' alignItems='center' spacing={3}>
                  <Grid item>
                    <Button variant='contained' color='primary' className={classes.btnAction} onClick={handleClose}>
                      Close
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2' className={classes.opacity09}>
                      For further assistance, reach out to <b>Bayad Express</b> by emailing
                      <a className={classes.mail} href='mailto:support@bayad.com'>
                        {' '}
                        support@bayad.com
                      </a>{' '}
                      or calling our hotline at +632 8672 5777
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img className={classes.bayadExpressLogo} src={bayadExpressLogo} alt='bayadExpressLogo'></img>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default TransactionDetails;
