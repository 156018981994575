import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { terminate } from '../store/Session/SessionAction';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const RedirectTo = (props) => {
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    if (query.get('exit')) dispatch(terminate());
    window.location.assign(query.get('q'));
  }, []);

  return <div></div>;
};

export default RedirectTo;
