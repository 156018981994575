import { makeStyles, Grid, Typography, Card, Container, Divider, Button, Tooltip, Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import successLogo from '../../assets/image-logo/success-logo.png';
import bayadExpressLogo from '../../assets/image-logo/bayad-express-logo.png';
import Constants from '../../utils/Constants';
import clsx from 'clsx';
import moment from 'moment';
import {
  AMOUNT,
  BANK_FEE,
  getBillerFeeTotal,
  getCommaSeparatedTwoDecimalsNumber,
  getConvenienceFeeTotal,
  getNumberTotal,
  getTotalAmount,
} from '../../containers/BillerFields/Controls/Controls';
import { useHistory } from 'react-router-dom';
import urlEncode from '../../utils/UrlEncode';
import { Alert } from '@material-ui/lab';

const myStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    textAlign: 'center',
  },
  container: {},
  card: {
    boxShadow: '0px 1px 6px rgb(0 0 0 / 23%)',
    borderRadius: '8px',
  },
  cardContainer: {
    padding: 30,
    maxWidth: 550,
    width: 550,
    [theme.breakpoints.down('xs')]: {
      padding: 30,
      width: 'inherit',
    },
  },
  cardFooter: {
    maxWidth: 600,
    width: 600,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    padding: '20px',
  },
  successLogo: {
    width: '70%',
    height: '70%',
  },
  bold600: {
    fontWeight: 600,
  },
  bold500: {
    fontWeight: 500,
  },
  opacity09: {
    opacity: 0.9,
  },
  bayadExpressLogo: {},
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  gridContent: {
    [theme.breakpoints.down('xs')]: {
      width: 'inherit',
    },
  },
  btnAction: {
    letterSpacing: '1px',
    font: 'normal normal normal 13px/20px Poppins',
    width: '224px',
    height: '39px',
    borderRadius: '21px',
    backgroundColor: '#E05A29 !important',
    color: 'white',
  },
  itemFooter: {
    opacity: 0.9,
  },
  scrollBar: {
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E05A29 !important',
      borderRadius: '21px',
    },
    maxHeight: '125px',
    height: '125px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  footerLogo: {
    verticalAlign: 'middle',
  },
  mail: {
    color: 'black',
  },
  alert: {
    backgroundColor: '#F16122',
    color: 'white',
    '& .MuiAlert-icon': {
      color: 'white',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));
const AcknowledgementSuccess = (props) => {
  const { transactionData, paymentList } = props;
  const history = useHistory();
  const classes = myStyles();
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [billerFee, setBillerFee] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subAmount, setSubAmount] = useState(0);

  useEffect(() => {
    //if (counter > 8) {
    setConvenienceFee(getConvenienceFeeTotal(paymentList, BANK_FEE));
    setBillerFee(getBillerFeeTotal(paymentList));
    setSubAmount(getNumberTotal(paymentList, AMOUNT));
    setTotalAmount(getTotalAmount(subAmount, convenienceFee, billerFee));
    //}
  });

  if (transactionData.status !== Constants.status_success) history.push('/v1/0?msg=Please check that URL is correct and and try again.');

  const backToExpress = () => {
    history.push(`/v1/redirect?${urlEncode('q', transactionData.expressUrl)}&exit=true`);
  };

  const [openCopyTooltip, setOpenCopyTooltip] = useState(false);

  const handleCopyRefNumber = () => {
    setOpenCopyTooltip(true);
    const referenceNumber = transactionData ? 'Reference Number:' + transactionData.transaction_reference_number : '';
    navigator.clipboard.writeText(referenceNumber);
  };

  const handleCopyClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenCopyTooltip(false);
  };

  return (
    <>
      <Container maxWidth={'xl'} className={classes.container}>
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2} className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <img className={classes.successLogo} src={successLogo} alt={'successLogo'}></img>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant='h6' className={clsx(classes.bold500, classes.opacity09)}>
              {Constants.success_title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridContent}>
            <Card className={classes.card}>
              <Grid container className={classes.cardContainer} direction='column' spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <img className={classes.bayadExpressLogo} src={bayadExpressLogo} alt='bayadExpressLogo'></img>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant='subtitle1' noWrap={true} className={clsx(classes.textLeft, classes.bold500)}>
                    Transaction Info :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                      <Typography variant='subtitle1'>Date and Time:</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                      <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                        {transactionData ? moment(transactionData.paid_at).format('LLL') : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                      <Typography variant='subtitle1'>Reference No.:</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                      <Tooltip title='Copy' placement='right'>
                        <Typography
                          variant='subtitle1'
                          noWrap={true}
                          className={clsx(classes.bold500, classes.pointer)}
                          onClick={handleCopyRefNumber}>
                          {transactionData ? transactionData.transaction_reference_number : ''}
                        </Typography>
                      </Tooltip>
                      <Snackbar
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        open={openCopyTooltip}
                        autoHideDuration={3000}
                        onClose={handleCopyClose}>
                        <Alert onClose={handleCopyClose} severity='info' sx={{ width: '100%' }} className={classes.alert}>
                          {Constants.copy_to_clipboard}
                        </Alert>
                      </Snackbar>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <Box className={classes.scrollBar}> */}
                  {paymentList
                    ? paymentList.map((payment, index) => (
                        <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                              <Typography variant='subtitle1'>Biller Name:</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                              <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                                {payment.biller_name}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                              <Typography variant='subtitle1'>Customer Account Number:</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                              <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                                {payment.account_number}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                              <Typography variant='subtitle1'>Amount</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                              <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                                PHP {getCommaSeparatedTwoDecimalsNumber(payment.amount)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                              <Typography variant='subtitle1'>Biller Fee</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                              <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                                PHP {getCommaSeparatedTwoDecimalsNumber(payment.service_fee)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                              <Typography variant='subtitle1'>Convenience Fee</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                              <Typography variant='subtitle1' noWrap={true} className={classes.bold500}>
                                PHP {getCommaSeparatedTwoDecimalsNumber(payment.bank_fee)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    : ''}
                  {/* </Box> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textLeft}>
                      <Typography variant='subtitle1' className={classes.bold500}>
                        Total Amount:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.textRight}>
                      <Typography variant='subtitle1' className={classes.bold500}>
                        PHP {paymentList ? getCommaSeparatedTwoDecimalsNumber(totalAmount) : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item>
            <Container>
              <Grid container className={classes.cardFooter} direction='column' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    data-testid={'AcknowledgementSuccessBtn'}
                    className={classes.btnAction}
                    onClick={backToExpress}>
                    Back to Bayad Express
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant='body2' className={classes.opacity09}>
                    For further assistance, reach out to Bayad Express by emailing
                    <a className={classes.mail} href='mailto:support@bayad.com'>
                      {' '}
                      support@bayad.com
                    </a>{' '}
                    or calling our hotline at +632 8672 5777
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          {/* <Grid item>
            <Grid container direction='column'>
              <Container>
                <span>
                  Powered By <img className={classes.footerLogo} src={bayadExpressLogo} alt={'bayadExpressLogo'}></img>
                </span>
              </Container>
            </Grid>
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
};

export default AcknowledgementSuccess;
