import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PrivacyNotice from '../../components/PrivacyNotice/PrivacyNoticeModal';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

const myStyles = makeStyles((theme) => ({
  footerDiv1: {
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  footerDiv2: {
    [theme.breakpoints.down('xl')]: {
      display: 'block',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  footerContainer: {
    borderTop: 'solid 1px rgb(224 224 224)',
    backgroundColor: 'white',
    bottom: 0,
    position: 'fixed',
    padding: 15,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    fontWeight: 600,
    marginRight: 'auto',
  },
  privacyStatement: {
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: 'black',
      fontWeight: 600,
    },
    [theme.breakpoints.down('xl')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('lg')]: {
      textAlign: 'right',
    },
  },
  termsAndConditions: {
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: 'black',
      fontWeight: 600,
    },
    [theme.breakpoints.down('xl')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.down('lg')]: {
      textAlign: 'left',
    },
  },
}));

const Footer = () => {
  const classes = myStyles();
  const history = useHistory();

  const handlePrivatePolicy = () => {
    history.push('/v1/privacy-statement');
  };

  const handleTermsAndConditions = () => {
    history.push('/v1/terms-condition');
  };

  return (
    <>
      <Container maxWidth='xl' className={clsx(classes.footerContainer, classes.footerDiv1)}>
        <Grid container direction='row'>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Typography variant='body2' noWrap={true}>
              &nbsp;&nbsp; Bayad Express
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Typography variant='body2' align='right'>
              <span className={classes.privacyStatement} onClick={handlePrivatePolicy}>
                Privacy Statement
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className={classes.termsAndConditions} onClick={handleTermsAndConditions}>
                Terms & Conditions
              </span>
              &nbsp;&nbsp;
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth='xl' className={clsx(classes.footerContainer, classes.footerDiv2)}>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={5} sm={5} md={5} lg={5} onClick={handlePrivatePolicy}>
            <Typography variant='body2' className={classes.privacyStatement} data-testid='PrivacyStatement'>
              Privacy Statement
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
          <Grid item xs={5} sm={5} md={5} lg={5} onClick={handleTermsAndConditions}>
            <Typography variant='body2' className={classes.termsAndConditions} data-testid='TermsAndConditions'>
              Terms & Conditions
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <PrivacyNotice />
    </>
  );
};

export default Footer;
