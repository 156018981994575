import { BILLER_CONFIRMATION, CLEAR_ACCOUNT, CLEAR_PAYMENT, PAYMENT_LIST, VERIFY_ACCOUNT } from './PaymentTypes';

export const paymentList = (data) => {
  return {
    type: PAYMENT_LIST,
    data,
  };
};

export const clearPayment = () => {
  return {
    type: CLEAR_PAYMENT,
  };
};

export const verifyAccount = (billerCode, accountNumber, data) => {
  return {
    type: VERIFY_ACCOUNT,
    billerCode,
    accountNumber,
    data,
  };
};

export const billerConfirmation = (billerCode, billsPayId) => {
  return {
    type: BILLER_CONFIRMATION,
    billerCode,
    billsPayId,
  };
};

export const clearPaymentVerified = () => {
  return {
    type: CLEAR_ACCOUNT,
  };
};
