import { terminateSession } from '../../utils/Auth';
import { GET_SESSION, GET_SIGNATURE, TERMINATE_SESSION } from './SessionTypes';

export const verifyUrl = (id, name, timestamp, data, signature) => {
  return {
    type: GET_SESSION,
    id,
    name,
    timestamp,
    data,
    signature,
  };
};

export const terminate = () => {
  terminateSession();
  return {
    type: TERMINATE_SESSION,
  };
};

export const getSignature = (id, name, data) => {
  return {
    type: GET_SIGNATURE,
    id,
    name,
    data,
  };
};
