import React, { useEffect, useState } from 'react';
import holdOnLogo from '../../assets/image-logo/hold-on.png';
import { useHistory, useLocation } from 'react-router-dom';
import {
  clearCookies,
  getBillerCount,
  getSessionId,
  getTimestamp,
  getTokenData,
  removeTransactionId,
  setPrivacyNoticeDisplay,
  storeBillerCount,
  storeMerchantClientId,
  storeMerchantCode,
  storeMerchantData,
  storeMerchantId,
  storeMerchantName,
  storeTimestamp,
  terminateSession,
} from '../../utils/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { terminate, verifyUrl } from '../../store/Session/SessionAction';
import { getToken } from '../../store/Token/TokenAction';
import Loader from '../../containers/Loader/Loader';
import ModalDialog from '../../containers/ModalDialog/ModalDialog';
import Constants from '../../utils/Constants';
import { clearPayment, clearPaymentVerified } from '../../store/Payment/PaymentAction';
import { clearTransaction } from '../../store/Transaction/TransactionAction';
import jwtDecode from 'jwt-decode';
import { clearBillerForm, getBillerList } from '../../store/Biller/BillerAction';
import PrivacyNotice from '../PrivacyNotice/PrivacyNoticeModal';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ExpressPay = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const sessionData = useSelector((state) => state.session.data);
  const tokenData = useSelector((state) => state.token.data);
  const [openModal, setOpenModal] = useState(false);
  const [proceedExpress, setProceedExpress] = useState(false);
  const allMerchantBillers = useSelector((state) => state.biller.billerList);

  useEffect(() => {
    const timestamp = query.get('timestamp');
    const timestampStored = getTimestamp('timestamp');
    if (timestamp !== null) {
      if (timestampStored !== undefined && timestamp !== timestampStored) {
        dispatch(terminate());
        setPrivacyNoticeDisplay(false);
      }
    }
  }, []);

  useEffect(() => {
    const backToExpress = query.get('backToExpress');
    if (backToExpress === null) {
      setPrivacyNoticeDisplay(true);
    } else if (backToExpress === 'true') {
      setPrivacyNoticeDisplay(false);
      setProceedExpress(true);
    }
  }, []);

  useEffect(() => {
    const id = query.get('id');
    const name = query.get('name');
    const timestamp = query.get('timestamp');
    const data = query.get('data');
    const signature = query.get('signature');
    const sessionStored = getSessionId();
    const tokenStored = getTokenData();
    const disableMaintenance = process.env.REACT_APP_DISABLE_MAINTENANCE
    if (process.env.REACT_APP_MAINTENANCE_MODE == 1 && !disableMaintenance.includes(name)) {
      history.push('/v1/maintenance');
    } else {
      if (proceedExpress) {
        if (!sessionStored) {
          clearCookies();
          storeMerchantData(data);
          storeMerchantClientId(id);
          storeTimestamp(timestamp);
          dispatch(verifyUrl(id, name, timestamp, data, signature));
        }
        if (sessionStored && !tokenStored) {
          if (sessionData.sessionExist) {
            setOpenModal(true);
            terminateSession();
            clearCookies();
          } else {
            storeMerchantCode(jwtDecode(sessionData.generatedCode).code);
            storeMerchantName(jwtDecode(sessionData.generatedCode).name);
            storeMerchantId(jwtDecode(sessionData.generatedCode).id);
            storeBillerCount(jwtDecode(sessionData.generatedCode).count);
            //storeBillerCount(20);
            setOpenModal(false);
            dispatch(getToken(id, sessionData.sessionId));
          }
        }
        if (tokenStored) {
          dispatch(clearPaymentVerified());
          dispatch(clearPayment());
          dispatch(clearBillerForm());
          dispatch(clearTransaction());
          removeTransactionId();
          //history.push('/v1/biller-category');
          //if (allMerchantBillers && allMerchantBillers.length !== 0) {
          if (getBillerCount() >= 20) {
            //if (allMerchantBillers.length > 20) {
            history.push('/v1/biller-category');
          } else {
            history.push('/v1/merchant-biller?listType=all');
          }
          //}
        }
      }
    }
  }, [dispatch, history, query, sessionData, tokenData, proceedExpress, allMerchantBillers]);

  const handleAgree = (action) => {
    if (action) {
      setPrivacyNoticeDisplay(false);
      setProceedExpress(true);
    } else {
      setProceedExpress(false);
      if (window.confirm('Are you sure you want to proceed?')) {
        dispatch(terminate());
        history.push(`/v1/1?msg=We regret to inform you that you aren't allowed to proceed. Please make sure to agree. Thank you.`);
      }
    }
  };

  return (
    <>
      <Loader />
      <PrivacyNotice handleAgree={handleAgree} />
      <ModalDialog
        modalLogo={holdOnLogo}
        modalTitle={Constants.multiple_browser_title}
        modalContent={Constants.multiple_browser_content}
        modalContent2={Constants.multiple_browser_content2}
        modalOpen={openModal}
        showBtnClose={false}
      />
    </>
  );
};

export default ExpressPay;
