import SMARTLogo from '../assets/biller-logo/SMART.png';
import ETRIPLogo from '../assets/biller-logo/ETRIP.png';
import MWSINLogo from '../assets/biller-logo/MWSIN.png';
import RTI01Logo from '../assets/biller-logo/RTI01.png';
import PLDT6Logo from '../assets/biller-logo/PLDT6.png';
import CGNALLogo from '../assets/biller-logo/CGNAL.png';
import CNVRGLogo from '../assets/biller-logo/CNVRG.png';
import BAYANLogo from '../assets/biller-logo/BAYAN.png';
import CARFPLogo from '../assets/biller-logo/CARFP.png';
import CLPCOLogo from '../assets/biller-logo/CLPCO.png';
import MECORLogo from '../assets/biller-logo/MECOR.png';
import PWCORLogo from '../assets/biller-logo/PWCOR.png';
import RCTELLogo from '../assets/biller-logo/RCTEL.png';
import RFID1Logo from '../assets/biller-logo/RFID1.png';
import SEZCOLogo from '../assets/biller-logo/SEZCO.png';
import SKY01Logo from '../assets/biller-logo/SKY01.png';
import STLCWLogo from '../assets/biller-logo/STLCW.png';
import VIECOLogo from '../assets/biller-logo/VIECO.png';
import ISLC1Logo from '../assets/biller-logo/ISLC1.png';
import MLIFELogo from '../assets/biller-logo/MLIFE.png';
import SLIFELogo from '../assets/biller-logo/SLIFE.png';
import ILEC2Logo from '../assets/biller-logo/ILEC2.png';
import ILECOLogo from '../assets/biller-logo/ILECO.png';
import SWSCOLogo from '../assets/biller-logo/SWSCO.png';
import MAMEMLogo from '../assets/biller-logo/MAMEM.png';
import LOPCILogo from '../assets/biller-logo/LOPCI.png';
import ADMSNLogo from '../assets/biller-logo/ADMSN.png';
import PRULILogo from '../assets/biller-logo/PRULI.png';
import MCWD1Logo from '../assets/biller-logo/MCWD1.png';
import STMWDLogo from '../assets/biller-logo/STMWD.png';
import TWDISLogo from '../assets/biller-logo/TWDIS.png';
import BTCO1Logo from '../assets/biller-logo/BTCO1.png';
import SLWI1Logo from '../assets/biller-logo/SLWI1.png';
import MECOPLogo from '../assets/biller-logo/MECOP.png';
import CELCOLogo from '../assets/biller-logo/CELCO.png';
import MCARELogo from '../assets/biller-logo/MCARE.png';
import BNECOLogo from '../assets/biller-logo/BNECO.png';
import APECSLogo from '../assets/biller-logo/APECS.png';
import EQPMCLogo from '../assets/biller-logo/EQPMC.png';
import MWCOMLogo from '../assets/biller-logo/MWCOM.png';
import HMRKILogo from '../assets/biller-logo/HMRKI.png';
import SSS01Logo from '../assets/biller-logo/SSS01.png';
import SPLANLogo from '../assets/biller-logo/SPLAN.png';
import BPWWILogo from '../assets/biller-logo/BPWWI.png';
import MCLACLogo from '../assets/biller-logo/MCLAC.png';
import PILAMLogo from '../assets/biller-logo/PILAM.png';
import DVOLTLogo from '../assets/biller-logo/DVOLT.png';
import LARC1Logo from '../assets/biller-logo/LARC1.png';
import INEC1Logo from '../assets/biller-logo/INEC1.png';
import LGNWCLogo from '../assets/biller-logo/LGNWC.png';
import CRMWDLogo from '../assets/biller-logo/CRMWD.png';
import GREPALogo from '../assets/biller-logo/GREPA.png';
import LCWD1Logo from '../assets/biller-logo/LCWD1.png';
import HWMCSLogo from '../assets/biller-logo/HWMCS.png';
import AVONCLogo from '../assets/biller-logo/AVONC.png';
import AECORLogo from '../assets/biller-logo/AECOR.png';
import GHWSILogo from '../assets/biller-logo/GHWSI.png';
import RYLCVLogo from '../assets/biller-logo/RYLCV.png';

export default {
  SMART: SMARTLogo,
  ETRIP: ETRIPLogo,
  MWSIN: MWSINLogo,
  RTI01: RTI01Logo,
  PLDT6: PLDT6Logo,
  CGNAL: CGNALLogo,
  CNVRG: CNVRGLogo,
  BAYAN: BAYANLogo,
  CARFP: CARFPLogo,
  CLPCO: CLPCOLogo,
  MECOR: MECORLogo,
  PWCOR: PWCORLogo,
  RCTEL: RCTELLogo,
  RFID1: RFID1Logo,
  SEZCO: SEZCOLogo,
  SKY01: SKY01Logo,
  STLCW: STLCWLogo,
  VIECO: VIECOLogo,
  ISLC1: ISLC1Logo,
  MLIFE: MLIFELogo,
  SLIFE: SLIFELogo,
  ILEC2: ILEC2Logo,
  ILECO: ILECOLogo,
  SWSCO: SWSCOLogo,
  MAMEM: MAMEMLogo,
  LOPCI: LOPCILogo,
  ADMSN: ADMSNLogo,
  PRULI: PRULILogo,
  MCWD1: MCWD1Logo,
  STMWD: STMWDLogo,
  TWDIS: TWDISLogo,
  BTCO1: BTCO1Logo,
  SLWI1: SLWI1Logo,
  MECOP: MECOPLogo,
  CELCO: CELCOLogo,
  MCARE: MCARELogo,
  BNECO: BNECOLogo,
  APECS: APECSLogo,
  EQPMC: EQPMCLogo,
  MWCOM: MWCOMLogo,
  HMRKI: HMRKILogo,
  SSS01: SSS01Logo,
  SPLAN: SPLANLogo,
  BPWWI: BPWWILogo,
  MCLAC: MCLACLogo,
  PILAM: PILAMLogo,
  DVOLT: DVOLTLogo,
  LARC1: LARC1Logo,
  INEC1: INEC1Logo,
  LGNWC: LGNWCLogo,
  CRMWD: CRMWDLogo,
  GREPA: GREPALogo,
  LCWD1: LCWD1Logo,
  HWMCS: HWMCSLogo,
  AVONC: AVONCLogo,
  AECOR: AECORLogo,
  GHWSI: GHWSILogo,
  RYLCV: RYLCVLogo,
};
