export const VERIFY_URL = '/verify-url';
export const SIGNATURE = '/generate-signature';
export const AUTHENTICATE = '/authenticate';
export const BILLERS = '/billers';
export const BILLER = '/biller';
export const BILLER_FORM = '/biller-form';
export const VERIFY_ACCOUNT = '/verify-account';
export const BILLER_CONFIRMATION = '/biller-confirmation';
export const TRANSACTION = '/transaction';
export const PAYMENT_GATEWAY = '/payment-gateway';
export const PAYMENT_ACKNOWLEDGEMENT = '/payment-acknowledgement';
export const PAYMENT_INQUIRY = '/payment-inquiry';
export const CATEGORY = '/biller-categories';

export const getUrlStatus = (url) => {
  let segments = url.split('/');
  let lastSegment = segments.pop() || segments.pop();
  return lastSegment.toUpperCase();
};

export const getUrlTransactionId = (transactionId) => {
  let result = transactionId.substring(0, transactionId.indexOf('?')) || transactionId;
  return result;
};

export const checkUrlContains = (value) => {
  return window.location.toString().includes(value);
};
