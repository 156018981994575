import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';

const myStyles = makeStyles((theme) => ({
  iconArrowRight: {
    cursor: 'pointer',
    color: '#979797',
    verticalAlign: 'middle',
  },
  bold: {
    fontWeight: 600,
  },
  topGrid: {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 87%), 0px 1px 1px 0px rgb(0 0 0 / 87%), 0px 1px 3px 0px rgb(0 0 0 / 87%)',
  },
  backItem: {
    textAlign: 'center',
  },
  backIcon: {
    //verticalAlign: 'middle',
    //display: 'inline-flex',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      fontWeight: 600,
    },
  },
  backDiv: {
    //position: 'absolute',
    textAlign: 'left',
  },
  backLabel: {
    verticalAlign: 'middle',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const BillerForm = (props) => {
  const { backRedirect, headerTitle, disableBack = false, addBackBtnCallback = false, handleBackBtn } = props;
  const classes = myStyles();
  const history = useHistory();

  const handlerBackButton = () => {
    if (!disableBack) history.push(`/v1${backRedirect}`);
    if (addBackBtnCallback) handleBackBtn(true);
  };

  return (
    <>
      <Container disableGutters={true}>
        <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.backItem}>
            <div className={classes.backDiv} onClick={handlerBackButton}>
              <Typography variant='h6' className={classes.backIcon}>
                <KeyboardArrowLeftSharpIcon className={classes.iconArrowRight} fontSize='large' onClick={handlerBackButton} />{' '}
                <span className={classes.backLabel} onClick={handlerBackButton}>
                  Back
                </span>
              </Typography>
            </div>
            <Typography variant='h6' className={classes.bold}>
              {headerTitle}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default BillerForm;
