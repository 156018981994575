import api from '../../utils/Api';
import { BILLER_CONFIRMATION, VERIFY_ACCOUNT } from '../../utils/ApiUrl';

export const verifyAccount = async (billerCode, accountNumber, data) => {
  try {
    let response = await api({
      method: 'POST',
      url: `${VERIFY_ACCOUNT}`,
      data: {
        billerCode,
        accountNumber,
        ...data,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const billerConfirmation = async (billerCode, billsPayId) => {
  try {
    let response = await api({
      method: 'POST',
      url: `${BILLER_CONFIRMATION}`,
      data: {
        billerCode,
        billsPayId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
