import 'date-fns';
import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {
  DATE,
  dateFieldFormat,
  formattedDateValue,
  INVALID_DATE,
  INVALID_DATE_MSG,
  INVALID_YEAR_MSG,
  removeWhiteSpace,
  YEAR_END_INVALID,
  YEAR_START_INVALID,
} from './Controls';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import dot from 'dot-object';

const myStyles = makeStyles((theme) => ({
  cssOutlinedInput: {
    //color: '#F16122',
  },
  notchedOutline: {
    borderColor: 'gray !important',
  },
  input: {
    '&::placeholder': {
      color: 'gray',
    },
  },
  inputLabel: {
    color: 'gray !important',
  },
  inputLabelError: {
    color: 'red !important',
  },
}));

const DateYearRange = (props) => {
  const classes = myStyles();
  const { field, control, handlerFieldFormValid, fieldFormValid, btnLoading, handlerFieldFormData, fieldFormData } = props;
  const [errorStart, isErrorStart] = useState(false);
  const [helperTextStart, setHelperTextStart] = useState('');
  const [errorEnd, isErrorEnd] = useState(false);
  const [helperTextEnd, setHelperTextEnd] = useState('');
  const dateFieldFConfig = dateFieldFormat(removeWhiteSpace(field.dateFormat));
  const [selectedYearStart, setYearStart] = useState(null);
  const [selectedYearEnd, setYearEnd] = useState(null);

  const validate = (value, fieldName) => {
    if (value.toUpperCase() === INVALID_DATE) {
      errorAndHelperText(fieldName, true, INVALID_YEAR_MSG);
      fieldFormValid[fieldName] = false;
    } else if (Object.entries(field.rules).length !== 0) {
      let comparedYearValue = comparedYear(fieldName).comparedYearValue;
      let comparedYearField = comparedYear(fieldName).comparedYearField;
      Object.keys(field.rules).map((prop) => {
        if (prop === 'required') {
          if (!value || value === '') {
            errorAndHelperText(fieldName, true, field.rules[prop]);
            fieldFormValid[fieldName] = false;
          } else {
            fieldFormValid[fieldName] = true;
          }

          if (!comparedYearValue || comparedYearValue === '') {
            errorAndHelperText(comparedYearField, true, field.rules[prop]);
            fieldFormValid[comparedYearField] = false;
          } else if (parseInt(value) > parseInt(comparedYearValue) && fieldName.includes('_year_start')) {
            errorAndHelperText(fieldName, true, YEAR_START_INVALID);
            fieldFormValid[fieldName] = false;
          } else {
            fieldFormValid[fieldName] = true;
          }
        } else {
          errorAndHelperText(fieldName, false, '');
          fieldFormValid[fieldName] = true;
        }
      });
    } else {
      errorAndHelperText(fieldName, false, '');
      fieldFormValid[fieldName] = true;
    }
    if (fieldFormValid[fieldName]) {
      fieldFormData[fieldName] = value;
    } else {
      fieldFormData[fieldName] = '';
    }

    if (fieldFormValid[field.name + '_year_start'] && fieldFormValid[field.name + '_year_end']) {
      fieldFormValid[field.name] = true;
      fieldFormData[field.name] = fieldFormData[field.name + '_year_start'] + '-' + fieldFormData[field.name + '_year_end'];
    } else {
      fieldFormValid[field.name] = false;
      fieldFormData[field.name] = '';
    }
    handlerFieldFormValid(fieldFormValid);
    handlerFieldFormData(fieldFormData);
  };

  const handleDateChange = (date, fieldName) => {
    fieldName.includes('_year_start') ? setYearStart(date) : setYearEnd(date);
    errorAndHelperText(fieldName, false, '');
    if (Object.entries(field.rules).length !== 0) validate(date, fieldName);
  };

  const errorAndHelperText = (fieldName, error, helperText) => {
    if (fieldName.includes('_year_start')) {
      isErrorStart(error);
      setHelperTextStart(helperText);
    } else if (fieldName.includes('_year_end')) {
      isErrorEnd(error);
      setHelperTextEnd(helperText);
    }
  };

  const comparedYear = (fieldName) => {
    let comparedYearValue = null;
    let comparedYearField = fieldName.includes('_year_start') ? field.name + '_year_end' : field.name + '_year_start';
    if (fieldName.includes('_year_start')) {
      comparedYearValue = selectedYearEnd;
    } else if (fieldName.includes('_year_end')) {
      comparedYearValue = selectedYearStart;
    }
    return { comparedYearValue: comparedYearValue, comparedYearField: comparedYearField };
  };

  const handleClearDate = (fieldName) => {
    let comparedYearField = comparedYear(fieldName).comparedYearField;
    errorAndHelperText(fieldName, false, '');
    fieldName.includes('_year_start') ? setYearStart(null) : setYearEnd(null);
    fieldFormValid[fieldName] = false;
    fieldFormData[fieldName] = '';
    fieldFormValid[field.name] = false;
    fieldFormData[field.name] = '';
    if (fieldName.includes('_year_start')) {
      setYearEnd(null);
      errorAndHelperText(comparedYearField, false, '');
      fieldFormValid[comparedYearField] = false;
      fieldFormData[comparedYearField] = '';
    }
    handlerFieldFormValid(fieldFormValid);
    handlerFieldFormData(fieldFormData);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent='space-around'>
          <Controller
            name={field.name + '_year_start'}
            control={control}
            initialFocusedDate={null}
            defaultValue={null}
            render={({ field: { onChange, ref, ...rest } }) => (
              <KeyboardDatePicker
                clearable
                openTo={dateFieldFConfig.openTo}
                views={dateFieldFConfig.views}
                format={dateFieldFConfig.datePickerFormat}
                disabled={btnLoading}
                inputVariant='outlined'
                margin='dense'
                fullWidth
                id={field.name + '_year_start'}
                label={field.label + ' Year Start'}
                data-testid={field.name + '_year_start'}
                onChange={(e) => {
                  if (e) {
                    onChange(formattedDateValue(e, field.dateFormat).dateDisplay);
                    handleDateChange(formattedDateValue(e, field.dateFormat).dateValue, field.name + '_year_start');
                  } else {
                    onChange(null);
                    handleClearDate(field.name + '_year_start');
                  }
                }}
                error={errorStart && Boolean(errorStart)}
                helperText={helperTextStart}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                {...rest}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.cssOutlinedInput,
                    notchedOutline: !errorStart ? classes.notchedOutline : '',
                    input: classes.input,
                  },
                }}
                InputLabelProps={{
                  className: errorStart ? classes.inputLabelError : classes.inputLabel,
                }}
              />
            )}
          />
          <Controller
            name={field.name + '_year_end'}
            control={control}
            initialFocusedDate={null}
            defaultValue={null}
            render={({ field: { onChange, ref, value, ...rest } }) => (
              <KeyboardDatePicker
                clearable
                value={selectedYearEnd}
                openTo={dateFieldFConfig.openTo}
                views={dateFieldFConfig.views}
                format={dateFieldFConfig.datePickerFormat}
                disabled={btnLoading || !selectedYearStart || selectedYearStart === null}
                inputVariant='outlined'
                margin='dense'
                minDate={selectedYearStart}
                fullWidth
                id={field.name + '_year_end'}
                data-testid={field.name + '_year_end'}
                label={field.label + ' Year End'}
                onChange={(e) => {
                  if (e) {
                    onChange(formattedDateValue(e, field.dateFormat).dateDisplay);
                    handleDateChange(formattedDateValue(e, field.dateFormat).dateValue, field.name + '_year_end');
                  } else {
                    onChange(null);
                    handleClearDate(field.name + '_year_end');
                  }
                }}
                error={errorEnd && Boolean(errorEnd)}
                helperText={helperTextEnd}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                {...rest}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.cssOutlinedInput,
                    notchedOutline: !errorEnd ? classes.notchedOutline : '',
                    input: classes.input,
                  },
                }}
                InputLabelProps={{
                  className: errorEnd ? classes.inputLabelError : classes.inputLabel,
                }}
              />
            )}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};
export default DateYearRange;
