import { Button, CircularProgress, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BaseContainer from '../../containers/BaseContainer/BaseContainer';
import BaseBackTop from '../../containers/BaseBackTop/BaseBackTop';
import PaymentList from '../../containers/PaymentList/PaymentList';
import { useDispatch, useSelector } from 'react-redux';
import {
  AMOUNT,
  getBillerFeeTotal,
  getCommaSeparatedTwoDecimalsNumber,
  getNumberTotal,
  getTotalAmount,
} from '../../containers/BillerFields/Controls/Controls';
import { getCheckoutUrl } from '../../store/Transaction/TransactionAction';
import urlEncode from '../../utils/UrlEncode';
import ModalDialog from '../../containers/ModalDialog/ModalDialog';
import { useHistory } from 'react-router-dom';
import { clearPayment } from '../../store/Payment/PaymentAction';
import { getBillerCount, storeTransactionId } from '../../utils/Auth';

const myStyles = makeStyles((theme) => ({
  container: {
    marginTop: '30px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  convenienceFeeLeft: {
    textAlign: 'left',
    font: 'normal normal normal 16px/30px Poppins',
    letterSpacing: '0.69px',
    color: ' #494949',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
  },
  convenienceFeeRight: {
    textAlign: 'right',
    font: 'normal normal normal 16px/30px Poppins',
    letterSpacing: '0.69px',
    color: ' #58595B',
    opacity: 1,
  },
  totalAmountLeft: {
    textAlign: 'left',
    font: 'normal normal normal 16px/30px Poppins',
    letterSpacing: '0.69px',
    color: ' #58595B',
    opacity: 1,
    fontWeight: '500',
  },
  totalAmountRight: {
    textAlign: 'right',
    font: 'normal normal normal 18px/32px Poppins',
    letterSpacing: '0.88px',
    color: ' #58595B',
    opacity: 1,
    fontWeight: '600',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1.5),
    backgroundColor: '#F16122',
    color: 'white',
  },
  infoIcon: {
    marginLeft: 5,
    color: '#E05A29',
  },
  btnCheckout: {
    backgroundColor: '#E05A29 !important',
    color: 'white',
    letterSpacing: '0.5px',
    marginBottom: '5px',
    textTransform: 'none',
  },
  btnCheckoutDisabled: {
    backgroundColor: '#f19978 !important',
    color: 'white',
    letterSpacing: '0.5px',
    marginBottom: '5px',
    textTransform: 'none',
  },
  btnLoading: {
    color: 'white',
  },
  totalAmountCaption: {},
  php: {
    fontSize: '0.8rem',
    verticalAlign: 'text-bottom',
    fontWeight: '700',
  },
}));

const PaymentSummary = (props) => {
  const dispatch = useDispatch();
  const classes = myStyles();
  const history = useHistory();
  const paymentList = useSelector((state) => state.payment.paymentList);
  const paymentConfirmed = useSelector((state) => state.payment.paymentConfirmed);
  const checkoutUrlData = useSelector((state) => state.transaction.checkoutUrl);
  const transactionError = useSelector((state) => state.transaction.error);
  const [getPaymentList, setPaymentList] = useState([]);
  const [billerFee, setBillerFee] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subAmount, setSubAmount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    if (paymentList) {
      setPaymentList(paymentList);
      setBillerFee(getBillerFeeTotal(paymentList));
      setSubAmount(getNumberTotal(paymentList, AMOUNT));
      setTotalAmount(getTotalAmount(subAmount, 0, billerFee));
    } else {
      if (getBillerCount() > 20) {
        history.push('/v1/biller-category');
      } else {
        history.push('/v1/merchant-biller?listType=all');
      }
    }
  }, [paymentList]);

  useEffect(() => {
    if (paymentList) {
      setTotalAmount(getTotalAmount(subAmount, 0, billerFee));
    }
  }, [subAmount]);

  const handleContinueToPay = () => {
    setBtnLoading(true);
    dispatch(getCheckoutUrl(paymentConfirmed.code, paymentConfirmed.bills_pay_data_id));
  };

  useEffect(() => {
    if (checkoutUrlData) {
      storeTransactionId(checkoutUrlData.transaction_id);
      setBtnLoading(false);
      history.push(`/v1/checkout-redirect?${urlEncode('q', checkoutUrlData.checkout_url)}&redirectCheckout=true`);
    }
  }, [checkoutUrlData]);

  useEffect(() => {
    if (transactionError.flag) {
      setBtnLoading(false);
      setOpenModal(true);
    }
  }, [transactionError]);

  const handleBackBtn = () => {
    if (!btnLoading) {
      dispatch(clearPayment());
      history.push('/v1/merchant-biller?listType=all');
    }
    // history.push('/v1/biller-category');
  };

  return (
    <>
      <BaseContainer disableBack={true} disableContactUs={true} disableFaq={true}>
        <Container>
          {/* if has add to cart process , back to add to cart page */}
          <BaseBackTop
            backRedirect={'/merchant-biller?listType=all'}
            headerTitle={'Payment Summary'}
            disableBack={true}
            addBackBtnCallback={true}
            handleBackBtn={handleBackBtn}
          />
        </Container>
        <Container className={classes.container}>
          <Grid container direction='row' alignItems='center' spacing={2}>
            {getPaymentList.map((data, index) => (
              <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                <PaymentList paymentData={data} key={index} />
              </Grid>
            ))}
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant='subtitle1' className={classes.totalAmountLeft}>
                Total Amount
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant='subtitle1' noWrap={true} className={classes.totalAmountRight}>
                <span className={classes.php}>PHP</span> {getCommaSeparatedTwoDecimalsNumber(totalAmount)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='caption' className={classes.totalAmountCaption}>
                Please note that you are required to pay for the convenience fee. The total amount differs once you are redirected to the payment
                gateway page.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <br />
              <br />
              <Button
                variant='contained'
                type='submit'
                data-testid={'ContinueToPayBtn'}
                fullWidth
                className={!btnLoading ? classes.btnCheckout : classes.btnCheckoutDisabled}
                onClick={handleContinueToPay}
                disabled={btnLoading}>
                {btnLoading ? <CircularProgress size={28} className={classes.btnLoading} /> : 'Continue to Pay'}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </BaseContainer>
      {openModal ? <ModalDialog modalOpen={openModal} /> : ''}
    </>
  );
};

export default PaymentSummary;
