import imageDefault from '../assets/image-logo/bayad-logo.png';

export const moveArrayItemToNewIndex = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const getBillerLogoBySource = (code) => {
  let result = null;
  try {
    result = require('../assets/biller-logo/' + code + '.png').default;
  } catch (error) {
    result = imageDefault;
  }

  return result;
};

export const checkBillerLogoByLink = (url) => {
  let result = null;
  try {
    result = url ? url : false;
  } catch (error) {
    result = imageDefault;
  }

  return result;
};

export const getCategoryLogo = (name, active) => {
  let result = null;
  let categoryName = String(name).replace(/\//g, '');
  try {
    if (active) {
      result = require('../assets/category-logo/' + categoryName + '-A.png').default;
    } else {
      result = require('../assets/category-logo/' + categoryName + '.png').default;
    }
  } catch (error) {
    result = imageDefault;
  }

  return result;
};

export const checkBillerImageExist = (code) => {
  let result = false;
  try {
    let image = require('../assets/biller-logo/' + code + '.png').default;
    result = image ? true : false;
  } catch (error) {
    result = false;
  }

  return result;
};


