import DevStore from './store.dev';
import ProdStore from './store.prod';

let store = null;
if (process.env.REACT_APP_ENVIRONMENT == 3 || process.env.REACT_APP_ENVIRONMENT == 2) {
  store = ProdStore();
} else {
  store = DevStore();
}

export default store;
