import { Grid, Container, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BaseContainer from '../../containers/BaseContainer/BaseContainer';
import CategoryListTable from '../../containers/CategoryListTable/CategoryListTable';
import CategoryListTiled from '../../containers/CategoryListTiled/CategoryListTiled';
import ListAndTiledView from '../../containers/ListAndTiledView/ListAndTiledView';
import CategoryData from './CategoryData';
import { useHistory, useLocation } from 'react-router-dom';
import { clearCategoryBillers, getCategoryList } from '../../store/Category/CategoryAction';
import { useDispatch, useSelector } from 'react-redux';
import { getBillerList } from '../../store/Biller/BillerAction';
import ModalDialog from '../../containers/ModalDialog/ModalDialog';

const myStyles = makeStyles((theme) => ({
  billerCategpryContainter: {
    paddingBottom: '60px',
  },
  paddingTop: {
    paddingTop: 20,
  },
}));

const Categories = (props) => {
  const classes = myStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [categoryViewType, setCategoryViewType] = useState('list');
  const [searchText, setSearchText] = useState('');
  const categoryList = useSelector((state) => state.category.categoryList);
  const allMerchantBillers = useSelector((state) => state.biller.billerList);
  const errorCategory = useSelector((state) => state.category.error);
  const errorBiller = useSelector((state) => state.biller.error);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!allMerchantBillers && !categoryList) {
      dispatch(getBillerList());
      dispatch(getCategoryList());
    }
  }, [dispatch]);

  useEffect(() => {
    if (errorCategory.flag || errorBiller.flag) {
      setOpenModal(true);
    }
  }, [errorCategory, errorBiller]);

  const handlerView = (type) => {
    setSearchText('');
    setCategoryViewType(type);
  };

  const handleSearchChange = (value) => {
    setSearchText(value);
  };

  const handlerGetCategory = (data) => {
    if (data) {
      const id = data.category_id;
      dispatch(clearCategoryBillers());
      history.push('/v1/merchant-biller?listType=category&type=' + data.category + '&categoryId=' + id);
    }
  };

  const handleSearchClick = (action) => {
    if (action) {
      history.push('/v1/merchant-biller?listType=all');
    }
  };

  return (
    <>
      <BaseContainer disableBack={true} showFooter={true} contentWidth={'lg'}>
        <Container maxWidth='lg' className={classes.billerCategpryContainter}>
          <ListAndTiledView
            viewType={(type) => {
              handlerView(type);
            }}
            searchValue={(value) => {
              handleSearchChange(value);
            }}
            showClear={false}
            searchClick={(action) => {
              handleSearchClick(action);
            }}
            disableSearchClick={false}
          />
          <Grid container className={classes.paddingTop}>
            {categoryViewType === 'list' ? (
              <CategoryListTable
                categoryList={categoryList}
                handlerGetCategory={(data) => {
                  handlerGetCategory(data);
                }}
              />
            ) : (
              <CategoryListTiled
                categoryList={categoryList}
                handlerGetCategory={(data) => {
                  handlerGetCategory(data);
                }}
              />
            )}
          </Grid>
        </Container>
      </BaseContainer>
      {openModal ? <ModalDialog modalOpen={openModal} /> : ''}
    </>
  );
};

export default Categories;
