import { makeStyles, Grid, Typography, Container, TextField, Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import inquiryLogo from '../../assets/image-logo/inquiry-logo.png';
import Constants from '../../utils/Constants';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { emailValidation } from '../../containers/BillerFields/Controls/Controls';
import bayadExpressLogo from '../../assets/image-logo/bayad-express-logo.png';

const myStyles = makeStyles((theme) => ({
  root: {
    minHeight: '80vh',
    textAlign: 'center',
  },
  container: {
    // backgroundImage: `url(${containerBackground})`,
    // minHeight: '100%',
    // maxWidth: '100%',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
  },
  bold600: {
    fontWeight: 600,
  },
  bold500: {
    fontWeight: 500,
  },
  opacity09: {
    opacity: 0.9,
  },
  cssOutlinedInput: {
    color: 'black',
  },
  notchedOutline: {
    borderColor: 'gray !important',
    color: 'gray',
  },
  input: {
    '&::placeholder': {
      color: 'gray',
    },
  },
  inputLabel: {
    color: 'gray !important',
  },
  inputLabelError: {
    color: 'red !important',
  },
  gridContent: {
    [theme.breakpoints.down('xs')]: {
      width: 'inherit',
    },
  },
  cardContainer: {
    padding: 30,
    maxWidth: 500,
    width: 500,
    [theme.breakpoints.down('xs')]: {
      padding: 30,
      width: 'inherit',
    },
  },
  buttonSubmit: {
    backgroundColor: '#E05A29 !important',
    color: 'white',
    letterSpacing: '0.5px',
    marginBottom: '5px',
    textTransform: 'none',
    marginTop: '15px',
  },
  buttonSubmitDisabled: {
    backgroundColor: '#f19978 !important',
    color: 'white !important',
    letterSpacing: '0.5px',
    marginBottom: '5px',
    textTransform: 'none',
    marginTop: '15px',
  },
  btnLoading: {
    color: 'white',
  },
}));
const EmailValidation = (props) => {
  const { handleEmailSubmit, invalidEmail = false, setBtnLoading = false } = props;
  const classes = myStyles();
  const [error, isError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [disabledBtn, setDisableBtn] = useState(true);

  const { register, handleSubmit } = useForm({ mode: 'onChange' });

  useEffect(() => {
    isError(invalidEmail);
    if (invalidEmail) {
      setHelperText('The email address not match. Please enter the correct email address');
    } else {
      setHelperText('');
    }
  }, [invalidEmail]);

  const handlerOnChange = (e) => {
    const { value } = e.target;
    isError(false);
    setHelperText('');
    setDisableBtn(true);
    if (!emailValidation(value) && value !== '') {
      isError(true);
      setHelperText('The email address is invalid. Please enter the correct email address');
      setDisableBtn(true);
    } else if (value === '') {
      isError(false);
      setHelperText('');
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  const getEmailInquiry = (data) => {
    handleEmailSubmit(data);
  };

  return (
    <>
      <Container maxWidth={'xl'} className={classes.container}>
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2} className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridContent}>
            <Grid container className={classes.cardContainer} direction='column' spacing={3}>
              <Grid item>
                <img className={classes.inquiryLogo} src={inquiryLogo} alt={'inquiryLogo'}></img>
              </Grid>
              <Grid item>
                <Typography variant='h6' className={clsx(classes.bold600, classes.opacity09)}>
                  {Constants.inquiry_header}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='subtitle1'>{Constants.inquiry_content}</Typography>
              </Grid>
              <Grid item>
                <form
                  noValidate
                  onSubmit={handleSubmit((data) => {
                    getEmailInquiry(data);
                  })}>
                  <TextField
                    id={'email'}
                    data-testid={'EmailInquiryTextbox'}
                    autoComplete='off'
                    label={'Enter your email address here.'}
                    margin='dense'
                    variant='outlined'
                    size='small'
                    fullWidth
                    {...register('email', {
                      onChange: (e) => {
                        handlerOnChange(e);
                      },
                    })}
                    error={error && Boolean(error)}
                    helperText={helperText}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        notchedOutline: !error ? classes.notchedOutline : '',
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{
                      className: error ? classes.inputLabelError : classes.inputLabel,
                    }}
                  />
                  <Button
                    className={!disabledBtn && !setBtnLoading ? classes.buttonSubmit : classes.buttonSubmitDisabled}
                    variant='contained'
                    type='submit'
                    fullWidth
                    disabled={disabledBtn || setBtnLoading}
                    data-testid={'EmailInquirySubmitButton'}>
                    {setBtnLoading ? <CircularProgress size={28} className={classes.btnLoading} /> : 'Submit'}
                  </Button>
                </form>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          <Grid item>
            <img className={classes.bayadExpressLogo} src={bayadExpressLogo} alt='bayadExpressLogo'></img>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default EmailValidation;
