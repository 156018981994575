import { all } from 'redux-saga/effects';
import watchSessionRequest from './Session/SessionSaga';
import watchTokenRequest from './Token/TokenSaga';
import watchBillerRequests from './Biller/BillerSaga';
import watchPaymentRequests from './Payment/PaymentSaga';
import watchTransactionRequests from './Transaction/TransactionSaga';
import watchCategoryRequests from './Category/CategorySaga';

export default function* rootSaga() {
  yield all([
    watchSessionRequest(),
    watchTokenRequest(),
    watchBillerRequests(),
    watchPaymentRequests(),
    watchTransactionRequests(),
    watchCategoryRequests(),
  ]);
}
