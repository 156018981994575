import { CircularProgress, Container, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BaseContainer from '../../containers/BaseContainer/BaseContainer';
import BaseBackTop from '../../containers/BaseBackTop/BaseBackTop';
import { useDispatch, useSelector } from 'react-redux';
import BillerFields from '../../containers/BillerFields/BillerFields';
import billerLogo from '../../utils/BillerLogoList';
import { clearBillerForm, getBillerForm, getBillerInfo } from '../../store/Biller/BillerAction';
import ModalDialog from '../../containers/ModalDialog/ModalDialog';
import Constants from '../../utils/Constants';
import { billerConfirmation, clearPayment, clearPaymentVerified, paymentList, verifyAccount } from '../../store/Payment/PaymentAction';
import { ADD_TO_CART, PROCEED_TO_CHECKOUT } from '../../containers/BillerFields/Controls/Controls';
import { getBillerCount, getMerchantId } from '../../utils/Auth';
import imageDefault from '../../assets/image-logo/bayad-logo.png';
// import BillerParameter from '../../containers/BillerParameter/BillerParameter';
// import fieldParameter from '../../containers/BillerParameter/Parameters';, setBillerForm

const myStyles = makeStyles((theme) => ({
  billerLogo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  tdBillerLogo: {
    width: 120,
    height: 120,
    paddingRight: 10,
    [theme.breakpoints.down('xs')]: {
      width: 90,
    },
    [theme.breakpoints.down('sm')]: {
      width: 90,
    },
  },
  billerLogo1: {
    verticalAlign: 'middle',
  },
  bold: {
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontWeight: 600,
      fontSize: '0.9rem',
    },
  },
  divLogo: {
    position: 'absolute',
  },
  divBillerItem: {
    textAlign: 'center',
  },
  loading: {
    color: '#F16122',
  },
  loadingContainer: {
    marginTop: '30px',
    textAlign: 'center',
  },
  gridLogo: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const BillerForm = (props) => {
  const classes = myStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const errorMerchantBillers = useSelector((state) => state.biller.error);
  const billerInfo = useSelector((state) => state.biller.billerInfo);
  const billerForm = useSelector((state) => state.biller.billerForm);
  const billerPaymentList = useSelector((state) => state.payment.paymentList);
  const paymentVerified = useSelector((state) => state.payment.paymentVerified);
  const paymentConfirmed = useSelector((state) => state.payment.paymentConfirmed);
  const [billerFormFields, setBillerFormFields] = useState([]);
  const merchantIdCookie = getMerchantId('merchantId');
  const [buttonType, setButtonType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [modalContent2, setModalContent2] = useState('');
  const [modalButtonLabel, setModalButtonLabel] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const errorPaymentAccount = useSelector((state) => state.payment.error);

  //Local Request Biller Form
  // useEffect(() => {
  // const getBillerParameters = fieldParameter;
  //   const billerCode = query.get('billerCode');
  //   dispatch(clearBillerForm());
  //   if (!billerInfo && !billerCode) {
  //     history.push('/v1/biller-list');
  //   } else if (!billerInfo && billerCode) {
  //     dispatch(getBillerInfo(billerCode, merchantIdCookie));
  //   }
  //   dispatch(clearPaymentVerified());
  //   dispatch(clearPayment());
  //   if (getBillerParameters[billerCode] !== undefined) {
  //     //Use BillerParameter if one by one biller mock data local
  //     //Use getBillerParameters if all biller mock data local
  //     dispatch(setBillerForm(getBillerParameters[billerCode]));
  //     setBillerFormFields(getBillerParameters[billerCode]);
  //   } else {
  //     setModalContent('Biller ' + billerInfo.name + ' ' + Constants.biller_not_found_content);
  //     setModalContent2(' ');
  //     setModalButtonLabel(Constants.biller_not_found_btn_label);
  //     setOpenModal(true);
  //   }
  // }, [dispatch, billerInfo]);
  //Local Request Biller Form

  //API Request Biller Form
  useEffect(() => {
    const billerCode = query.get('billerCode');
    dispatch(clearBillerForm());
    if (!billerInfo && !billerCode) {
      history.push('/v1/biller-category');
    } else if (!billerInfo && billerCode) {
      dispatch(getBillerInfo(billerCode, merchantIdCookie));
    } else if (billerInfo && billerCode) {
      dispatch(getBillerForm(billerCode, merchantIdCookie));
    }
  }, [dispatch, billerInfo]);

  useEffect(() => {
    if (billerForm && billerForm.length !== 0) {
      dispatch(clearPaymentVerified());
      dispatch(clearPayment());
      setBillerFormFields(billerForm);
    }
  }, [billerForm]);
  //API Request Biller Form

  const clearModalContent = () => {
    setModalTitle('');
    setModalContent('');
    setModalContent2('');
    setModalButtonLabel('');
    setOpenModal(false);
  };

  useEffect(() => {
    clearModalContent();
    if (errorMerchantBillers.flag || errorPaymentAccount.flag) {
      if (errorMerchantBillers.flag) handleErrorMechantBiller();
      if (errorPaymentAccount.flag) handleErrorPaymentAccount();
      setOpenModal(true);
      setBtnLoading(false);
    }
  }, [errorMerchantBillers, errorPaymentAccount]);

  const handleErrorMechantBiller = () => {
    setBillerFormFields([]);
    if (errorMerchantBillers.code === 404) {
      setModalContent(errorMerchantBillers.msg);
      setModalContent2(' ');
    }
  };

  const handleErrorPaymentAccount = () => {
    if (errorPaymentAccount.code === 422) {
      setModalContent(errorPaymentAccount.details);
      setModalContent2(' ');
    }
  };

  const handlerGetFormData = (data, btnType) => {
    clearModalContent();
    if (billerPaymentList && billerPaymentList.length > 0) {
      if (checkAccountIfExist(data.account_number, billerInfo.code).length > 0) {
        setModalTitle(data.account_number);
        setModalContent(Constants.account_duplicate_content);
        setModalContent2(' ');
        setModalButtonLabel(Constants.account_duplicate_btn_label);
        setOpenModal(true);
      } else {
        setBtnLoading(true);
        paymentVerifyAccount(data, btnType);
      }
    } else {
      setBtnLoading(true);
      paymentVerifyAccount(data, btnType);
    }
  };

  const checkAccountIfExist = (accountNumber, billerCode) => {
    return billerPaymentList.filter((data) => data.account_number === accountNumber && data.code === billerCode);
  };

  const paymentVerifyAccount = (data, btnType) => {
    setButtonType(btnType);
    const verifyData = {
      billsPayDetails: {
        billsPayFormDetails: {
          clientReference: null,
          paymentMethod: 'CASH',
          validationNumber: null,
          ...data,
        },
      },
    };
    dispatch(verifyAccount(billerInfo.code, data.referenceNumber, verifyData));
  };

  useEffect(() => {
    if (paymentVerified && !paymentConfirmed) {
      if (paymentVerified.accountNotVerified) {
        setModalContent(paymentVerified.details);
        setModalContent2(' ');
        setOpenModal(true);
        setBtnLoading(false);
      } else {
        dispatch(billerConfirmation(paymentVerified.code, paymentVerified.bills_pay_data_id));
      }
    }

    if (paymentVerified && paymentConfirmed) {
      if (billerPaymentList && billerPaymentList.length > 0) {
        dispatch(paymentList([...billerPaymentList, paymentConfirmed]));
      } else {
        dispatch(paymentList([...[paymentConfirmed]]));
      }

      if (buttonType === PROCEED_TO_CHECKOUT) {
        history.push('/v1/payment-summary');
      } else if (buttonType === ADD_TO_CART) {
        history.push('/v1/biller-category');
      }
      setBtnLoading(false);
    }
  }, [paymentVerified, paymentConfirmed]);

  const handleModalClose = (modalClose) => {
    if (modalClose) {
      dispatch(clearPaymentVerified());
      dispatch(clearBillerForm());
      dispatch(clearPayment());
      clearModalContent();
    }
  };

  return (
    <>
      <BaseContainer disableBack={btnLoading} disableContactUs={btnLoading} disableFaq={btnLoading}>
        <Container>
          <BaseBackTop backRedirect={'/merchant-biller?listType=all'} headerTitle={'Enter Biller Information'} disableBack={btnLoading} />
        </Container>
        {billerInfo ? (
          <>
            <Container>
              <table>
                <tbody>
                  <tr>
                    <td colSpan={2} className={classes.tdBillerLogo}>
                      <img
                        className={classes.billerLogo}
                        src={billerInfo.logo}
                        onError={(e) => (e.target.src = imageDefault)}
                        alt={billerInfo.code}></img>
                      {/* <img
                        className={classes.billerLogo}
                        src={billerLogo[billerInfo.code] ? billerLogo[billerInfo.code] : imageDefault}
                        alt={billerInfo.code}></img> */}
                    </td>
                    <td>
                      <Typography variant='subtitle1' className={classes.bold}>
                        {billerInfo.name}
                      </Typography>
                      <Typography variant='subtitle2'>{billerInfo.category}</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Container>
            <Container>
              <BillerFields formData={billerFormFields} handlerGetFormData={handlerGetFormData} setBtnLoading={btnLoading} />
            </Container>
          </>
        ) : (
          <Container className={classes.loadingContainer}>
            <CircularProgress className={classes.loading} />
          </Container>
        )}
      </BaseContainer>

      {openModal ? (
        <ModalDialog
          maxWidth={'xs'}
          modalOpen={openModal}
          modalTitle={modalTitle}
          modalContent={modalContent}
          modalContent2={modalContent2}
          modalButtonLabel={modalButtonLabel}
          addBtnCloseCallback={true}
          handleModalClose={handleModalClose}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default BillerForm;
