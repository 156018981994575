import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import BaseContainer from '../../containers/BaseContainer/BaseContainer';
import contactUsBg from '../../assets/background/contactUs-bg.png';
import contactUsBgMobile from '../../assets/background/contactUs-bg-mobile.png';

const myStyles = makeStyles((theme) => ({
  contactLogo: {
    top: '0px',
    left: '0px',
    paddingTop: 15,
    paddingBottom: 10,
  },
  bold: {
    fontWeight: 800,
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  container: {
    paddingLeft: '30px',
    paddingTop: '40px',
  },
  mail: {
    color: 'black',
  },
  labelTitle: {
    color: 'blue',
  },
  divContainer: {
    backgroundImage: `url(${contactUsBg})`,
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${contactUsBgMobile})`,
      backgroundPosition: 'center',
    },
    height: '100vh',
    minHeight: '100%',
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
  },
  root: {
    minHeight: '70vh',
    margin: 'auto',
    paddingLeft: '30px',
    paddingRight: '30px',
    textAlign: 'center',
  },
  contentTitle: {
    fontWeight: 600,
    color: '#188dad',
  },
  content: {
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));

const ContactUs = (props) => {
  const classes = myStyles();
  return (
    <>
      {/* className={classes.divContainer} */}
      <Container maxWidth='xl' disableGutters={true} className={classes.divContainer}>
        <BaseContainer contactUsActive={true}>
          <Grid container component='main' className={classes.root} direction='column' justifyContent='center' alignItems='center'>
            <Grid item>
              <Typography variant='h4' className={classes.contentTitle} paragraph={true}>
                CONTACT US
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle1' paragraph={true} className={classes.content}>
                For inquiries and concerns, you may contact us at
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle1' className={classes.bold} paragraph={true}>
                Email:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle1' paragraph={true} className={classes.content}>
                <a className={classes.mail} href='mailto:support@bayad.com'>
                  {' '}
                  support@bayad.com
                </a>{' '}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle1' className={classes.bold} paragraph={true}>
                Customer Care Hotline:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle1' paragraph={true} className={classes.content}>
                +632 8672-5777
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle1' className={classes.bold} paragraph={true}>
                Mobile Numbers:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle1' paragraph={true} className={classes.content}>
                +63 920-966 3000 / +63 917-836 3000
              </Typography>
            </Grid>
          </Grid>
        </BaseContainer>
      </Container>
    </>
  );
};

export default ContactUs;
