import { makeStyles, Grid, Typography, Card, Container, Button, Tooltip, Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import failedLogo from '../../assets/image-logo/failed-logo.png';
import Constants from '../../utils/Constants';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import urlEncode from '../../utils/UrlEncode';
import { Alert } from '@material-ui/lab';

const myStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    textAlign: 'center',
  },
  container: {},
  bold600: {
    fontWeight: 600,
  },
  bold500: {
    fontWeight: 500,
  },
  opacity09: {
    opacity: 0.9,
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  gridContent: {
    [theme.breakpoints.down('xs')]: {
      width: 'inherit',
    },
  },
  card: {
    boxShadow: '0px 1px 6px rgb(0 0 0 / 23%)',
    borderRadius: '8px',
  },
  cardContainer: {
    padding: 30,
    maxWidth: 550,
    width: 550,
    [theme.breakpoints.down('xs')]: {
      padding: 30,
      width: 'inherit',
    },
  },
  cardFooter: {
    maxWidth: 600,
    width: 600,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    padding: '20px',
  },
  btnAction: {
    letterSpacing: '1px',
    font: 'normal normal normal 13px/20px Poppins',
    width: '224px',
    height: '39px',
    borderRadius: '21px',
    backgroundColor: '#E05A29 !important',
    color: 'white',
  },
  mail: {
    color: 'black',
  },
  failedLogo: {
    width: '70%',
    height: '70%',
  },
  alert: {
    backgroundColor: '#F16122',
    color: 'white',
    '& .MuiAlert-icon': {
      color: 'white',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));
const AcknowledgementFailed = (props) => {
  const classes = myStyles();
  const { transactionData } = props;
  const history = useHistory();

  if (transactionData.status !== Constants.status_failed) history.push('/v1/0?msg=Please check that URL is correct and and try again.');

  const backToExpress = () => {
    history.push(`/v1/redirect?${urlEncode('q', transactionData.expressUrl)}&exit=true`);
  };

  const [openCopyTooltip, setOpenCopyTooltip] = useState(false);

  const handleCopyRefNumber = () => {
    setOpenCopyTooltip(true);
    const referenceNumber = transactionData ? 'Reference Number:' + transactionData.transaction_reference_number : '';
    navigator.clipboard.writeText(referenceNumber);
  };

  const handleCopyClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenCopyTooltip(false);
  };

  return (
    <>
      <Container maxWidth={'xl'} className={classes.container}>
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2} className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <img className={classes.failedLogo} src={failedLogo} alt={'failedLogo'}></img>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant='h6' className={clsx(classes.bold500, classes.opacity09)}>
              {Constants.failed_title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridContent}>
            <Card className={classes.card}>
              <Grid container className={classes.cardContainer} direction='column' spacing={3}>
                <Grid item>
                  <Typography variant='h6' className={clsx(classes.bold600, classes.opacity09)}>
                    We encountered a problem with your card, and we are unable to proceed with your payment.
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip title='Copy' placement='right'>
                    <Typography variant='subtitle2' className={clsx(classes.opacity09, classes.pointer)} onClick={handleCopyRefNumber}>
                      Reference Number: {transactionData ? transactionData.transaction_reference_number : ''}
                    </Typography>
                  </Tooltip>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={openCopyTooltip}
                    autoHideDuration={3000}
                    onClose={handleCopyClose}>
                    <Alert onClose={handleCopyClose} severity='info' sx={{ width: '100%' }} className={classes.alert}>
                      {Constants.copy_to_clipboard}
                    </Alert>
                  </Snackbar>
                </Grid>
                <Grid item>
                  <Typography variant='subtitle1'>Please contact your bank for assistance and try again.</Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item>
            <Container>
              <Grid container className={classes.cardFooter} direction='column' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    data-testid={'AcknowledgementFailedBtn'}
                    className={classes.btnAction}
                    onClick={backToExpress}>
                    Back to Bayad Express
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant='body2' className={classes.opacity09}>
                    For further assistance, reach out to Bayad Express by emailing
                    <a className={classes.mail} href='mailto:support@bayad.com'>
                      {' '}
                      support@bayad.com
                    </a>{' '}
                    or calling our hotline at +632 8672 5777
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AcknowledgementFailed;
