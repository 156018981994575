import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isPrivacyNoticeDisplay, setPrivacyNoticeDisplay } from '../../utils/Auth';
import logo from '../../assets/image-logo/privacy-notice-logo@2x.png';
import { checkUrlContains } from '../../utils/ApiUrl';

const myStyles = makeStyles((theme) => ({
  alertTitle: {
    textAlign: 'center',
    fontWeight: 600,
  },
  alertButton: {
    borderRadius: '15px',
    textTransform: 'none',
    color: '#3f9e75',
    margin: '10px',
    fontSize: '0.8rem',
  },
  textIndent: {
    textIndent: '0px',
    color: 'white',
  },
  bold: {
    fontWeight: 600,
    color: 'white',
  },
  dialogAction: {
    textAlign: 'center',
    display: 'block',
  },
  underline: {
    textDecoration: 'underline',
    color: 'white',
    '&:hover': {
      color: 'black',
    },
    cursor: 'pointer',
  },
  mail: {
    color: 'white',
    '&:hover': {
      color: 'black',
    },
  },
  dialogContent: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '1em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#e0e0e0 !important',
      borderRadius: '21px',
    },
  },
  logo: {
    width: '20%',
    height: '20%',
    marginTop: '-50px',
    [theme.breakpoints.down('xs')]: {
      width: '30%',
      height: '30%',
      marginTop: '-40px',
    },
  },
  logoDiv: {
    textAlign: 'center',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    backgroundColor: '#00B753',
  },
  topDiv: {
    paddingTop: '70px !important',
    backgroundColor: '#dcf0fa',
  },
  logoDivParent: {
    backgroundColor: '#dcf0fa',
  },
  dialogTitle: {
    padding: '0 !important',
  },
  dialog: {
    //height: '80%',
  },
}));

const PrivacyNoticeModal = (props) => {
  const { handleAgree } = props;
  const classes = myStyles();
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sx'));
  //remove if privacy notice show before express loading
  const privacyNoticeDisplay = isPrivacyNoticeDisplay();

  //remove if privacy notice show before express loading
  useEffect(() => {
    if (checkUrlContains('biller-category') || checkUrlContains('merchant-biller')) {
      setOpen(false);
    } else if (privacyNoticeDisplay === 'true') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [privacyNoticeDisplay]);

  const handlePrivatePolicy = () => {
    history.push('/v1/privacy-statement');
  };

  const handlePrivacyNoticeYes = () => {
    handleAgree(true);
    setPrivacyNoticeDisplay(false);
    setOpen(false);
  };

  const handlePrivacyNoticeNo = () => {
    handleAgree(false);
    //setPrivacyNoticeDisplay(false);
    //setOpen(false);
  };

  return (
    <>
      <Dialog
        className={classes.dialog}
        PaperProps={{
          style: {
            backgroundColor: '#00B753',
            color: 'white',
            boxShadow: 'none',
            borderRadius: '15px',
          },
        }}
        id='privacy-modal-id'
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        fullScreen={fullScreen}
        open={open}
        aria-labelledby='responsive-dialog-title'>
        <div className={classes.topDiv}></div>
        <div className={classes.logoDivParent}>
          <div className={classes.logoDiv}>
            <img className={classes.logo} src={logo} alt={'logo'}></img>
          </div>
        </div>
        <DialogTitle id='responsive-dialog-title' className={classes.dialogTitle}>
          <Typography variant='subtitle1' component={'div'} className={classes.alertTitle}>
            Privacy Notice
          </Typography>
        </DialogTitle>
        <DialogContent component={'div'} className={classes.dialogContent}>
          <DialogContentText component={'div'}>
            <Typography variant='body2' paragraph={true} className={classes.bold}>
              Bayad values your privacy
            </Typography>
            <Typography variant='body2' paragraph={true} className={classes.textIndent}>
              We collect your personal data solely to verify your identity, facilitate your transactions, and enhance your customer experience. We
              ensure that the data you provide is safely stored in our database, and will only be retained for ten (10) years, in accordance with our
              Privacy policy and in compliance with existing privacy laws and regulations.
            </Typography>
            <Typography variant='body2' paragraph={true} className={classes.textIndent}>
              We shall share your data only with our trusted partners on a need-to-know basis, and shall not further share your data with any other
              third-party unless we have written authorization from you to do so.
            </Typography>
            <Typography variant='body2' paragraph={true} className={classes.textIndent}>
              For more information on how we protect your data, you may visit our Privacy Statement at
              <a className={classes.underline} onClick={handlePrivatePolicy}>
                {' '}
                https://www.bayadexpress.com/v1/privacy-statement
              </a>
              .
            </Typography>
            <Typography variant='body2' paragraph={true} className={classes.textIndent}>
              To exercise your data subject rights, you may contact the Bayad Data Privacy Office at
              <a className={classes.mail} href='mailto:dpo@bayad.com'>
                {' '}
                dpo@bayad.com
              </a>
              .
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button variant='contained' className={classes.alertButton} onClick={handlePrivacyNoticeYes} size='small' id='btn-proceed'>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Proceed&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Button>
          {/* <Button variant='contained' className={classes.alertButton} onClick={handlePrivacyNoticeNo} size='small'>
            Disagree
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PrivacyNoticeModal;
