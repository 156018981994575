import { makeStyles, Zoom, Box, Fab, useScrollTrigger } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#divToTop');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box onClick={handleClick} role='presentation' sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 9999 }}>
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const myStyles = makeStyles((theme) => ({
  scrollToTop: {
    backgroundColor: '#F16122',
    '&:hover': {
      backgroundColor: '#F16122',
    },
  },
}));

const ScrollToTop = (props) => {
  const classes = myStyles();
  return (
    <>
      <ScrollTop {...props}>
        <Fab color='secondary' size='small' aria-label='scroll back to top' className={classes.scrollToTop}>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default ScrollToTop;
