import * as types from '../Token/TokenTypes';

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  isToken: false,
  error: {
    flag: false,
    code: '',
    msg: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_TOKEN:
      return {
        ...state,
        isToken: false,
        isLoading: true,
      };
    case types.GET_TOKEN_SUCCESS:
      return {
        ...state,
        isToken: true,
        data: action.payload.data,
        isLoading: false,
      };
    case types.GET_TOKEN_FAILED:
      return {
        ...state,
        isToken: false,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Get Authentication Token Error',
        },
        isLoading: false,
      };
    case types.REMOVE_TOKEN:
      return INITIAL_STATE;
    default:
      return state;
  }
};
