import { createTheme, responsiveFontSizes } from '@material-ui/core';
const defaultTheme = createTheme();
const { breakpoints } = defaultTheme;

let theme = createTheme({
  palette: {
    background: {
      default: 'rgb(255 255 255)',
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#F16122',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: 'white',
      },
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          color: '#f16122bf',
        },
        '&:focus': {
          color: '#f16122bf',
        },
      },
      yearSelected: {
        color: '#F16122',
        '&:hover': {
          color: '#F16122',
        },
      },
    },
    MuiPickersMonth: {
      root: {
        '&:hover': {
          color: '#f16122bf',
        },
        '&:focus': {
          color: '#f16122bf',
        },
      },
      monthSelected: {
        color: '#F16122',
        '&:hover': {
          color: '#F16122',
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#F16122',
        '&:hover': {
          backgroundColor: '#F16122',
        },
      },
      day: {
        '&:hover': {
          backgroundColor: '#f16122bf',
        },
      },
    },
    MuiTypography: {
      root: {
        // wordWrap: 'break-word',
      },
    },
    MuiContainer: {
      root: {},
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          //backgroundImage: `url(${banner})`,
        },
      },
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h1: {
      [breakpoints.down('xs')]: {
        fontSize: 25,
      },
    },
    h2: {
      [breakpoints.down('xs')]: {
        fontSize: 24,
      },
    },
    h3: {
      [breakpoints.down('xs')]: {
        fontSize: 21,
      },
    },
    h4: {
      [breakpoints.down('xs')]: {
        fontSize: 20,
      },
    },
    h5: {
      [breakpoints.down('xs')]: {
        fontSize: 19,
      },
    },
    h6: {
      [breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },

    subtitle1: {
      [breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },

    subtitle2: {
      [breakpoints.down('xs')]: {
        fontSize: 11,
      },
    },

    body2: {
      [breakpoints.down('xs')]: {
        fontSize: 10,
      },
    },

    body1: {
      [breakpoints.down('xs')]: {
        fontSize: 9,
      },
    },

    button: {
      [breakpoints.down('xs')]: {},
    },
    caption: {
      [breakpoints.down('xs')]: {},
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
