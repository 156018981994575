import {
  BILLER_FORM_DATA,
  CLEAR_BILLER_FORM,
  CLEAR_BILLER_FORM_DATA,
  CLEAR_BILLER_LIST,
  GET_BILLER,
  GET_BILLER_FORM,
  GET_BILLER_INFO,
  GET_BILLER_LIST,
  SET_BILLER_FORM,
} from './BillerTypes';

export const getBillerList = () => {
  return {
    type: GET_BILLER_LIST,
  };
};

export const getBiller = (data) => {
  return {
    type: GET_BILLER,
    data,
  };
};

export const getBillerForm = (billerCode) => {
  return {
    type: GET_BILLER_FORM,
    billerCode,
  };
};

export const getBillerInfo = (billerCode) => {
  return {
    type: GET_BILLER_INFO,
    billerCode,
  };
};

export const clearBillerForm = () => {
  return {
    type: CLEAR_BILLER_FORM,
  };
};

export const clearBillerList = () => {
  return {
    type: CLEAR_BILLER_LIST,
  };
};

export const setBillerForm = (data) => {
  return {
    type: SET_BILLER_FORM,
    data,
  };
};

export const billerFormData = (data) => {
  return {
    type: BILLER_FORM_DATA,
    data,
  };
};

export const clearBillerFormData = () => {
  return {
    type: CLEAR_BILLER_FORM_DATA,
  };
};
