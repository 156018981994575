import { useSelector } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { getMerchantName, getTokenData, getTransactionId } from '../utils/Auth';

function PrivateRoute({ component: Component, ...rest }) {
  const token = useSelector((state) => state.token);
  const tokenData = getTokenData();
  const transactionId = getTransactionId();
  const transactionData = useSelector((state) => state.transaction.transactionData);
  const merchantName = getMerchantName();
  const history = useHistory();
  const disableMaintenance = process.env.REACT_APP_DISABLE_MAINTENANCE
  if (process.env.REACT_APP_MAINTENANCE_MODE == 1 && !disableMaintenance.includes(merchantName)) {
    history.push('/v1/maintenance');
  }
  return (
    <>
      {transactionId !== null && transactionData === null ? (
        <Redirect to={'/v1/acknowledgement/cancel?transactionId=' + transactionId} />
      ) : (
        <Route
          {...rest}
          render={(props) =>
            token.isToken && tokenData ? <Component {...props} /> : <Redirect to='/v1/0?msg=Please check that URL is correct and and try again.' />
          }
        />
      )}
    </>
  );
}

export default PrivateRoute;
