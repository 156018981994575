import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { AMOUNT, DEFAULT_MIN_AMOUNT_VALUE, DEFAULT_MIN_VALUE, NUMBER } from './Controls';

const myStyles = makeStyles((theme) => ({
  cssOutlinedInput: {
    //color: '#F16122',
  },
  notchedOutline: {
    borderColor: 'gray !important',
  },
  input: {
    '&::placeholder': {
      color: 'gray',
    },
  },
  inputLabel: {
    color: 'gray !important',
  },
  inputLabelError: {
    color: 'red !important',
  },
}));

const NumberField = (props) => {
  const classes = myStyles();
  const {
    field,
    register,
    handlerFieldFormValid,
    fieldFormValid,
    btnLoading,
    selectedData,
    handlerFieldFormData,
    fieldFormData,
    handleAtleastField,
    atleastField,
  } = props;
  const [error, isError] = useState(false);
  const [value, setValue] = useState('');
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    let checkIfRequired = false;
    if (Object.keys(fieldFormValid).length > 0) {
      Object.keys(field.rules).forEach((prop) => {
        if (prop.includes('required_if:')) {
          checkIfRequired = true;
        } else if (prop === 'required') {
          checkIfRequired = true;
        }
      });
      if (!checkIfRequired) {
        fieldFormValid[field.name] = true;
        handlerFieldFormValid(fieldFormValid);
      }
    }
  }, [fieldFormValid]);

  const getAtleastField = (name, value) => {
    if (field.atleastFields.length > 0) {
      let atleastFields = [];
      field.atleastFields.forEach((val) => {
        atleastFields.push(val);
      });
      handleAtleastField(atleastFields);
    }
  };

  useEffect(() => {
    if (atleastField.length > 0 && atleastField.includes(field.name)) {
      return Object.keys(field.rules).forEach((prop) => {
        if (prop.includes('at_least_field:')) {
          const fieldKey = prop.substring(prop.indexOf(':') + 1);
          if (value === '') {
            isError(true);
            setHelperText(field.rules[prop]);
            fieldFormValid[field.name] = false;
          } else if (parseInt(fieldFormData[field.name]) < fieldFormData[fieldKey]) {
            isError(true);
            setHelperText(field.rules[prop]);
            fieldFormValid[field.name] = false;
          } else {
            isError(false);
            setHelperText('');
            fieldFormValid[field.name] = true;
          }
        }
      });
    }
  }, [atleastField]);

  useEffect(() => {
    if (Object.keys(selectedData).length > 0 && selectedData.comparedFields.includes(field.name)) {
      selectDataChange(selectedData);
    }
  }, [selectedData]);

  const selectDataChange = (selectedData) => {
    return Object.keys(field.rules).forEach((prop) => {
      if (prop.includes('required_if:')) {
        const fieldKey = prop.substring(prop.indexOf(':') + 1, prop.indexOf(','));
        const fieldValue = prop.substring(prop.indexOf(',') + 1);
        if (fieldKey === selectedData.name && fieldValue === selectedData.value) {
          if (!value || value === '') {
            isError(true);
            setHelperText(field.rules[prop]);
            fieldFormValid[field.name] = false;
          } else {
            fieldFormValid[field.name] = true;
          }
        } else {
          isError(false);
          setHelperText('');
          fieldFormValid[field.name] = true;
        }
        handlerFieldFormValid(fieldFormValid);
        if (fieldFormValid[field.name]) {
          fieldFormData[field.name] = value;
          handlerFieldFormData(fieldFormData);
        } else {
          fieldFormData[field.name] = '';
          handlerFieldFormData(fieldFormData);
        }
      }
    });
  };

  let checkFieldValid = [];
  const validate = (name, value) => {
    checkFieldValid = [];
    Object.keys(field.rules).map((prop) => {
      if (prop === 'required') {
        if (!value || value === '') {
          isError(true);
          setHelperText(field.rules[prop]);
          checkFieldValid.push(false);
        } else {
          checkFieldValid.push(true);
        }
      } else if (isNaN(+value) && !field.rules['numeric']) {
        if (isNaN(+value)) {
          isError(true);
          setHelperText('Please enter a value in numeric format');
          checkFieldValid.push(false);
        } else {
          checkFieldValid.push(true);
        }
      } else if (prop === 'numeric') {
        if (isNaN(+value)) {
          isError(true);
          if (field.rules['numeric']) {
            setHelperText(field.rules['numeric']);
          } else {
            setHelperText('Please enter a value in numeric format');
          }
          checkFieldValid.push(false);
        } else {
          checkFieldValid.push(true);
        }
      } else if (prop.includes('required_if:')) {
        const fieldKey = prop.substring(prop.indexOf(':') + 1, prop.indexOf(','));
        const fieldValue = prop.substring(prop.indexOf(',') + 1);
        if (fieldFormData[fieldKey] === fieldValue) {
          if (!value || value === '') {
            isError(true);
            setHelperText(field.rules[prop]);
            checkFieldValid.push(false);
          } else {
            checkFieldValid.push(true);
          }
        } else {
          isError(false);
          setHelperText('');
          checkFieldValid.push(true);
        }
      } else if (prop.includes('starts_with:')) {
        const startValue = prop.substring(prop.indexOf(':') + 1);
        const startValueLength = startValue.length;
        let valueStart = value.substring(0, startValueLength);
        if (valueStart !== startValue) {
          isError(true);
          setHelperText(field.rules[prop]);
          checkFieldValid.push(false);
        } else {
          checkFieldValid.push(true);
        }
      } else if (prop.includes('ends_with:')) {
        const endValue = prop.substring(prop.indexOf(':') + 1);
        const endValueLength = endValue.length;
        if (value.length >= endValueLength) {
          let valueEnd = value.substring(value.length - endValueLength, value.length);
          if (valueEnd !== endValue) {
            isError(true);
            setHelperText(field.rules[prop]);
            checkFieldValid.push(false);
          } else {
            checkFieldValid.push(true);
          }
        }
      } else if (prop.includes('digits:')) {
        const digitLength = prop.substring(prop.indexOf(':') + 1);
        if (parseInt(value.length) !== parseInt(digitLength) && parseInt(value.length) > 0) {
          isError(true);
          setHelperText(field.rules[prop]);
          checkFieldValid.push(false);
        } else {
          checkFieldValid.push(true);
        }
      } else if (prop.includes('max:') || prop.includes('max_amount:')) {
        if (value.toString().charAt(0) === '0' && value.toString().charAt(1) !== '.') {
          value = '0.' + value.toString();
        }
        let minRuleExist = Object.keys(field.rules).findIndex((propfield) => propfield.includes('min:'));
        const maxValue = prop.substring(prop.indexOf(':') + 1);
        if (parseFloat(value) > parseFloat(maxValue)) {
          isError(true);
          setHelperText(field.rules[prop]);
          checkFieldValid.push(false);
        } else if (minRuleExist <= -1) {
          const minValue = 1;
          if (parseInt(value) < parseInt(minValue)) {
            isError(true);
            if (name === AMOUNT) {
              setHelperText(DEFAULT_MIN_AMOUNT_VALUE);
            } else {
              setHelperText(DEFAULT_MIN_VALUE);
            }
            checkFieldValid.push(false);
          }
        }
      } else if (prop.includes('min:') || prop.includes('min_amount:')) {
        if (value.toString().charAt(0) === '0' && value.toString().charAt(1) !== '.') {
          value = '0.' + value.toString();
        }
        const minValue = prop.substring(prop.indexOf(':') + 1);
        if (parseInt(value) < parseInt(minValue)) {
          isError(true);
          setHelperText(field.rules[prop]);
          checkFieldValid.push(false);
        }
      } else if (prop.includes('digits_between:') && field.type === NUMBER) {
        const digitBetween = prop.substring(prop.indexOf(':') + 1).split(',');
        const start = digitBetween.splice(0, 1).join('');
        const end = digitBetween.splice(0, 2).join('');
        if (parseInt(value.length) < parseInt(start)) {
          isError(true);
          setHelperText(field.rules[prop]);
          checkFieldValid.push(false);
        } else if (parseInt(value.length) > parseInt(end)) {
          isError(true);
          setHelperText(field.rules[prop]);
          checkFieldValid.push(false);
        } else {
          checkFieldValid.push(true);
        }
      } else if (prop.includes('age_between:') && field.type === NUMBER) {
        const digitBetween = prop.substring(prop.indexOf(':') + 1).split(',');
        const start = digitBetween.splice(0, 1).join('');
        const end = digitBetween.splice(0, 2).join('');
        if (parseInt(value) < parseInt(start)) {
          isError(true);
          setHelperText(field.rules[prop]);
          checkFieldValid.push(false);
        } else if (parseInt(value) > parseInt(end)) {
          isError(true);
          setHelperText(field.rules[prop]);
          checkFieldValid.push(false);
        } else {
          checkFieldValid.push(true);
        }
      } else if (prop.includes('at_least_field:')) {
        const fieldKey = prop.substring(prop.indexOf(':') + 1);
        if (value === '') {
          isError(true);
          setHelperText(field.rules[prop]);
          checkFieldValid.push(false);
        } else if (parseInt(value) < fieldFormData[fieldKey]) {
          isError(true);
          setHelperText(field.rules[prop]);
          checkFieldValid.push(false);
        } else {
          checkFieldValid.push(true);
        }
      } else {
        isError(false);
        setHelperText('');
        checkFieldValid.push(true);
      }
    });
    fieldFormValid[name] = checkFieldValid.includes(false) ? false : true;
    handlerFieldFormValid(fieldFormValid);
    if (fieldFormValid[name]) {
      fieldFormData[name] = value;
      handlerFieldFormData(fieldFormData);
    } else {
      fieldFormData[name] = '';
      handlerFieldFormData(fieldFormData);
    }
  };

  const handlerOnChange = (e) => {
    const { name, value } = e.target;
    setValue(value);
    isError(false);
    setHelperText('');
    getAtleastField(name, value);
    if (Object.entries(field.rules).length !== 0) {
      validate(name, value);
    } else {
      if (isNaN(+value)) {
        isError(true);
        setHelperText('Please enter a value in numeric format');
        fieldFormValid[name] = false;
      } else {
        isError(false);
        setHelperText('');
        fieldFormValid[name] = true;
      }
      handlerFieldFormValid(fieldFormValid);
      if (fieldFormValid[name]) {
        fieldFormData[name] = value;
        handlerFieldFormData(fieldFormData);
      } else {
        fieldFormData[name] = '';
        handlerFieldFormData(fieldFormData);
      }
    }
  };

  const checkIfValueIsNumber = (value, name) => {
    if (isNaN(+value)) {
      if (field.rules['numeric']) {
        setHelperText(field.rules['numeric']);
      } else {
        setHelperText('Please enter a value in numeric format');
      }
      isError(true);
      fieldFormValid[name] = false;
    } else {
      isError(false);
      setHelperText('');
      fieldFormValid[name] = true;
      getAtleastField(name, value);
      if (Object.entries(field.rules).length !== 0) validate(name, value);
    }
  };

  return (
    <>
      <TextField
        id={field.name}
        label={field.label}
        data-testid={field.name}
        autoComplete='off'
        {...register(field.name)}
        margin='dense'
        variant='outlined'
        value={value}
        fullWidth
        onChange={handlerOnChange}
        error={error && Boolean(error)}
        helperText={helperText}
        disabled={btnLoading}
        InputProps={{
          min: 0,
          classes: {
            root: classes.cssOutlinedInput,
            notchedOutline: !error ? classes.notchedOutline : '',
            input: classes.input,
          },
          startAdornment: field.name === AMOUNT ? <InputAdornment position='start'>Php</InputAdornment> : '',
        }}
        InputLabelProps={{
          className: error ? classes.inputLabelError : classes.inputLabel,
        }}
      />
    </>
  );
};

export default NumberField;
