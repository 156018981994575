import { makeStyles, Grid, Typography, Card, Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import Constants from '../../utils/Constants';
import bayadExpressLogo from '../../assets/image-logo/bayad-logo-brand.png';
import sessionExpire from '../../assets/image-logo/session-expire.png';
import clsx from 'clsx';
import { clearCookies, terminateSession } from '../../utils/Auth';

const myStyles = makeStyles((theme) => ({
  root: {
    minHeight: '90vh',
    textAlign: 'center',
  },
  container: {},
  sessionExpire: {
    width: '100px',
    height: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '60px',
      height: '60px',
    },
  },
  bold600: {
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontWeight: 500,
      fontSize: 14,
    },
  },
  bold500: {
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontWeight: 500,
      fontSize: 14,
    },
  },
  opacity09: {
    opacity: 0.9,
  },
  gridContent: {
    [theme.breakpoints.down('xs')]: {
      width: 'inherit',
    },
  },
  card: {
    boxShadow: 'none',
    borderRadius: '8px',
  },
  cardContainer: {
    padding: 30,
    maxWidth: 550,
    width: 550,
    [theme.breakpoints.down('xs')]: {
      padding: 30,
      width: 'inherit',
    },
  },
  cardFooter: {
    maxWidth: 600,
    width: 600,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    padding: '20px',
  },
  btnAction: {
    letterSpacing: '1px',
    font: 'normal normal normal 13px/20px Poppins',
    width: '224px',
    height: '39px',
    borderRadius: '21px',
    backgroundColor: '#E05A29 !important',
    color: 'white',
  },
  mail: {
    color: 'black',
  },
  footerLogo: {
    verticalAlign: 'middle',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100px',
      height: '30px',
    },
    height: '40px',
  },
  footer: {
    bottom: 100,
    position: 'fixed',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
}));

const SessionExpire = () => {
  const classes = myStyles();

  useEffect(() => {
    terminateSession();
    clearCookies();
  });

  return (
    <>
      <Container maxWidth={'xl'} className={classes.container}>
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2} className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <img className={classes.sessionExpire} src={sessionExpire} alt={'sessionExpire'}></img>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant='h6' className={clsx(classes.bold500, classes.opacity09)}>
              This page has expired due to inactivity.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridContent}>
            <Card className={classes.card}>
              <Grid container className={classes.cardContainer} direction='column' spacing={3}>
                <Grid item>
                  <Typography variant='h6' className={clsx(classes.bold600, classes.opacity09)}>
                    To make another session, please access Bayad Express again in the merchant’s website or mobile application.
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Container className={classes.cardFooter}>
              <Typography variant='body2' className={classes.opacity09}>
                For further assistance, reach out to Bayad Express by emailing
                <a className={classes.mail} href='mailto:support@bayad.com'>
                  {' '}
                  support@bayad.com
                </a>{' '}
                or calling our hotline at +632 8672 5777
              </Typography>
            </Container>
            {/* <Grid container className={classes.cardFooter} direction='column' justifyContent='center' alignItems='center' spacing={3}>
              <Grid item>
                <Typography variant='body2' className={classes.opacity09}>
                  For further assistance, reach out to Bayad Express by emailing
                  <a className={classes.mail} href='mailto:support@bayad.com'>
                    {' '}
                    support@bayad.com
                  </a>{' '}
                  or calling our hotline at +632 8672 5777
                </Typography>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Container className={classes.footer}>
              <span>
                Powered By <img className={classes.footerLogo} src={bayadExpressLogo} alt={'bayadExpressLogo'}></img>
              </span>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SessionExpire;
