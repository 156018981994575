import * as types from './BillerTypes';

const INITIAL_STATE = {
  billerInfo: null,
  billerList: null,
  billerForm: null,
  billerFormData: null,
  fieldValdiation: null,
  isLoading: false,
  error: {
    flag: false,
    msg: null,
    status: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_BILLER_LIST:
      return {
        ...state,
        isLoading: true,
        billerInfo: null,
        billerFormData: null,
        billerList: null,
        billerForm: null,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    case types.GET_BILLER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        billerList: action.payload,
      };
    case types.GET_BILLER_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        billerInfo: null,
        billerFormData: null,
        billerList: null,
        billerForm: null,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Get Biller List Error',
        },
      };
    case types.GET_BILLER:
      return {
        ...state,
        billerInfo: action.data,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    case types.BILLER_FORM_DATA:
      return {
        ...state,
        billerFormData: action.data,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    case types.GET_BILLER_FORM:
      return {
        ...state,
        isLoading: true,
        billerForm: null,
        billerFormData: null,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    case types.GET_BILLER_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        billerForm: action.payload,
      };
    case types.GET_BILLER_FORM_FAILED:
      return {
        ...state,
        isLoading: false,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Get Biller Form Error',
        },
      };
    case types.GET_BILLER_INFO:
      return {
        ...state,
        isLoading: true,
        billerInfo: null,
        billerFormData: null,
        billerForm: null,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    case types.GET_BILLER_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        billerInfo: action.payload,
      };
    case types.GET_BILLER_INFO_FAILED:
      return {
        ...state,
        isLoading: false,
        billerInfo: null,
        billerFormData: null,
        billerForm: null,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Get Biller List Error',
        },
      };
    case types.CLEAR_BILLER_FORM:
      return {
        ...state,
        isLoading: false,
        billerForm: null,
        billerFormData: null,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    case types.CLEAR_BILLER_LIST:
      return {
        ...state,
        isLoading: false,
        billerForm: null,
        billerFormData: null,
        billerList: null,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    case types.CLEAR_BILLER_FORM_DATA:
      return {
        ...state,
        isLoading: false,
        billerFormData: null,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    case types.SET_BILLER_FORM:
      return {
        ...state,
        isLoading: false,
        billerForm: action.data,
      };
    default:
      return state;
  }
};
