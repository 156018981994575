import 'date-fns';
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DATE, dateFieldFormat, dateFormatMatch, formattedDateValue, INVALID_DATE, INVALID_DATE_MSG, removeWhiteSpace } from './Controls';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

const myStyles = makeStyles((theme) => ({
  cssOutlinedInput: {
    //color: '#F16122',
  },
  notchedOutline: {
    borderColor: 'gray !important',
  },
  input: {
    '&::placeholder': {
      color: 'gray',
    },
  },
  inputLabel: {
    color: 'gray !important',
  },
  inputLabelError: {
    color: 'red !important',
  },
}));

const DateField = (props) => {
  const classes = myStyles();
  const { field, control, handlerFieldFormValid, fieldFormValid, btnLoading, handlerFieldFormData, fieldFormData, selectedData } = props;
  const [error, isError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const dateFieldFConfig = dateFieldFormat(removeWhiteSpace(field.dateFormat));

  useEffect(() => {
    let checkIfRequired = false;
    if (Object.keys(fieldFormValid).length > 0) {
      Object.keys(field.rules).forEach((prop) => {
        if (prop.includes('required_if:')) {
          checkIfRequired = true;
        } else if (prop === 'required') {
          checkIfRequired = true;
        }
      });
      if (!checkIfRequired) {
        fieldFormValid[field.name] = true;
        handlerFieldFormValid(fieldFormValid);
      }
    }
  }, [fieldFormValid]);

  useEffect(() => {
    if (Object.keys(selectedData).length > 0 && selectedData.comparedFields.includes(field.name)) {
      selectDataChange(selectedData);
    }
  }, [selectedData]);

  const selectDataChange = (selectedData) => {
    return Object.keys(field.rules).forEach((prop) => {
      if (prop.includes('required_if:')) {
        const fieldKey = prop.substring(prop.indexOf(':') + 1, prop.indexOf(','));
        const fieldValue = prop.substring(prop.indexOf(',') + 1);

        if (fieldKey === selectedData.name && fieldValue === selectedData.value) {
          if (!selectedDate || selectedDate === '') {
            isError(true);
            setHelperText(field.rules[prop]);
            fieldFormValid[field.name] = false;
          } else {
            fieldFormValid[field.name] = true;
          }
        } else {
          isError(false);
          setHelperText('');
          fieldFormValid[field.name] = true;
        }
        handlerFieldFormValid(fieldFormValid);
        if (fieldFormValid[field.name]) {
          fieldFormData[field.name] = selectedDate;
          handlerFieldFormData(fieldFormData);
        } else {
          fieldFormData[field.name] = '';
          handlerFieldFormData(fieldFormData);
        }
      }
    });
  };

  const validate = (value) => {
    if (value.toUpperCase() === INVALID_DATE) {
      isError(true);
      setHelperText(INVALID_DATE_MSG);
      fieldFormValid[field.name] = false;
    } else if (Object.entries(field.rules).length !== 0) {
      Object.keys(field.rules).forEach((prop) => {
        if (prop === 'required') {
          if (!value || value === '') {
            isError(true);
            setHelperText(field.rules[prop]);
            fieldFormValid[field.name] = false;
          } else {
            fieldFormValid[field.name] = true;
          }
        } else if (prop.includes('required_if:')) {
          const fieldKey = prop.substring(prop.indexOf(':') + 1, prop.indexOf(','));
          const fieldValue = prop.substring(prop.indexOf(',') + 1);
          if (fieldFormData[fieldKey] === fieldValue) {
            if (!value || value === '') {
              isError(true);
              setHelperText(field.rules[prop]);
              fieldFormValid[field.name] = false;
            } else {
              fieldFormValid[field.name] = true;
            }
          } else {
            isError(false);
            setHelperText('');
            fieldFormValid[field.name] = true;
          }
        } else if (prop.includes('date:')) {
          if (value || value !== '') {
            const format = field.dateFormat;
            if (!dateFormatMatch(format, value)) {
              isError(true);
              setHelperText(field.rules[prop]);
              fieldFormValid[field.name] = false;
            } else {
              fieldFormValid[field.name] = true;
            }
          }
        } else {
          isError(false);
          setHelperText('');
          fieldFormValid[field.name] = true;
        }
      });
    } else {
      isError(false);
      setHelperText('');
      fieldFormValid[field.name] = true;
    }
    handlerFieldFormValid(fieldFormValid);
    if (fieldFormValid[field.name]) {
      fieldFormData[field.name] = value;
      handlerFieldFormData(fieldFormData);
    } else {
      fieldFormData[field.name] = '';
      handlerFieldFormData(fieldFormData);
    }
  };

  const handleDateChange = (date) => {
    isError(false);
    setHelperText('');
    setSelectedDate(date);
    if (Object.entries(field.rules).length !== 0) validate(date);
  };

  const handleClearDate = () => {
    isError(false);
    setHelperText('');
    setSelectedDate(null);
    fieldFormValid[field.name] = false;
    handlerFieldFormValid(fieldFormValid);
    fieldFormData[field.name] = '';
    handlerFieldFormData(fieldFormData);
    if (Object.entries(field.rules).length !== 0) validate('');
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent='space-around'>
          <Controller
            name={field.name}
            control={control}
            initialFocusedDate={null}
            defaultValue={null}
            render={({ field: { onChange, ref, ...rest } }) => (
              <KeyboardDatePicker
                margin='dense'
                clearable
                openTo={dateFieldFConfig.openTo}
                views={dateFieldFConfig.views}
                format={dateFieldFConfig.datePickerFormat}
                disabled={btnLoading}
                inputVariant='outlined'
                fullWidth
                id={field.name}
                data-testid={field.name}
                label={field.label}
                onChange={(e) => {
                  if (e) {
                    onChange(formattedDateValue(e, field.dateFormat).dateDisplay);
                    handleDateChange(formattedDateValue(e, field.dateFormat).dateValue);
                  } else {
                    onChange(null);
                    handleClearDate();
                  }
                }}
                error={error && Boolean(error)}
                helperText={helperText}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                {...rest}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.cssOutlinedInput,
                    notchedOutline: !error ? classes.notchedOutline : '',
                    input: classes.input,
                  },
                }}
                InputLabelProps={{
                  className: error ? classes.inputLabelError : classes.inputLabel,
                }}
              />
            )}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default DateField;
