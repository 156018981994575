import api from '../../utils/Api';
import { AUTHENTICATE } from '../../utils/ApiUrl';
import { storeToken } from '../../utils/Auth';

export const authentication = async (id, sessionId) => {
  try {
    let response = await api({
      url: AUTHENTICATE,
      method: 'POST',
      data: {
        id,
        sessionId,
      },
    });

    storeToken(response.data.data.access_token);
    return response.data;
  } catch (error) {
    throw error;
  }
};
