import * as types from './CategoryTypes';

const INITIAL_STATE = {
  categoryList: null,
  categoryBillers: null,
  isLoading: false,
  error: {
    flag: false,
    msg: null,
    status: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CATEGORY_LIST:
      return {
        ...state,
        isLoading: true,
        categoryList: null,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    case types.GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoryList: action.payload,
      };
    case types.GET_CATEGORY_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        categoryList: null,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Get Category List Error',
        },
      };
    case types.GET_CATEGORY_BILLERS:
      return {
        ...state,
        isLoading: true,
        categoryBillers: null,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    case types.GET_CATEGORY_BILLERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoryBillers: action.payload,
      };
    case types.GET_CATEGORY_BILLERS_FAILED:
      return {
        ...state,
        isLoading: false,
        categoryBillers: null,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Get Category Billers Error',
        },
      };
    case types.CLEAR_CATEGORY_BILLERS:
      return {
        ...state,
        isLoading: false,
        categoryBillers: null,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    default:
      return state;
  }
};
