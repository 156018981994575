import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import sagas from './sagas';
import rootReducer from './reducers';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();

export default (initialState = {}) => {
  const enhancers = [applyMiddleware(sagaMiddleware, logger)];

  const persistConfig = {
    key: 'bayadExpressPersist',
    storage: storage,
    whitelist: ['token', 'biller', 'payment', 'transaction', 'category'], // which reducer want to store
  };

  const pReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(pReducer, initialState, compose(...enhancers));
  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);

  return { persistor, store };
};
