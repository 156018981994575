import React from 'react';
import { Container, CssBaseline, Grid, makeStyles, Typography } from '@material-ui/core';
import constant from '../../utils/Constants';
import maintenanceLogo from '../../assets/image-logo/bayad-express-logo@2x.png';
import maintenance from './assets/maintenance-bg2x.png';
import maintenanceMobile from './assets/maintenance-bg-mobile2x.png';

const myStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    margin: 'auto',
  },
  maintenanceLogo: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    padding: '10px',
    width: 240,
    height: 75,
    [theme.breakpoints.down('xs')]: {
      width: 190,
      height: 63,
    },
  },
  container: {
    backgroundImage: `url(${maintenance})`,
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${maintenanceMobile})`,
      backgroundPosition: 'center',
    },
    height: '100vh',
    minHeight: '100%',
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center !important',
    },
    borderSpacing: '15px',
  },
  contentHeader: {
    paddingBottom: '20px',
  },
  contentFooter: {
    paddingTop: '20px',
  },
  bold: {
    fontWeight: 600,
  },
}));

const Maintenance = (props) => {
  const classes = myStyles();
  return (
    <div>
      <CssBaseline />
      {process.env.REACT_APP_MAINTENANCE_MODE !== 1 ? (
        <Container className={classes.container}>
          <img className={classes.maintenanceLogo} src={maintenanceLogo} alt='maintenanceLogo'></img>
          <Grid container component='main' className={classes.root} direction='row-reverse' justifyContent='center' alignItems='center'>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container direction='column' className={classes.content}>
                <Grid item className={classes.contentHeader}>
                  <Typography variant='h5' className={classes.bold}>
                    {constant.maintenance_mode_header}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='subtitle1'>{constant.maintenance_mode_body}</Typography>
                  <Typography variant='subtitle1'>{constant.maintenance_mode_body1}</Typography>
                </Grid>
                <Grid item className={classes.contentFooter}>
                  <Typography variant='subtitle1'>{constant.maintenance_mode_body2}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}></Grid>
          </Grid>
        </Container>
      ) : (
        <>.</>
      )}
    </div>
  );
};

export default Maintenance;
