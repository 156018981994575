import React, { CircularProgress, Container, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import { useEffect, useState } from 'react';
import Constants from '../../utils/Constants';
import dataNotFound from '../../assets/image-logo/no-data-found.png';
import imageDefault from '../../assets/image-logo/bayad-logo.png';

const myStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    borderTop: '1px solid rgb(224 224 224)',
  },
  billerLogo: {
    backgroundColor: 'rgb(255 255 255)',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 1,
    padding: 2,
  },
  iconArrowRight: {
    cursor: 'pointer',
    color: '#979797',
  },
  bold: {
    fontWeight: 600,
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCellLogo: {
    width: 100,
    height: 100,
    [theme.breakpoints.down('xs')]: {
      width: 75,
      height: 75,
    },
    [theme.breakpoints.down('sm')]: {
      width: 75,
      height: 75,
    },
  },
  tableCellBiller: {
    paddingLeft: 30,
  },
  loading: {
    color: '#F16122',
  },
  loadingContainer: {
    textAlign: 'center',
  },
  dataNotFound: {},
  resultCount: {
    textAlign: 'end',
  },
}));

const BillerListTable = (props) => {
  let { handlerGetBiller, selectedBiller, searchBiller, billerList } = props;
  const classes = myStyles();
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    searchBiller = '';
  }, []);

  useEffect(() => {
    setTableList([...billerList.filter((row) => row.name.toUpperCase().includes(searchBiller.toUpperCase()))]);
  }, [searchBiller]);

  useEffect(() => {
    if (billerList) setTableList(billerList);
  }, [billerList]);

  return (
    <>
      {billerList.length !== 0 ? (
        // <div className={classes.resultCount}>
        //   <div>
        //     <p>
        //       <i>
        //         <b>{tableList.length}</b> biller/s found
        //       </i>
        //     </p>
        //   </div>
        <TableContainer data-testid='BillerListTable'>
          <Table className={classes.tableContainer}>
            <TableBody>
              {tableList.length !== 0 ? (
                tableList
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map((row, index) => (
                    <TableRow
                      className={classes.tableRow}
                      hover={true}
                      selected={selectedBiller?.billerCode === row.code ? true : false}
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => handlerGetBiller(row)}>
                      <TableCell align='left' padding='none' className={classes.tableCellLogo}>
                        <img className={classes.billerLogo} src={row.logo} onError={(e) => (e.target.src = imageDefault)} alt={row.code}></img>
                      </TableCell>
                      <TableCell align='left' padding='none' className={classes.tableCellBiller}>
                        <Typography variant='subtitle1' className={classes.bold}>
                          {row.name}
                        </Typography>
                        <Typography variant='subtitle2'>{row.category}</Typography>
                      </TableCell>
                      <TableCell align='right' padding='none'>
                        <KeyboardArrowRightSharpIcon className={classes.iconArrowRight} fontSize='large' />
                      </TableCell>
                    </TableRow>
                  ))
              ) : searchBiller !== '' ? (
                <TableRow>
                  <TableCell align='center'>
                    <img className={classes.dataNotFound} src={dataNotFound} alt={'dataNotFound'}></img>
                    <Typography variant='subtitle1'>{Constants.search_not_found}</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell align='center'></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        // </div>
        <Container className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />
        </Container>
      )}
    </>
  );
};

export default BillerListTable;
