import { makeStyles, Grid, Typography, Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import invalidLogo from '../../assets/image-logo/invalid-logo.png';
import constants from '../../utils/Constants';
import bayadExpressLogo from '../../assets/image-logo/bayad-express-logo.png';
import { useHistory } from 'react-router';

const myStyles = makeStyles(() => ({
  root: {
    minHeight: '80vh',
    textAlign: 'center',
  },
  invalidLogo: {},
  bold: {
    fontWeight: 600,
  },
  bayadExpressLogo: {},
}));

const InvalidPage = () => {
  const classes = myStyles();
  const history = useHistory();

  useEffect(() => {
    if (process.env.REACT_APP_MAINTENANCE_MODE == 1) {
      history.push('/v1/maintenance');
    }
  }, []);

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3} direction='column' justifyContent='center' alignItems='center' className={classes.root}>
          <Grid item>
            <img className={classes.invalidLogo} src={invalidLogo} alt={'invalidLogo'}></img>
          </Grid>
          <Grid item>
            <Typography variant='h5' className={classes.bold}>
              {constants.invalid_title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>{constants.invalid_body}</Typography>
          </Grid>
        </Grid>
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          <Grid item>
            <img className={classes.bayadExpressLogo} src={bayadExpressLogo} alt='bayadExpressLogo'></img>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default InvalidPage;
