import * as types from './TransactionTypes';

const INITIAL_STATE = {
  transactionData: null,
  checkoutUrl: null,
  isLoading: false,
  transactionInquiry: null,
  error: {
    flag: false,
    msg: null,
    status: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TRANSACTION_DATA:
      return {
        ...state,
        isLoading: false,
        transactionData: action.data,
      };
    case types.PAYMENT_ACKNOWLEDGEMENT:
      return {
        ...state,
        transactionData: null,
        isLoading: true,
      };
    case types.PAYMENT_ACKNOWLEDGEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactionData: action.payload.data,
      };
    case types.PAYMENT_ACKNOWLEDGEMENT_FAILED:
      return {
        ...state,
        isLoading: false,
        transactionData: null,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Update Transaction Error',
        },
      };
    case types.GET_TRANSACTION_INQUIRY:
      return {
        ...state,
        transactionInquiry: null,
        isLoading: true,
      };
    case types.GET_TRANSACTION_INQUIRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactionInquiry: action.payload.data,
      };
    case types.GET_TRANSACTION_INQUIRY_FAILED:
      return {
        ...state,
        isLoading: false,
        transactionInquiry: null,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Get Transaction Details Error',
        },
      };
    case types.GET_CHECKOUT_URL:
      return {
        ...state,
        checkoutUrl: null,
        isLoading: true,
      };
    case types.GET_CHECKOUT_URL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        checkoutUrl: action.payload.data,
      };
    case types.GET_CHECKOUT_URL_FAILED:
      return {
        ...state,
        isLoading: false,
        checkoutUrl: null,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Checkout URL',
        },
      };
    case types.CLEAR_TRANSACTION:
      return INITIAL_STATE;
    default:
      return state;
  }
};
