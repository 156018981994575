import { Button, CircularProgress, Container, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Controls, {
  initialFormData,
  checkFormIsValid,
  DATE_PICKER,
  initialFormValidation,
  NUMBER,
  PROCEED_TO_CHECKOUT,
  SELECT,
  TEXT,
  DATE_TIME_PICKER,
  setFormDetails,
  DATE_YEAR_RANGE,
  DATE_YEAR_RANGE_FORMAT,
  deleteStartAndEndYearField,
  removeWhiteSpace,
  removeEmptyString,
} from './Controls/Controls';
import { useForm } from 'react-hook-form';
import dot from 'dot-object';

const myStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
  },
  buttonNext: {
    backgroundColor: '#E05A29 !important',
    color: 'white',
    letterSpacing: '0.5px',
    marginBottom: '5px',
    textTransform: 'none',
  },
  buttonNextDisabled: {
    backgroundColor: '#f19978 !important',
    color: 'white !important',
    letterSpacing: '0.5px',
    marginBottom: '5px',
    textTransform: 'none',
  },
  loading: {
    color: '#F16122',
  },
  loadingContainer: {
    textAlign: 'center',
  },
  btnLoading: {
    color: 'white',
  },
}));

const BillerFields = (props) => {
  const { formData, handlerGetFormData, setBtnLoading } = props;
  const classes = myStyles();
  const [isFormValid, setIsFormValid] = useState(false);
  const [fieldFormValid, setFieldFormValid] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [atleastField, setAtleastField] = useState([]);
  const [fieldFormData, setFieldFormData] = useState({});

  const { register, handleSubmit, control } = useForm({ mode: 'onChange' });

  useEffect(() => {
    setFieldFormValid(initialFormValidation(formData));
    setFieldFormData(initialFormData(formData));
  }, [formData]);

  const handlerFieldFormValid = (form) => {
    setFieldFormValid(form);
    const valid = checkFormIsValid(form);
    setIsFormValid(valid);
  };

  const handlerFieldFormData = (form) => {
    setFieldFormData(form);
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const getSelectedData = (data) => {
    setSelectedData(data);
  };

  return (
    <>
      {formData.length !== 0 ? (
        <div className={classes.container}>
          <form
            noValidate
            onKeyDown={(e) => checkKeyDown(e)}
            onSubmit={handleSubmit((data, e) => {
              const formdata = deleteStartAndEndYearField(fieldFormData);
              //const buttonName = e.nativeEvent.submitter.name;
              const buttonName = PROCEED_TO_CHECKOUT;
              dot.object(formdata);
              removeEmptyString(formdata);
              handlerGetFormData(formdata, buttonName);
            })}>
            {formData.map((data, index) =>
              data.type === TEXT ? (
                <Controls.InputField
                  field={data}
                  key={index}
                  register={register}
                  handlerFieldFormValid={(data) => {
                    handlerFieldFormValid(data);
                  }}
                  fieldFormValid={fieldFormValid}
                  btnLoading={setBtnLoading}
                  selectedData={selectedData}
                  handlerFieldFormData={(data) => {
                    handlerFieldFormData(data);
                  }}
                  fieldFormData={fieldFormData}
                />
              ) : data.type === NUMBER ? (
                <Controls.NumberField
                  field={data}
                  key={index}
                  register={register}
                  handlerFieldFormValid={(data) => {
                    handlerFieldFormValid(data);
                  }}
                  selectedData={selectedData}
                  handlerFieldFormData={(data) => {
                    handlerFieldFormData(data);
                  }}
                  handleAtleastField={(data) => {
                    setAtleastField(data);
                  }}
                  atleastField={atleastField}
                  fieldFormData={fieldFormData}
                  fieldFormValid={fieldFormValid}
                  btnLoading={setBtnLoading}
                />
              ) : removeWhiteSpace(data.dateFormat) !== DATE_YEAR_RANGE_FORMAT && data.type === DATE_PICKER ? (
                <Controls.DateField
                  field={data}
                  key={index}
                  register={register}
                  control={control}
                  selectedData={selectedData}
                  handlerFieldFormValid={(data) => {
                    handlerFieldFormValid(data);
                  }}
                  fieldFormValid={fieldFormValid}
                  handlerFieldFormData={(data) => {
                    handlerFieldFormData(data);
                  }}
                  fieldFormData={fieldFormData}
                  btnLoading={setBtnLoading}
                />
              ) : removeWhiteSpace(data.dateFormat) === DATE_YEAR_RANGE_FORMAT && data.type === DATE_PICKER ? (
                <Controls.DateYearRange
                  field={data}
                  key={index}
                  register={register}
                  control={control}
                  selectedData={selectedData}
                  handlerFieldFormValid={(data) => {
                    handlerFieldFormValid(data);
                  }}
                  fieldFormValid={fieldFormValid}
                  handlerFieldFormData={(data) => {
                    handlerFieldFormData(data);
                  }}
                  fieldFormData={fieldFormData}
                  btnLoading={setBtnLoading}
                />
              ) : data.type === SELECT ? (
                <Controls.SelectField
                  field={data}
                  key={index}
                  register={register}
                  control={control}
                  handlerFieldFormValid={(data) => {
                    handlerFieldFormValid(data);
                  }}
                  fieldFormValid={fieldFormValid}
                  handlerFieldFormData={(data) => {
                    handlerFieldFormData(data);
                  }}
                  fieldFormData={fieldFormData}
                  btnLoading={setBtnLoading}
                  selectedData={selectedData}
                  handlerSelectedData={(data) => {
                    getSelectedData(data);
                  }}
                />
              ) : (
                ''
              )
            )}
            <Button
              className={isFormValid && !setBtnLoading ? classes.buttonNext : classes.buttonNextDisabled}
              variant='contained'
              type='submit'
              data-testid={'BillerFormNextBtn'}
              fullWidth
              name={PROCEED_TO_CHECKOUT}
              disabled={!isFormValid || setBtnLoading}>
              {setBtnLoading ? <CircularProgress size={28} className={classes.btnLoading} /> : 'Next'}
            </Button>
            {/* <Button
              className={isFormValid ? classes.buttonNext : classes.buttonNextDisabled}
              variant='contained'
              type='submit'
              fullWidth
              name={ADD_TO_CART}
              disabled={!isFormValid}>
              Add to cart
            </Button> */}
          </form>
        </div>
      ) : (
        <Container className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />
        </Container>
      )}
    </>
  );
};

export default BillerFields;
