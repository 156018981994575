import React, { Card, CardContent, CardMedia, CircularProgress, Container, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { getCategoryLogo } from '../../utils/HelperUtil';

const myStyles = makeStyles((theme) => ({
  // categoryLogo: {
  //   paddingTop: '35px',
  //   paddingLeft: '35px',
  //   paddingRight: '35px',
  //   paddingBottom: '15px !important',
  //   [theme.breakpoints.down('xs')]: {
  //     paddingTop: '15px',
  //     paddingLeft: '15px',
  //     paddingRight: '15px',
  //     paddingBottom: '5px',
  //   },
  // },
  cardContent: {
    textAlign: 'center',
    padding: '0px',
    '&:last-child': {
      paddingBottom: '5px !important',
    },
  },
  card: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 87%), 0px 1px 1px 0px rgb(0 0 0 / 87%), 0px 1px 3px 0px rgb(0 0 0 / 87%)',
    },
    boxShadow: 'none',
  },
  bold: {
    fontWeight: 600,
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: '9px',
    },
  },
  loading: {
    color: '#F16122',
  },
  loadingContainer: {
    textAlign: 'center',
  },
  notFoundContainer: {
    textAlign: 'center',
    marginTop: '25px',
    marginBottom: '25px',
  },
  tooltip: {
    backgroundColor: 'rgb(0 118 191)',
    color: 'white',
  },
  cardLogo: {
    height: 140,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 120,
    },
    [theme.breakpoints.down('sm')]: {
      height: 75,
    },
  },
  categoryLogo: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

const CategoryListTiled = (props) => {
  const classes = myStyles();
  const { categoryList, handlerGetCategory } = props;

  return (
    <>
      {categoryList ? (
        <Grid container direction='row' spacing={3} data-testid='CategoryListTiled'>
          {categoryList
            .sort((a, b) => (a.category_order > b.category_order ? 1 : -1))
            .map((row, index) => (
              <Grid item xs={3} sm={3} md={2} lg={2} key={index}>
                <Tooltip
                  title={row.category}
                  placement='bottom'
                  classes={{
                    tooltip: classes.tooltip,
                  }}>
                  <Card onClick={() => handlerGetCategory(row)} className={classes.card}>
                    {/* <CardMedia className={classes.categoryLogo} component='img' image={getCategoryLogo(row.category_id, false)} alt={row.category} /> */}
                    <CardContent className={classes.cardLogo}>
                      <img className={classes.categoryLogo} src={getCategoryLogo(row.category, false)} alt={row.category}></img>
                    </CardContent>
                    <CardContent className={classes.cardContent}>
                      <Typography variant='subtitle2' className={classes.bold}>
                        {row.category}
                      </Typography>
                    </CardContent>
                  </Card>
                </Tooltip>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Container className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />{' '}
        </Container>
      )}
    </>
  );
};

export default CategoryListTiled;
