import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Constants from '../../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { clearTransaction, paymentAcknowledgement, setTransactionData } from '../../store/Transaction/TransactionAction';
import Loader from '../../containers/Loader/Loader';
import { getUrlStatus, getUrlTransactionId } from '../../utils/ApiUrl';
import {
  getMerchantData,
  getMerchantId,
  getTransactionId,
  getMerchantName,
  storeTransactionId,
  terminateSession,
  removeBayadExpressPersist,
  getMerchantClientId,
} from '../../utils/Auth';
import Cookies from 'js-cookie';
import { getSignature } from '../../store/Session/SessionAction';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Acknowledgement = (props) => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const transactionData = useSelector((state) => state.transaction.transactionData);
  const signatureData = useSelector((state) => state.session.signature);
  const transactionError = useSelector((state) => state.transaction.error);
  const urlStatus = getUrlStatus(history.location.pathname);
  const urlTransactionId = getUrlTransactionId(query.get('transactionId'));
  const paymentType = query.get('paymentType');
  const sessionTransactionId = getTransactionId(); //|| Cookies.get('transactionId') //for cookie storage
  const id = Cookies.get('merchantClientId') || getMerchantClientId();
  const name = Cookies.get('merchantName') || getMerchantName();
  const data = Cookies.get('merchantData') || getMerchantData();

  useEffect(() => {
    if (sessionTransactionId !== urlTransactionId) {
      removeBayadExpressPersist();
      history.push('/v1/0?msg=Please check that URL is correct and and try again.');
    } else if (urlTransactionId && urlStatus) {
      if (!signatureData) {
        //dispatch(clearTransaction());
        dispatch(getSignature(id, name, data));
      } else if (signatureData && !transactionData) {
        dispatch(paymentAcknowledgement(urlTransactionId));
      }
    } else {
      history.push('/v1/0?msg=Please check that URL is correct and and try again.');
    }
  }, [signatureData]);

  useEffect(() => {
    const validStatus = [Constants.status_success, Constants.status_failed, Constants.status_cancel];
    if (transactionData) {
      if (!validStatus.includes(urlStatus) || transactionData.transactionUpdate) {
        terminateSession();
        history.push('/v1/0?msg=Please check that URL is correct and and try again.');
      } else {
        storeTransactionId(urlTransactionId);
        const domainName = process.env.REACT_APP_DOMAIN_NAME;
        const timestamp = signatureData.timestamp;
        const signature = signatureData.generatedCode;
        //get signature
        const expressUrl = `${domainName}?id=${id}&name=${name}&timestamp=${timestamp}&data=${data}&signature=${signature}&backToExpress=true`;
        dispatch(setTransactionData({ ...transactionData, expressUrl: expressUrl }));
        let type = '';
        if (urlStatus === Constants.status_cancel && transactionData.status === Constants.status_expired) {
          type = Constants.status_expired;
        } else {
          type = urlStatus;
        }

        history.push('/v1/acknowledgement-status?type=' + type);
      }
    }
  }, [transactionData]);

  useEffect(() => {
    if (transactionError.flag) {
      history.push('/v1/0?msg=Please check that URL is correct and and try again.');
    }
  }, [transactionError]);

  return <>{!transactionData ? <Loader /> : ''}</>;
};

export default Acknowledgement;
