import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EmailValidation from '../../containers/EmailValidation/EmailValidation';
import { getTransactionInquiry } from '../../store/Transaction/TransactionAction';
import { removeTransactionId, storeTransactionId } from '../../utils/Auth';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const EmailInquiry = (props) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const transactionId = query.get('transactionId');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const transactionInquiry = useSelector((state) => state.transaction.transactionInquiry);
  const transactionError = useSelector((state) => state.transaction.error);
  const dispatch = useDispatch();

  useEffect(() => {
    removeTransactionId();
    if (transactionId === null) {
      history.push('/v1/0?msg=Please check that URL is correct and and try again.');
    }
  }, []);

  useEffect(() => {
    if (transactionInquiry) {
      if (transactionInquiry.email_valid) {
        setInvalidEmail(false);
        storeTransactionId(transactionInquiry.transaction_id);
        history.push('/v1/transaction-details?status=' + transactionInquiry.status);
      } else {
        setInvalidEmail(true);
      }
      setBtnLoading(false);
    }
  }, [transactionInquiry]);

  useEffect(() => {
    if (transactionError.flag) {
      setBtnLoading(false);
      setInvalidEmail(true);
    }
  }, [transactionError]);

  const handleEmailSubmit = (data) => {
    setInvalidEmail(false);
    setBtnLoading(true);
    removeTransactionId();
    dispatch(getTransactionInquiry(transactionId, data.email));
  };

  return (
    <>
      <EmailValidation setBtnLoading={btnLoading} invalidEmail={invalidEmail} handleEmailSubmit={handleEmailSubmit} />
    </>
  );
};

export default EmailInquiry;
