import * as types from './PaymentTypes';

const INITIAL_STATE = {
  paymentList: null,
  isLoading: false,
  paymentVerified: null,
  paymentConfirmed: null,
  error: {
    flag: false,
    msg: null,
    status: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PAYMENT_LIST:
      return {
        ...state,
        paymentList: action.data,
      };
    case types.CLEAR_PAYMENT:
      return INITIAL_STATE;
    case types.VERIFY_ACCOUNT:
      return {
        ...state,
        paymentVerified: null,
        paymentConfirmed: null,
        isLoading: true,
      };
    case types.VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentVerified: action.payload.data,
      };
    case types.VERIFY_ACCOUNT_FAILED:
      return {
        ...state,
        isLoading: false,
        paymentVerified: null,
        paymentConfirmed: null,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Verify Account Error',
          details: action.error.response ? action.error.response.data.details : 'Verify Account Error',
        },
      };
    case types.BILLER_CONFIRMATION:
      return {
        ...state,
        paymentConfirmed: null,
        isLoading: true,
      };
    case types.BILLER_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentConfirmed: action.payload.data,
      };
    case types.BILLER_CONFIRMATION_FAILED:
      return {
        ...state,
        isLoading: false,
        paymentVerified: null,
        paymentConfirmed: null,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Biller Confirmation Error',
        },
      };
    case types.CLEAR_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        paymentVerified: null,
        paymentConfirmed: null,
        error: {
          flag: false,
          msg: null,
          status: null,
        },
      };
    default:
      return state;
  }
};
