import { GET_TOKEN, REMOVE_TOKEN } from '../Token/TokenTypes';

export const getToken = (merchantId, sessionId) => {
  return {
    type: GET_TOKEN,
    merchantId,
    sessionId,
  };
};

export const removeToken = () => {
  return {
    type: REMOVE_TOKEN,
  };
};
