import { Container, Grid, makeStyles, Typography, TextField, InputAdornment, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import BaseContainer from '../../containers/BaseContainer/BaseContainer';
import BaseBackTop from '../../containers/BaseBackTop/BaseBackTop';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import { useState } from 'react';
import FAQData from '../../components/FrequentlyAskQuestion/FAQData';
import AddIcon from '@material-ui/icons/Add';
import { getBillerCount } from '../../utils/Auth';

const myStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: '30px',
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  bold: {
    fontWeight: 600,
  },
  cssOutlinedInput: {
    color: '#F16122',
  },
  notchedOutline: {
    borderColor: 'gray !important',
  },
  input: {
    '&::placeholder': {
      color: 'gray',
    },
  },
  iconSearch: {
    color: '#F16122',
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    color: 'rgb(16 125 195)',
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 87%), 0px 1px 1px 0px rgb(0 0 0 / 87%), 0px 1px 3px 0px rgb(0 0 0 / 87%)',
    },
  },
  accordionContent: {
    padding: '8px 30px 10px 40px',
    flexWrap: 'wrap',
  },
  subContent: {
    marginBlockEnd: '0px',
    paddingInlineStart: '30px',
    width: '100%',
  },
  addIcon: {
    marginRight: 5,
    color: 'rgb(16 125 195)',
    fontWeight: 600,
  },
  expanded: {},
}));

const FrequentlyAskQuestion = (props) => {
  const classes = myStyles();
  const [searchText, setSearchText] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [faqTable, setFaqTable] = useState(FAQData);

  const handleSearchChange = (event) => {
    setExpanded(false);
    setSearchText(event.target.value);
    if (event.target.value === '') {
      setFaqTable(FAQData);
    } else {
      setFaqTable([...FAQData.filter((row) => row.title.toUpperCase().includes(event.target.value.toUpperCase()))]);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <BaseContainer disableBack={false} faqActive={true} contentWidth={'lg'}>
        <Container maxWidth='lg'>
          <BaseBackTop backRedirect={getBillerCount() > 20 ? '/biller-category' : '/merchant-biller?listType=all'} headerTitle={''} />
        </Container>
        <Container maxWidth='lg' className={classes.container}>
          <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant='h6' paragraph={true} className={classes.bold} align='center'>
                Finding Something?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                hiddenLabel
                size='small'
                fullWidth={true}
                placeholder='How would I know what you need to know?'
                value={searchText}
                onChange={handleSearchChange}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                  },
                  endAdornment: (
                    <InputAdornment position='start'>
                      <SearchSharpIcon className={classes.iconSearch} />
                    </InputAdornment>
                  ),
                }}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {faqTable
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map((topic, topicKey) => (
                  <Accordion expanded={expanded === `panel${topicKey + 1}`} onChange={handleChange(`panel${topicKey + 1}`)} key={topicKey + 1}>
                    <AccordionSummary
                      key={topicKey + 1}
                      className={classes.accordionSummary}
                      expandIcon={<AddIcon />}
                      classes={{
                        expandIcon: classes.addIcon,
                        expanded: classes.expanded,
                      }}
                      aria-controls={`panel${topicKey + 1}bh-content`}
                      id={`panel${topicKey + 1}bh-header`}>
                      <Typography variant='subtitle1' className={classes.bold}>
                        {topic.title}
                      </Typography>
                    </AccordionSummary>
                    {topic.content.map((content, contentKey) => (
                      <AccordionDetails className={classes.accordionContent} key={contentKey + 1}>
                        <Typography variant='subtitle2'>{content.title}</Typography>
                        {content.subContent.length !== 0 ? (
                          <ul key={contentKey + 1} className={classes.subContent}>
                            {content.subContent.map((subContent, subContentKey) => (
                              <li key={subContentKey + 1}>
                                <Typography variant='subtitle2'>{subContent}</Typography>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ''
                        )}
                      </AccordionDetails>
                    ))}
                  </Accordion>
                ))}
            </Grid>
          </Grid>
        </Container>
      </BaseContainer>
    </>
  );
};

export default FrequentlyAskQuestion;
