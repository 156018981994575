import * as types from './SessionTypes';

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  isSession: false,
  signature: null,
  error: {
    flag: false,
    code: '',
    msg: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_SESSION:
      return {
        ...state,
        isLoading: true,
        signature: null,
        error: {
          flag: false,
          code: '',
          msg: null,
        },
      };
    case types.GET_SESSION_SUCCESS:
      return {
        ...state,
        isSession: true,
        signature: null,
        data: action.payload.data,
        isLoading: false,
        error: {
          flag: false,
          code: '',
          msg: null,
        },
      };
    case types.GET_SESSION_FAILED:
      return {
        ...state,
        isSession: false,
        signature: null,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Verify URL Error',
        },
        isLoading: false,
      };
    case types.GET_SIGNATURE:
      return {
        ...state,
        isLoading: true,
        signature: null,
        error: {
          flag: false,
          code: '',
          msg: null,
        },
      };
    case types.GET_SIGNATURE_SUCCESS:
      return {
        ...state,
        signature: action.payload.data,
        isLoading: false,
        error: {
          flag: false,
          code: '',
          msg: null,
        },
      };
    case types.GET_SIGNATURE_FAILED:
      return {
        ...state,
        isSession: false,
        signature: null,
        error: {
          flag: true,
          code: action.error.response ? action.error.response.data.code : '',
          msg: action.error.response ? action.error.response.data.message : 'Get Signature Error',
        },
        isLoading: false,
      };
    case types.TERMINATE_SESSION:
      return INITIAL_STATE;
    default:
      return state;
  }
};
