import React, { List, ListItem, CircularProgress, Container, makeStyles, Typography, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCategoryLogo, moveArrayItemToNewIndex } from '../../utils/HelperUtil';
import { useSelector } from 'react-redux';
import { getBillerCount } from '../../utils/Auth';

const myStyles = makeStyles((theme) => ({
  categoryLogo: {
    backgroundColor: 'rgb(255 255 255)',
    width: 75,
    height: 75,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 65,
      height: 65,
    },
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
  },
  categoryLogoInvalid: {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    backgroundColor: 'rgb(255 255 255)',
    width: 70,
    height: 70,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 65,
      height: 65,
    },
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
  },
  bold: {
    fontWeight: 600,
    maxWidth: '90px',
    wordWrap: 'break-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      maxWidth: '85px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      maxWidth: '75px',
    },
    textAlign: 'center',
  },
  boldActive: {
    fontWeight: 600,
    color: '#E05A29',
    maxWidth: '90px',
    wordWrap: 'break-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      maxWidth: '85px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      maxWidth: '75px',
    },
    textAlign: 'center',
  },
  loadingContainer: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  loading: {
    color: '#F16122',
  },
  container: {
    paddingLeft: '5px',
    paddingRight: '0px',
    paddingTop: '20px',
    marginBottom: '10px',
    overflowY: 'hidden',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#91b0d1 !important',
      borderRadius: '21px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'black',
    },
  },
  divListItem: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 87%), 0px 1px 1px 0px rgb(0 0 0 / 87%), 0px 1px 3px 0px rgb(0 0 0 / 87%)',
    },
  },
  listItem: {
    display: 'block',
    textAlign: 'center',
  },
  list: {
    display: 'flex',
    flexDirection: 'row',

    justifyContent: 'normal',
  },
  tooltip: {
    backgroundColor: 'rgb(0 118 191)',
    color: 'white',
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CategoryListHorizontal = (props) => {
  const history = useHistory();
  const query = useQuery();
  const { handleCategoryClick, category } = props;
  const categoryBillers = useSelector((state) => state.category.categoryBillers);
  const classes = myStyles();
  const [categoryListArr, setCategoryListArr] = useState([]);
  const categoryList = useSelector((state) => state.category.categoryList);
  //const category = query.get('type');
  const listType = query.get('listType');
  const categoryId = query.get('categoryId');

  useEffect(() => {
    if (listType && categoryList) {
      setCategoryListArr(categoryList.sort((a, b) => (a.category_order > b.category_order ? 1 : -1)));
    }
  }, [listType, categoryList]);

  useEffect(() => {
    if (category && categoryList && categoryBillers && listType === 'category') {
      if (category === categoryBillers.category && parseInt(categoryId) === categoryBillers.category_id) {
        categoryList.sort((a, b) => (a.category_order > b.category_order ? 1 : -1));
        const index = categoryList.findIndex((obj) => obj.category === category);
        setCategoryListArr([...moveArrayItemToNewIndex(categoryList, index, 0)]);
      } else {
        getBillerCount() > 20 ? history.push('/v1/biller-category') : history.push('/v1/merchant-biller?listType=all');
      }
    }
  }, [category, categoryBillers]);

  return (
    <>
      {categoryListArr.length > 0 ? (
        <Container maxWidth='xl' className={classes.container}>
          <List className={classes.list}>
            {categoryListArr.map((row, index) => (
              <div key={index} className={classes.divListItem} onClick={() => handleCategoryClick(row)}>
                <Tooltip
                  title={row.category}
                  placement='top'
                  classes={{
                    tooltip: classes.tooltip,
                  }}>
                  <ListItem button className={classes.listItem}>
                    {category === row.category ? (
                      <img className={classes.categoryLogo} src={getCategoryLogo(row.category, true)} alt={row.category}></img>
                    ) : (
                      <img className={classes.categoryLogo} src={getCategoryLogo(row.category, false)} alt={row.category}></img>
                    )}
                  </ListItem>
                </Tooltip>
                <Tooltip
                  title={row.category}
                  placement='bottom'
                  classes={{
                    tooltip: classes.tooltip,
                  }}>
                  <ListItem button className={classes.listItem}>
                    {category === row.category ? (
                      <Typography variant='subtitle2' className={classes.boldActive}>
                        {row.category}
                      </Typography>
                    ) : (
                      <Typography variant='subtitle2' className={classes.bold}>
                        {row.category}
                      </Typography>
                    )}
                  </ListItem>
                </Tooltip>
              </div>
            ))}
          </List>
        </Container>
      ) : (
        <Container className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />{' '}
        </Container>
      )}
    </>
  );
};

export default CategoryListHorizontal;
