import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import BaseContainer from '../../containers/BaseContainer/BaseContainer';
import BaseBackTop from '../../containers/BaseBackTop/BaseBackTop';
import contactUsBg from '../../assets/background/contactUs-bg.png';
import contactUsBgMobile from '../../assets/background/contactUs-bg-mobile.png';
import { getBillerCount } from '../../utils/Auth';

const myStyles = makeStyles((theme) => ({
  contactLogo: {
    top: '0px',
    left: '0px',
    paddingTop: 15,
    paddingBottom: 10,
  },
  bold: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontWeight: 600,
      fontSize: '0.9rem',
    },
  },
  container: {
    paddingLeft: '30px',
    paddingTop: '40px',
  },
  mail: {
    color: '#188dad',
    '&:hover': {
      color: 'black',
    },
  },
  contentTitle: {
    fontWeight: 600,
    color: '#188dad',
  },
  divContainer: {
    backgroundImage: `url(${contactUsBg})`,
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${contactUsBgMobile})`,
      backgroundPosition: 'center',
    },
    height: '100vh',
    minHeight: '100%',
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    overflow: 'auto',
  },
}));

const TermsAndConditions = () => {
  const classes = myStyles();
  return (
    <>
      <BaseContainer disableBack={false} contentWidth={'lg'}>
        <Container maxWidth='lg'>
          <BaseBackTop backRedirect={getBillerCount() > 20 ? '/biller-category' : '/merchant-biller?listType=all'} headerTitle={''} />
        </Container>
        <Container maxWidth='lg' className={classes.container}>
          <Grid container direction='row' justifyContent='center' alignItems='flex-start' spacing={2}>
            <Grid item>
              <Typography variant='h4' align='center' className={classes.contentTitle}>
                Terms and Conditions
              </Typography>
              <br />
            </Grid>
            <Grid item>
              <Typography variant='subtitle2'>
                Welcome to <b>Bayad Express</b>, a solution which enables merchants or billers to accept bills payment in their websites or mobile
                apps to their existing customers.
                <br />
                <br />
                Bayad Express is a solution that offers payment of other billers within a merchant's mobile application or website.
                <br />
                <br />
                These TERMS AND CONDITIONS (T&Cs) is a contract between you and Bayad and applies to your use and access of Bayad and the services
                associated with it (“Bayad Service” or “Bayad Services”).
                <br />
                <br />
                By transacting via Bayad Express, you agree to comply with and be legally bound by these T&Cs, as may revised from time to time. If
                you do not understand or do not wish to be bound by these T&Cs, or any subsequent modification thereof, you should not access or use
                any portion of the Bayad Services.
                <br />
                <br />
                You also agree to comply with the Bayad Data Privacy Policy (Privacy Policy) found at
                <a className={classes.mail} href='https://www.bayad.com/home/data-privacy-policy/'>
                  {' '}
                  www.bayad.com/home/data-privacy-policy
                </a>
                . The Privacy Policy describes how your Personal Information (as defined under the applicable laws and regulations) are collected,
                processed and used when you avail of Bayad Services.
                <br />
                <br />
                CBCI may amend or modify these T&Cs from time to time. Any amendment or modification shall be posted herein or by emailing to you the
                revised T&Cs. The revised version will be effective at the time of posting, unless otherwise stated. By continuing to avail of Bayad
                Services after any change to these T&Cs, you agree to abide by and be bound by those changes.
                <br />
                <br />
                We may (a) modify or discontinue any portion of the Bayad Services; or (b) suspend or terminate your use of and access hereto, at any
                time, and from time to time, without notice to you in certain and limited circumstances described herein.
                <br />
                <br />
                You agree that Bayad shall not be liable to you or any third party for any modification or termination of Bayad Services, or
                suspension or termination of your access hereto except to the extent otherwise expressly set forth herein.
                <br />
                <br />
                Under these T&Cs, the terms “Bayad”, “we”, “us”, and “our” refer to CIS Bayad Center, Inc., together with its employees, directors,
                affiliates, successor and assigns. Bayad is duly registered with and licensed by the Bangko Sentral ng Pilipinas (BSP) for as an
                Operator of a Payment System and Remittance and Transfer Company
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>1. DEFINITIONS</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                Definitions appearing in these T&Cs shall have the meanings ascribed to them below.
                <br />
                <br />
                “Bayad ” shall refer to the mobile application solution developed by the CIS Bayad Center, Inc. where you can avail of the Bayad
                Services.
                <br />
                <br />
                “Bayad Services” shall refer to functionalities of Bayad that include, but are not limited to, creation of Bayad Wallet, add money to
                Bayad Wallet, withdraw funds, pay bills, transfer funds, receive funds and other value-added features using Bayad Wallet.
                <br />
                <br />
                “Bayad Wallet” shall refer to a form of electronic money account that stores Philippine Peso (Php) value, which resides in the Bayad
                system. It is an account that is linked to your Mobile Identification Number (MIN), and which can be created and used in accordance of
                the terms herein.
                <br />
                <br />
                “Biller” shall refer to an entity with an arrangement with Bayad for the collection of the bills payment of its customers for its
                services through Bayad or Bayad Express.
                <br />
                <br />
                “Bills Payment”, as used herein, shall refer to a payment transaction to a Biller by you through Bayad Platforms - Bayad Centers,
                Bayad App, Bayad Online and Bayad Express.
                <br />
                <br />
                “Bayad Accredited Merchant” shall refer to a merchant entity that accepts payments for its products or service offerings using Bayad
                or Bayad Express.
                <br />
                <br />
                “Bayad Centers” shall refer to physical outlets and branches of Bayad or those of its authorized franchisees and partners that accept
                Cash-in and Cash-out Bayad Transactions, over-the-counter Bills Payment, e-loading and gaming pins, ticketing and booking, and
                microinsurance.
                <br />
                <br />
                “Short Message Service” or “SMS” shall mean a communications protocol allowing the interchange of short text messages between mobile
                telephone devices.
                <br />
                <br />
                “Bayad Channels” refers to the different payment platforms of Bayad that may be accessed by its customers/you for the performance of
                self-service bills payment using Bayad Express, Bayad and Bayad Center Outlets.
                <br />
                <br />
                “Payment Method” refers to the payment option chosen by you, be it payment in cash, payment by credit or debit cards, or payment by
                Bayad Wallet or other alternative fund sources.
                <br />
                <br />
                “Credit/Debit Card Mode of Payment” or “Visa/Mastercard Mode of Payment” refers to Bayad and Bayad Express’s ability to accept credit
                and debit card payments through a third-party Payment Gateway Provider.
                <br />
                <br />
                “Payment Gateway Provider” refers to an entity that authorizes Credit/Debit Card Mode of Payment or direct payments processing for the
                Bayad App and Bayad Express.
                <br />
                <br />
                “Bills Payment Transaction” refers to any and all transactions wherein you avail of Bayad Services and Bayad Express Services.
                <br />
                <br />
                “Transaction Amount” refers to the amount of money involved in a Transaction but excludes Biller Service Fee and Convenience Fee.
                <br />
                <br />
                “Biller Service Fee” refers to a service fee imposed by Bayad per Transaction, which fee is charged to you on top of the Transaction
                Amount.
                <br />
                <br />
                “Convenience Fee” refers to a fixed fee imposed on top of the Transaction Amount, which serves as the fee for using the payment
                gateway.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>2. ELIGIBILITY</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                2.1 To be eligible to use Bayad Service, you must be at least eighteen (18) years old.
                <br />
                <br />
                2.2 If you are an agent or representative of a business entity, you agree that you are duly authorized by such business entity to
                access and use Bayad and/or Bayad Express and can legally bind your principal to these T&Cs and all Transactions conducted under your
                username and password. Your account that is owned by a business entity may be subject to fees that differ from the fees applicable to
                personal accounts.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>3. IDENTITY AUTHENTICATION</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                3.1 When you accept these T&Cs, you are setting up your Bayad account and/or Bayad Express account that is associated with your mobile
                number and e-mail address. Depending on the specific Bayad Service that you are availing, you will be asked to provide information and
                submit copies of documents such as, but not limited to, your complete name, present and/or permanent address, date and place of birth,
                nationality, source of funds, photo of yourself, valid government identification card with picture and signature in accordance with
                the KYC requirements of the BSP.
                <br />
                <br />
                3.2 You are responsible for providing accurate account creation/registration information and for keeping your registration information
                up to date by editing your information on the account settings menu, or notifying CIS Bayad Center, Inc. by sending an email to
                <a className={classes.mail} href='mailto:support@bayad.com'>
                  {' '}
                  support@bayad.com
                </a>{' '}
                in the event of any changes thereto. In addition, as certain Bayad Services and Bayad Express services may become available or be
                offered only on a selective or promotional basis, supplementary information may be required from your end in order to enable the
                relevant Bayad Services and Bayad Express Services.
                <br />
                <br />
                3.3 We may verify your registration information through third-party verification as we may deem necessary. In some cases, we may ask
                you to provide additional details or information that can aid in verifying your identity.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>4. BAYAD WALLET</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                The Bayad Wallet is a reloadable electronic money instrument. It is not a deposit account and is not covered by the Philippine Deposit
                Insurance Corporation (PDIC). It does not earn interest. However, it is subject to the rules and regulations of the BSP, the
                Anti-Money Laundering Act (AMLA), as amended, and the Data Privacy Act of 2012 and its Implementing Rules and Regulations. The value
                loaded in your Bayad Wallet may be used to pay for Bayad Services via (a) quick-response or “QR” code scan payment method through
                Bayad; or (b) in-app payment thru the Bayad Wallet.
                <br />
                <br />
                The Bayad Wallet may be created only through Bayad.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>5. ADD MONEY AND CASH-OUT THROUGH BAYAD</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                5.1 You may cash-in Philippine Peso value into your Bayad Wallet through Bayad either through: (a) transfer of a Philippine Peso
                amount from your qualified bank account to your Bayad Wallet; (b) transfer of a Philippine Peso amount from an eligible debit or
                credit card linked to your Bayad account; (c) Over-the-Counter cash-in through any of the Bayad Center Outlet/s; (d) Bayad automated
                payment machines, which are self-service kiosks deployed in various locations; or (e) Peer-to-Peer or “P2P” transfer, which allows you
                to transfer Philippine Peso amount from your Bayad Wallet to another Bayad Wallet; or (f) Bayad Corporate Associates.
                <br />
                <br />
                5.2 You may cash-out Philippine Peso value from your Bayad Wallet through Bayad either through: (a) Over-the-Counter cash-out through
                any of the Bayad Center Outlet/s; or (b) Bayad Partner Network accredited channels such as, but not limited to, Bancnet, VISA,
                Mastercard, Pesonet and Instapay.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>6. ONLINE PURCHASES THROUGH BAYAD PLATFORMS</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                6.1 You can make online purchases from a Bayad Accredited Merchant through Bayad Express and Bayad Wallet. However, Bayad assumes no
                liability or and accountability for the quality of the product or service of its Bayad Accredited Merchants.
                <br />
                <br />
                6.2 Once the payment transaction has been completed through Bayad, transaction will be considered as authorized and you agree to bind
                yourself with the Bayad Accredited Merchant’s terms of sale such as, but not limited to, how you will receive the goods and/or
                services that you purchased. Bayad assumes no liability or accountability for any undelivered goods and services, defects, damages and
                after-sales services of such goods and/or services.
                <br />
                <br />
                6.3 You shall be fully accountable for payment transactions especially in dealing with a Bayad Accredited Merchant that has a limited
                refund policy.
                <br />
                <br />
                6.4 Bayad reserves the right not to process, or to cancel or reverse, any Transaction in its sole discretion, even after funds have
                been debited from your Bayad Wallet or your debit card , if Bayad suspects that the transaction involves (or has a high risk of
                involvement in) money laundering, terrorist financing, fraud, or any other type of financial crime; in response to a court order, or
                other government order;
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>7. BILLS PAYMENT</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                7.1 You can make bills payment with Billers using the Bayad Wallet when transacting through the Bayad. Other Payment Options such as
                payment through eligible credit & debit cards and other payment channels are available in Bayad and Bayad Express.
                <br />
                <br />
                7.2 Payment of bills does not require a printed receipt from the Biller. For every bill payment Transaction, you shall enter the
                assigned transaction number and Transaction Amount.
                <br />
                <br />
                7.3 Upon submission of bills payment request, you authorize the payment to the chosen Biller and therefore, cannot be disputed by you.
                The transaction reference number in the SMS and e-mail confirmation shall be your proof of payment to the Biller.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>8. TRANSACTION AND BAYAD WALLET LIMITS</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                Transaction limits and Bayad Wallet limits may be assigned or changed by Bayad at anytime at its option pursuant to applicable laws.
                Limits are applied on a per customer basis not on a per Bayad Wallet basis. If you have multiple Bayad Wallets under your name, limits
                shall be shared across all the wallets linked to your Bayad Account.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>9. ACTIVATION AND DE-ACTIVATION OF BAYAD SERVICE</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                Bayad may deactivate any existing feature of Bayad or of Bayad Express at any time at its option. On a case to case basis, Bayad may
                opt to suspend or terminate your privilege to use a particular feature, whenever it deems appropriate and necessary, particularly, but
                not limited to occasions when there are fraudulent transactions. In such cases, Bayad may charge a fee for the reactivation of the
                said feature, subject to other requirements Bayad may impose. The approval of any request for reactivation shall be the sole option of
                Bayad.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>10. LOYALTY/REWARDS PROGRAM</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                Bayad, at its sole option, shall have the option to run a loyalty/rewards program on the use of Bayad Services. Your rights and
                obligations in a loyalty/rewards program shall be covered by separate terms and conditions.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>11. FEES, RATES AND OTHER CHARGES</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                You agree to pay non-refundable Transaction Fees and other applicable fees and charges applicable to Transactions you transacted via
                Bayad, as may be imposed by Bayad. The Convenience Fee and Service Fee, as may be applicable, shall be inclusive of all applicable
                Philippine taxes and shall be debited to the customers fund source that the customer used.
                <br />
                <br />
                Charging of dormancy maintenance fees shall apply to Bayad Wallets that have not been used for any Transaction at least six (6) months
                from the date of the last Transaction. Such maintenance fees shall be automatically debited from your Bayad Wallet every 30th day of a
                calendar month. If your Bayad Wallet remains inactive for 6 months with zero balance, it shall be automatically closed.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>12. ACCEPTABLE USE POLICY</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                In connection with your use of Bayad Services, you must not:
                <br />
                <br />
                12.1 incorporate any word that is defamatory, obscene or profane, or which violates any trademark, service mark, or other intellectual
                property rights of any third party, including that of Bayad in your Bayad account name or your Bayad Wallet name;
                <br />
                <br />
                12.2 use any trademark, trade name, service mark, or logo in a way that is likely or intended to cause confusion about the owner or
                authorized user of such marks, names or logo;
                <br />
                <br />
                12.3 alter, modify, or cause the alteration or modification, of Bayad or Bayad Express, without prior written consent from Bayad;
                <br />
                <br />
                12.4 use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or methodology, or any
                similar or equivalent manual process, to access, acquire, copy or monitor any part of the Bayad App or Bayad Express, or in any way
                reproduce or circumvent its structure or presentation, as well as to obtain or attempt to obtain any material, document or information
                through any means not purposely made available through the Bayad Services;
                <br />
                <br />
                12.5 gain or attempt to gain unauthorized access to any part or feature of Bayad or Bayad Express or to any other system or network
                connected with the Bayad or Bayad Express;
                <br />
                <br />
                12.6 restrict, prevent or prohibit any other party from using the Bayad Services, including but not limited to such actions which may
                tend to discourage others from using the Bayad Services;
                <br />
                <br />
                12.7 circumvent, disable, or otherwise interfere with security-related features of Bayad or Bayad Express , including those that
                prevent or restrict use or copying of any content, material or information available on or through Bayad or Bayad Express , as well as
                those that enforce limitations on the use of Bayad or Bayad Express;
                <br />
                <br />
                12.8 probe, scan or test the vulnerability of Bayad or Bayad Express or any network connected to it, and not to breach the security or
                authentication measures on the same;
                <br />
                <br />
                12.9 reverse look-up, trace or seek to trace any information on any user of or visitor to Bayad or Bayad Express , or any other
                customer of Bayad including any account maintained with the Bayad Services not owned by you, to its source;
                <br />
                <br />
                12.10 copy or download any material or content from or through Bayad or Bayad Express, unless such copying or downloading is
                explicitly allowed by a visible manifestation thereof such as a “download” button or a similar link ostensibly displayed;
                <br />
                <br />
                12.11 engage or attempt to engage in the use, copying, transmission, broadcast, display, distribution or sale of any of the contents,
                material or information available on or through the Bayad Services, including user comments and the like, other than as expressly
                permitted herein, or as explicitly indicated in Bayad or Bayad Express , including use thereof for commercial purposes;
                <br />
                <br />
                12.12 use any device or routine to interfere or attempt to interfere with the proper working of the Bayad or Bayad Express or any
                transaction being conducted using Bayad or Bayad Express, or with any other person’s use of Bayad or Bayad Express ;
                <br />
                <br />
                12.13 use Bayad or Bayad Express for any purpose that is illegal, unlawful or prohibited by these T&Cs, or to solicit the performance
                of any illegal activity or other activity which infringes on the rights of Bayad or others; and
                <br />
                <br />
                12.14 modify, rent, lease, loan, sell, distribute or create derivative works based on any content, material or information, either in
                whole or in part, available on or through Bayad or Bayad Express , unless you have been specifically permitted to do so by Bayad or by
                the owner of that content, material or information in a separate agreement. In connection with your user content, you agree to:
                <br />
                <br />
                12.15 to undertake the sole responsibility and hold Bayad free of liability to you or to any third party, for any content that you
                create or information that you upload, transmit, or display while using the Bayad App or Bayad Express;
                <br />
                <br />
                12.16 to undertake sole responsibility for the consequences of your actions and interactions using the Bayad App or Bayad Express, and
                hereby stipulate admission of liability to Bayad for whatever loss or damage that Bayad may suffer as a consequence of your use or
                misuse of the Bayad App or Bayad Express;
                <br />
                <br />
                12.17 not to share, create, upload, transmit or display using the Bayad App or Bayad Express any material, information, or content
                which is or may be covered by copyright, patent, trade secret, trademark, trade name, service mark or any property rights, including
                privacy and/or publicity rights, unless you have the necessary licenses, rights, consents, and permissions to use and to authorize
                Bayad to use any and/or all content that you create, upload, transmit, or display using the Bayad App or Bayad Express;
                <br />
                <br />
                12.18 not to use fictitious name and concealing true name for the purpose of concealing crime, evading the execution of a judgment or
                causing damage;
                <br />
                <br />
                12.19 not to share, create, upload, transmit or display any material, information, content which is knowingly false and/or defamatory,
                inaccurate, abusive, vulgar, hateful, harassing, obscene, indecent, pornographic, profane, threatening, racist, gruesome, offensive to
                public morals, invasive of another’s privacy, or otherwise violative of any rule, regulation, or law; contains any viruses, Trojan
                horses, worms, time bombs, bots, ransomware, any malware, or any computer program, code, or routine that may or is intended to damage,
                detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information; and/or that which
                promotes or abets piracy; and
                <br />
                <br />
                12.20 not to engage in pyramid, ponzi or similar schemes or other damaging schemes in the future; not to start or forward chain
                letters; and not to conduct or aid in the conduct of surveys, contests, petitions, and the like, using the Bayad Services. You
                represent and warrant that:
                <br />
                <br />
                12.21 you own or that you have the necessary licenses, rights, consents, and permissions to use and to authorize Bayad to, subject to
                applicable laws and regulations, use, reproduce, adapt, modify, translate, publish, publicly perform, publicly display, distribute, or
                transmit over public networks and in various media any and/or all content that you create, upload, transmit, or display using Bayad
                App or Bayad Express, as you hereby authorize and grant Bayad perpetual, irrevocable, worldwide, royalty-free and non-exclusive right,
                license, consent and permission to use, reproduce, adapt, modify, translate, publish, publicly perform, publicly display, distribute,
                or transmit over public networks and in various media any and/or all content which you may create, upload, transmit, or display using
                the Bayad App or Bayad Express in a manner Bayad may deem fit and necessary, including but not limited to making such content
                available to a third party in compliance with a requirement of law, rule or regulation, or making such content available to any third
                party, including Bayad affiliates and subsidiaries, for its use, reproduction, adaptation, modification, translation, publication,
                public performance, public display, distribution or transmission; and
                <br />
                <br />
                12.22 you agree to hold Bayad free from any liability, both under equity and the law, arising or that may arise out of its use of your
                content or the use of your content by a third party to which Bayad made available your content.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>13. THIRD PARTY CONTENT & SERVICES</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                13.1 The Bayad Services contains content provided by third parties. You acknowledge and agree that Bayad is not responsible or liable
                for: the availability or accuracy, appropriateness, completeness or non-infringement of such third-party content; or the content,
                products, or services available on or through third-party services and resources. Unless specified by us, the availability of such
                third-party services does not imply any endorsement by us of such third-party services or the content, products, or services available
                therefrom.
                <br />
                <br />
                13.2 You may not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in
                or accompanying the application and the services or in any third-party.
                <br />
                <br />
                13.3 Bayad makes no representation or warranties whatsoever about the products or services and content of its Billers, Bayad
                Accredited Merchants or any third-party.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>14. RESERVED RIGHTS</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                Bayad reserves its rights to:
                <br />
                <br />
                14.1 deny access to and / or discontinue the Bayad Services or any component thereof to anyone at any time, temporarily or
                permanently, without giving any reason and/or prior notice to you. You hereby irrevocably agree, affirm and warrant to hold Bayad free
                from any liability, both under equity and the law, arising or that may arise out of any such denial of access to or the discontinuance
                of the Bayad Services;
                <br />
                <br />
                14.2 collect, screen, review, flag, filter, modify, block, refuse or remove any and/or all information provided by any user,
                explicitly or implicitly to and through the Bayad Service. You hereby irrevocably agree, affirm and warrant to hold Bayad free from
                any liability, both under equity and the law, arising or that may arise out of any such collection, screening, review, flagging,
                filtering, modification, blocking, refusal or removal of any and/or all information provided by any user to and through the
                third-party.
                <br />
                <br />
                14.3 enhance, improve, develop and introduce new features and functionalities to the Bayad App or Bayad Express at any time and
                without prior notice. You hereby understand, agree, and affirm that any such enhancement, improvement, development, new feature and/or
                new functionality to the Bayad App or Bayad Express shall form part of the Bayad Services and thus shall likewise be covered by these
                T&Cs;
                <br />
                <br />
                14.4 verify, check, cross-refer, validate, and ascertain the veracity and truthfulness of all information supplied by you by
                acquiring, accessing, retrieving, or otherwise acquiring similar or additional information supplied by you to other third-party
                service providers, including, but not limited to telecommunications providers, etc.;
                <br />
                <br />
                14.5 verify any activity that is done through the Bayad Services, especially if it involves compliance with the Anti-Money Laundering
                Act or with other laws, rules, and regulations.
                <br />
                <br />
                14.6 send you or cause to send you service updates and/or messages, including SMS, notifications, email and/or any data message
                transmission, informing you of enhancements, improvements, developments, features, functionalities, products, promotions, offers,
                advertisement and/or any other information relative to the Bayad Express and Bayad Services; and
                <br />
                <br />
                14.7 set limitations to and charge fees and applicable taxes for the use of the Bayad Services, at any time and without prior notice.
                You further understand and agree that Bayad reserves the right, at its sole discretion and under no obligation, to change the
                applicable fees, taxes and charges levied for the use of the Bayad Services, at any time and with prior written notice to you.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>15. FRAUDULENT TRANSACTIONS</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                Bayad shall have the right to automatically suspend or block your Bayad Wallet in the event that Bayad has reason to believe that such
                wallet may be used for fraudulent or suspicious transactions or by an unauthorized person. Bayad may, but shall not have the
                obligation, to inform you prior to suspending or blocking your Bayad Wallet pursuant to this section. You acknowledge the authority of
                Bayad to suspend or block your Bayad Wallet and accordingly, you shall hold Bayad free and harmless against any and all consequences
                of such suspension or blocking, or any loss or damage which the you may suffer as a result thereof. Without need to give any reason or
                notice, and without prejudice to other provisions hereof, Bayad has the absolute discretion (a) to refuse to approve any Transaction
                even if there is sufficient available balance in the Bayad Wallet; (b) to suspend, terminate or cancel your right to use your Bayad
                Wallet; (c) to increase or decrease the balance limit thereof; and/or (d) to introduce, amend, vary, restrict, terminate or withdraw
                the benefits, services, facilities and privileges with respect to or in connection with your Bayad Wallet, whether specifically
                relating to you or generally to all Bayad customers or customer segments.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>16. DATA PRIVACY</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                16.1 Data Collection
                <br />
                <br />
                Bayad shall keep all its customer files in strictest confidence. By providing information for the purpose of availing a Bayad account
                and a Bayad Express account, you expressly consent to the processing of your supplied customer data, as may be applicable. Customer
                Data are either Personal Information or Non-Personal Information: a. Personal Information, which may include Sensitive Personal
                Information, is any information from which the identity of an individual can be reasonably and directly ascertained, or when put
                together with other information would directly and certainly identify an individual. It also includes, but is not limited to, data
                that would help us verify [i] your identity such as such as name, gender, date of birth, address, telephone/mobile number, email
                address, government identification number, proof of identification, source of income, employment, and other data points whenever
                applicable; [ii] the location of your device whether desktop, laptop, or mobile devices whenever you access Bayad or the Bayad
                website; and [iii] the navigation experience when accessing Bayad and the Bayad website to see activities done in the channels, the
                pages visited, and the sequence thereof. b. Non-Personal Information is any information that does not identify you individually, and
                includes transactional, statistical, and analytical data, and anonymous and aggregate reports. Bayad may also request your Personal
                Information from time to time. Should you be unable to supply Bayad with the required Personal Information, Bayad may be unable to
                provide you with requested products and services, updates on latest offerings, and you may be unable to participate in events,
                promotions or other activities. When you use Bayad or accesses the Bayad website and electronically communicate with us, depending on
                Bayad Customer settings, Bayad may use Bayad Customer cookies, web beacons, small data text files or similar technologies to identify
                your device and record your preferences, with your consent.
                <br />
                <br />
                16.2 Use of Customer Data
                <br />
                <br />
                Without limiting the generality of the foregoing, you consent and authorize Bayad to store, process, disclose, exchange, and release
                the said information to its associates, affiliates, subsidiaries, officers, employees, agents, lawyers and other consultants,
                pre-paid/debit/credit bureaus or any such persons as Bayad deems necessary, or as required by law, rule or regulation, including but
                not limited to [i] providing you with your chosen Bayad products and services, including customer support; [ii] enhancing your
                customer experience and improve, develop and determine tailored products to meet your preferences and needs; [iii] communicating
                relevant products and/or advisories to you; [iv] showing you relevant ads on and off our services and measure the effectiveness and
                reach of ads and service; [v] Abide by any safety, security, public service or legal requirements and processes; and [vi] processing
                information for statistical, analytical, and research purposes. Bayad uses Personal Information to the extent necessary to comply with
                the requirements of the law and legal process, such as a court order; to comply with a legal obligation; or to prevent imminent harm
                to public security, safety or order. Bayad uses Non-Personal Information for statistical, analytical, and research purposes to create
                anonymous and aggregate reports. When required by our Data Privacy policy and the law, and before Bayad uses or processes your
                Customer Data for any other purpose, Bayad will ask for your consent. You, as prescribed by regulation, has the right to access,
                correct, object, remove and transport your information. However, you requesting to remove and or transport your information shall be
                tantamount to cancellation/termination of your subscription with Bayad.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>17. DATA SHARING</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                You hereby consent to and authorize Bayad, its subsidiaries, affiliates, and/or its third party partners contracted by Bayad for the
                purpose of conducting its operations, including but not limited to fraud and risk management, sales and marketing activities,
                communications relating to their products and/or services, product and system development and innovation, customer experience
                management and improvement, and market research, to collect and process any and all information related thereto from whatever relevant
                source such as, but not limited to, Bayad and its subsidiaries and affiliates. You hereby consent to and authorize the release by
                Bayad, its subsidiaries and affiliates, and third party partners, of any and all information required by Bayad and its third party
                partners such as, but not limited to, the following [i] voice calling data records; [ii] SMS sending and receiving records; [iii] Data
                usage records; [iv] Promo subscription and redemption records; [v] Customer relationship management records; [vi] Pre-paid load
                balance, top up and usage records; and [vii] Credit information. For the avoidance of doubt, you hereby acknowledge and agree that
                Bayad, its affiliates, and its third party subcontractors, may share any and all information relating to Applicant to each other for
                any legitimate business purpose including, but not limited to, fraud and risk management, sales and marketing activities,
                communications relating to their products and/or services, product and system development and innovation, customer experience
                management and improvement, and market research. The foregoing consents and authorizations shall continue for the duration of, and
                shall survive the termination of, this Agreement, or any other transactions, dealings, arrangements and accounts which you may have
                with, or avail from, Bayad.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>18. CLOSING YOUR ACCOUNT</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                You may close your account and terminate your relationship with Bayad at any time without cost, but you will remain liable for all
                obligations related to your account even after your account is closed. When you close your account, Bayad will cancel any scheduled or
                incomplete Transaction. If you have funds in your Bayad Wallet, you must withdraw or transfer any funds held in your Bayad Wallet
                before closing your Bayad or Bayad Express account because closing such account will result in Bayad automatically closing your Bayad
                Wallet.
                <br />
                <br />
                You will not be allowed to close your Bayad or Bayad Express account in certain cases, such as but not limited to the following:
                <br />
                <br />
                i. For purposes or in attempt of evading investigation by any authorized government agency;
                <br />
                ii. If you have a pending Transaction or an open dispute or claim on a Transaction; and
                <br />
                iii. If your Bayad Wallet has a negative balance.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>19. TERMINATION / CANCELLATION OF THE Bayad SERVICES</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                You hereby agree that Bayad is entitled to terminate your account immediately in the event that you are found to be in breach of any
                of the terms stipulated in these T&Cs. For the avoidance of doubt, the termination of these T&Cs shall not require Bayad to
                compensate, reimburse or cover any cost incurred by you in the course of you acquiring services from a Merchant or Biller.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>20. LIMITATION ON LIABILITY</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                Bayad makes no warranty, express or implied, regarding any Bayad Service. The Bayad Services are offered on an “AS IS”, “AS AVAILABLE”
                basis without warranties of any kind, other than warranties that are incapable of exclusion, waiver or limitation under the laws
                applicable hereto. Without limiting the generality of the foregoing, Bayad makes no warranty: (1) as to the content, quality or
                accuracy of data or information provided by Bayad hereunder or received or transmitted using the Bayad Services; (2) as to any Biller
                or Merchant service or product obtained using the Bayad Services; (3) that the Bayad Services will be uninterrupted or error-free; or
                (4) that any particular result or information will be obtained.
                <br />
                <br />
                Bayad shall not be liable for any loss, cost, compensation, damage or liability to you or third party arising from, directly or
                indirectly, or as a result of any or all of the following:
                <br />
                <br />
                a. refusal of any Biller, Bayad Accredited Merchant, bank, financial institution, merchant establishment, payment aggregator and the
                like to allow, accept or honor payments using Bayad;
                <br />
                b. failure to perform or complete any Transaction due to service/ system/ line unavailability;
                <br />
                c. any delay, interruption or termination of a Transaction whether caused by administrative error, technical, mechanical, electrical
                or electronic fault or difficulty or any other reason or circumstance beyond Bayad’s control (including but not limited to acts of
                God, strike, labor disputes, fire, disturbance, action of government, atmospheric conditions, lightning, interference or damage by
                third parties or any change in legislation);
                <br />
                d. unauthorized use of a Bayad Wallet;
                <br />
                e. misrepresentation or fraud by or misconduct by you, a Biller, a Bayad Accredited Merchant or of any third party; or
                <br />
                f. unauthorized acts or omissions of any Bayad employee or agent.
                <br />
                <br />
                In the event of any action that you may file against Bayad, you agree that Bayad’s liability shall not exceed One Thousand Pesos (PHP
                1,000.00) or the amount of the actual, direct and documented damages actually suffered by you, whichever is lower.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>21. VENUE OF LITIGATION</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                Venue of all suits shall either be at Pasig City or at any venue at the exclusive option of Bayad and subject to applicable laws and
                regulations.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>22. NON-WAIVER OF RIGHTS</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                You agree that no failure or delay by Bayad in exercising any right or remedy will operate as a waiver thereof, nor will any single or
                partial exercise of it preclude any other or further exercise of any right or remedy hereunder.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>23. SEPARABILITY CLAUSE</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                Should any part of these T&Cs be declared unconstitutional, illegal, void or unenforceable, the parts not affected shall remain valid
                and binding.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>24. CUSTOMER CONCERN HANDLING PROCESS</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                We are committed to maintaining the highest standards of financial consumer protection. Your requests, feedback and customer needs are
                our priority. If you have any concerns about a procedure or have encountered a problem with our service, you may contact our Bayad
                Customer Care at +632 8672-5777.
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>CUSTOMER CARE CONTACT INFORMATION</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                Business Name: CIS Bayad Center, Inc.
                <br />
                Business Address: G/F, Business Solutions Center Meralco Complex, Ortigas Avenue 0300 Pasig City, Philippines
                <br />
                E-mail:
                <a className={classes.mail} href='mailto:support@bayad.com'>
                  {' '}
                  support@bayad.com
                </a>
                <br />
                Hotline: +632 8672-5777
                <br />
                Mobile Numbers: +63 920-966 3000 / + 63 917-836 3000
                <br />
                Operating Hours: Monday to Friday – 6:00am – 12:00am
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>A. TRANSACTION HANDLING</b>
              </Typography>
              <br />
              <br />
              <Typography variant='subtitle1'>
                <b>B. REFUND REQUEST</b>
              </Typography>
              <br />
              <Typography variant='subtitle2'>
                1. The customer shall send an email to Customer Care (support@bayad.com) the following information:
                <br />
                <br />
                · Screenshot of Bayad Express Acknowledgement Receipt (It can be in-app or email)
                <br />
                · A proof or screenshot that the card has been charged
                <br />
                · Payment method used
                <br />
                · Request – Refund
                <br />
                · Channel used – Merchant’s App / Website redirected to Bayad Express
                <br />
                · Email used on Bayad Express
                <br />
                · Mobile Number used on Bayad Express
                <br />
                · Transaction Date and Time
                <br />
                · Biller Name
                <br />
                · Transaction Amount
                <br />
                · Account Number
                <br />
                <br />
                2. Customer Care Agent to acknowledge the attachments and information provided by the customer. Shall advise the customer if ever
                there are lacking details or lack of requirements.
                <br />
                <br />
                3. Customer Care Agent shall include the Ticket Number in the email response with the customer
                <br />
                <br />
                4. Customer Care Agent shall forward the concern to BOSD Team.
                <br />
                <br />
                5. BOSD team to check if the transaction is posted in Oracle and if part of the settlement report from our bank partner. If the
                transaction is settled to us, Collection and Settlement will trigger the refund.
                <br />
                <br />
                6. BOSD team will send a screenshot as a proof of the refunded transaction.
              </Typography>
              <br />
              <br />
            </Grid>
          </Grid>
        </Container>
      </BaseContainer>
    </>
  );
};

export default TermsAndConditions;
