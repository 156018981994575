import { Button, Container, CssBaseline, Grid, makeStyles, Tooltip } from '@material-ui/core';
import bayadExpressLogo from '../../assets/image-logo/bayad-express-logo.png';
import logoBackground from '../../assets/image-logo/logo-background.png';
import contactActive from '../../assets/image-logo/contact-active.png';
import contactInactive from '../../assets/image-logo/contact-inactive.png';
import { useHistory } from 'react-router-dom';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import { useEffect, useState } from 'react';
import Footer from '../Footer/Footer';
import ScrollToTop from './ScrollToTop';
import { getBillerCount } from '../../utils/Auth';
import PageScrollToTop from '../../utils/PageScrollToTop';

const myStyles = makeStyles((theme) => ({
  scrollToTop: {
    backgroundColor: '#F16122',
    '&:hover': {
      backgroundColor: '#F16122',
    },
  },
  mainContainer: {
    paddingBottom: 20,
    paddingTop: 40,
  },
  logoContainer: {
    backgroundImage: `url(${logoBackground})`,
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    top: 0,
    position: 'sticky',
    zIndex: 1,
    paddingTop: 15,
    paddingBottom: 10,
  },
  expressLogo: {
    top: '0px',
    left: '0px',
    cursor: 'pointer',
  },
  gridItem2: {
    textAlign: 'right',
  },
  contactLogo: {
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },
  contactUsLogo: {
    cursor: 'pointer',
  },
  buttonInactive: {
    borderRadius: '15px',
    textTransform: 'none',
    color: 'rgb(0 118 191)',
    fontSize: '0.7rem',
    backgroundColor: '#f3f6f9',
    verticalAlign: 'super',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#f3f6f9',
      //color: '#F16122',
    },
  },
  buttonActive: {
    borderRadius: '15px',
    textTransform: 'none',
    color: '#F16122',
    fontSize: '0.7rem',
    backgroundColor: '#f3f6f9',
    verticalAlign: 'super',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#f3f6f9',
      //color: '#F16122',
    },
  },
  bayadLogoTooltip: {
    backgroundColor: 'rgb(0 118 191)',
    color: 'white',
  },
}));

const BaseContainer = (props) => {
  const {
    disableBack = false,
    contactUsActive = false,
    faqActive = false,
    disableContactUs = false,
    disableFaq = false,
    showFooter = false,
    contentWidth = 'sm',
    showHeader = true,
    addBayadLogoClickHandler = false,
    handlerBayadLogoClick,
  } = props;
  const classes = myStyles();
  const history = useHistory();
  const [mobileView, setMobileView] = useState(false);

  const backToBillerList = () => {
    if (!disableBack) {
      getBillerCount() > 20 ? history.push('/v1/biller-category') : history.push('/v1/merchant-biller?listType=all');
      if (addBayadLogoClickHandler) handlerBayadLogoClick(true);
    }
  };

  // const handleResize = () => {
  //   if (window.innerWidth < 720) {
  //     setMobileView(false);
  //   } else {
  //     setMobileView(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  // });

  const contactUsPage = () => {
    if (!disableContactUs) history.push('/v1/contact-us');
  };

  const faqPage = () => {
    if (!disableFaq) history.push('/v1/frequently-ask-question');
  };

  return (
    <>
      <div id='divToTop'></div>
      <CssBaseline />
      {showHeader ? (
        <Container maxWidth='xl' className={classes.logoContainer}>
          <Grid container direction='row' alignItems='center'>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <img className={classes.expressLogo} src={bayadExpressLogo} alt={'bayadExpressLogo'} onClick={backToBillerList}></img>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem2}>
              {mobileView ? (
                'mobile'
              ) : (
                <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                  <Grid item>
                    <Button
                      data-testid='FrequentlyAskQuestion'
                      variant='contained'
                      className={faqActive ? classes.buttonActive : classes.buttonInactive}
                      onClick={faqPage}
                      size='small'>
                      <HelpOutlinedIcon />
                      &nbsp;&nbsp;FAQ
                    </Button>
                  </Grid>
                  <Grid item>
                    {contactUsActive ? (
                      <img
                        data-testid='contactUsActive'
                        className={classes.contactUsLogo}
                        src={contactActive}
                        alt='contactActive'
                        onClick={contactUsPage}></img>
                    ) : (
                      <img
                        data-testid='contactUsInactive'
                        className={classes.contactUsLogo}
                        src={contactInactive}
                        alt='contactInactive'
                        onClick={contactUsPage}></img>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      ) : (
        ''
      )}
      <Container maxWidth={contentWidth} disableGutters={true} className={classes.mainContainer}>
        <main className={classes.main}>{props.children}</main>
      </Container>
      {showFooter ? <Footer /> : ''}
      <ScrollToTop props={props} />
    </>
  );
};

export default BaseContainer;
