import React from 'react';
import "./dotloader.css"
const DotLoader = () => {
    return (
        <div>
            <div className="container">
                <div id="first" className="dot"></div>
                <div id="second" className="dot"></div>
                <div id="third" className="dot"></div>
                <div id="fourth" className="dot"></div>
                <div id="fifth" className="dot"></div>
            </div>
        </div>
    );
};

export default DotLoader;