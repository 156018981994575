import api from '../../utils/Api';
import { BILLERS, BILLER_FORM, BILLER } from '../../utils/ApiUrl';

export const getBillerList = async () => {
  try {
    let response = await api({
      method: 'POST',
      url: `${BILLERS}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBillerForm = async (billerCode) => {
  try {
    let response = await api({
      method: 'POST',
      url: `${BILLER_FORM}`,
      data: {
        billerCode,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBillerDetails = async (billerCode) => {
  try {
    let response = await api({
      method: 'POST',
      url: `${BILLER}`,
      data: {
        billerCode,
      },
    });
    return response.data[0];
  } catch (error) {
    throw error;
  }
};
