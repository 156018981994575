import { PAYMENT_GATEWAY, PAYMENT_ACKNOWLEDGEMENT, PAYMENT_INQUIRY } from '../../utils/ApiUrl';
import api from '../../utils/Api';

export const paymentAcknowledgement = async (transactionId) => {
  try {
    let response = await api({
      method: 'POST',
      url: `${PAYMENT_ACKNOWLEDGEMENT}`,
      data: {
        transactionId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCheckoutUrl = async (billerCode, billsPayId) => {
  try {
    let response = await api({
      method: 'POST',
      url: `${PAYMENT_GATEWAY}`,
      data: {
        billerCode,
        billsPayId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTransactionInquiry = async (transactionId, emailAddress) => {
  try {
    let response = await api({
      method: 'POST',
      url: `${PAYMENT_INQUIRY}`,
      data: {
        transactionId,
        emailAddress,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
