import api from '../../utils/Api';
import { SIGNATURE, VERIFY_URL } from '../../utils/ApiUrl';
import { storeSessionId } from '../../utils/Auth';

export const verifyUrl = async (id, name, timestamp, data, signature) => {
  try {
    let response = await api({
      method: 'POST',
      url: `${VERIFY_URL}`,
      data: {
        id,
        name,
        timestamp,
        data,
        signature,
      },
    });

    storeSessionId(response.data.data.sessionId);
    //storeMerchantId(response.data.data.merchantId);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSignature = async (id, name, data, renewSession = false) => {
  try {
    let response = await api({
      method: 'POST',
      url: `${SIGNATURE}`,
      data: {
        id,
        name,
        data,
      },
    });

    if (response.data && renewSession) {
      verifyUrl(id, name, response.data.data.timestamp, data, response.data.data.generatedCode);
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};
