import { SET_TRANSACTION_DATA, GET_CHECKOUT_URL, CLEAR_TRANSACTION, PAYMENT_ACKNOWLEDGEMENT, GET_TRANSACTION_INQUIRY } from './TransactionTypes';

export const setTransactionData = (data) => {
  return {
    type: SET_TRANSACTION_DATA,
    data,
  };
};

export const paymentAcknowledgement = (transactionId) => {
  return {
    type: PAYMENT_ACKNOWLEDGEMENT,
    transactionId,
  };
};

export const getCheckoutUrl = (billerCode, billsPayId) => {
  return {
    type: GET_CHECKOUT_URL,
    billerCode,
    billsPayId,
  };
};

export const clearTransaction = () => {
  return {
    type: CLEAR_TRANSACTION,
  };
};

export const getTransactionInquiry = (transactionId, emailAddress) => {
  return {
    type: GET_TRANSACTION_INQUIRY,
    transactionId,
    emailAddress,
  };
};
