import { Grid, Container, makeStyles, Typography, CssBaseline } from '@material-ui/core';
import constants from '../../utils/Constants';
import DotLoader from '../../containers/DotLoader/DotLoader';
import bayadExpressLogo from '../../assets/image-logo/bayad-logo-brand.png';

const myStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    minHeight: '100%',
    maxWidth: '100%',
    backgroundColor: '#dcf0fa',
  },
  grid: {
    minHeight: '100vh',
    margin: 'auto',
    paddingLeft: '30px',
    paddingRight: '30px',
    textAlign: 'center',
  },
  title: {
    letterSpacing: 2,
    fontWeight: 600,
    lineHeight: 1.5,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  content: {
    lineHeight: 1.5,
  },
  footerLogo: {
    verticalAlign: 'middle',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100px',
    },
    //width: '2%',
    height: '40px',
  },
  footer: {
    bottom: 100,
    position: 'fixed',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const Loader = (props) => {
  const { loader_title = constants.loader_title, loader_body = constants.loader_body } = props;
  const classes = myStyles();
  return (
    <>
      <CssBaseline />
      <Container maxWidth='xl' className={classes.container} disableGutters={true}>
        <Grid container direction='column' justifyContent='center' alignItems='center' component='main' className={classes.grid}>
          <Grid item>
            <Typography variant='h6' className={classes.title} paragraph={true}>
              {loader_title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1' className={classes.content} color='textSecondary' paragraph={true}>
              {loader_body}
            </Typography>
          </Grid>
          <Grid item>
            <DotLoader />
          </Grid>
          <Grid container direction='column'>
            <Container className={classes.footer}>
              <span>
                Powered By <img className={classes.footerLogo} src={bayadExpressLogo} alt={'bayadExpressLogo'}></img>
              </span>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Loader;
