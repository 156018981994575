import api from '../../utils/Api';
import { CATEGORY } from '../../utils/ApiUrl';

export const getCategoryList = async () => {
  try {
    let response = await api({
      method: 'POST',
      url: `${CATEGORY}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoryBillers = async (categoryId) => {
  try {
    let response = await api({
      method: 'POST',
      url: `${CATEGORY}`,
      data: {
        categoryId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
