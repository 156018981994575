const FAQData = [
  {
    title: `WHAT IS BAYAD EXPRESS?`,
    order: 1,
    content: [
      {
        title: `Bayad Express is a solution that offers payment of other billers within a merchant's mobile application or website.`,
        subContent: [],
      },
    ],
  },
  {
    title: `IS YOUR WEBSITE SAFE?`,
    order: 2,
    content: [
      { title: `Yes. Rest assured that our website has reliable cybersecurity measures.`, subContent: [] },
      { title: `You may check our Privacy Policy to see how your data is being collected, used and protected by our website.`, subContent: [] },
    ],
  },
  {
    title: `WHAT IS NEEDED TO PAY MY BILL?`,
    order: 3,
    content: [
      {
        title: `Account number will be required for some billers such as Meralco, Smart, and PLDT to proceed with your payment transaction. Other information may be asked as required by the system.`,
        subContent: [],
      },
    ],
  },
  {
    title: `HOW WOULD I KNOW IF THE INFORMATION I'M PROVIDING IS CORRECT?`,
    order: 4,
    content: [
      {
        title: `Bayad Express' system does validation of the entered details in the biller form.`,
        subContent: [],
      },
      {
        title: `Please note that the validation of the data entered would be based on the algorithm, value length, etc. based on the biller's guidelines provided to us.`,
        subContent: [],
      },
      {
        title: `*Note: We highly encourage you to double check the data you entered before confirming your payment.`,
        subContent: [],
      },
    ],
  },
  {
    title: `WHAT ARE THE FEES INVOLVED IN PROCESSING THE PAYMENT?`,
    order: 5,
    content: [
      {
        title: `There are two (2) fees placed on top of your bill amount. These are as follows:`,
        subContent: [
          `Biller Fee: Refers to the service fee charged by Bayad per transaction.`,
          `Convenience Fee: Refers to the issuing bank's fee to process the payment transaction.`,
        ],
      },
    ],
  },
  {
    title: `WHAT ARE THE PAYMENT OPTIONS AVAILABLE?`,
    order: 6,
    content: [
      { title: `You can use your Visa, Mastercard and JCB (Credit, Debit, or Prepaid) & PayMaya Wallets for payment processing.`, subContent: [] },
      { title: `Stay tuned to our official Social Media Pages for announcements on other payment methods for Bayad Express`, subContent: [] },
    ],
  },
  {
    title: `HOW WILL MY CARD INFORMATION BE PROCESSED?`,
    order: 7,
    content: [
      {
        title: `All card-related payment processing will be handled by our Payment Gateway Provider, who is compliant to the global security standards (PCI-DSS) and implements an authentication process (3DSecure) for every transaction.`,
        subContent: [],
      },
      {
        title: `You will be redirected to the secure payment page of PayMaya where customers should input their sensitive card information.`,
        subContent: [],
      },
      {
        title: `*Note: Bayad would NOT have access to these sensitive card information and would ONLY be accessible by our Payment Gateway Provider.`,
        subContent: [],
      },
    ],
  },
  {
    title: `FOR 3D SECURE, HOW IS THIS FACILITATED?`,
    order: 8,
    content: [
      {
        title: `This is an authentication process imposed by the Card Schemes (Visa, Mastercard and JCB) wherein you are redirected to the Card Issuer's Page for authentication.`,
        subContent: [],
      },
    ],
  },
  {
    title: `UPON COMPLETION OF THE PAYMENT, HOW WILL I KNOW IF THE PAYMENT TRANSACTION IS SUCCESSFUL?`,
    order: 9,
    content: [
      {
        title: `After providing your card information & upon completion of the authentication (if applicable), the payment gateway page would prompt a realtime notification on the status of the transaction.`,
        subContent: [],
      },
      {
        title: `You will also receive an Email & SMS confirming the success of your transactions.`,
        subContent: [],
      },
    ],
  },
  {
    title: `NOW THAT THE TRANSACTION IS SUCCESSFUL, DOES THIS MEAN THAT MY PAYMENT WOULD BE POSTED TO THE BILLER?`,
    order: 10,
    content: [
      {
        title: `You would see in the customer dashboard the status of the transaction paid for. Below are the status category for reference:`,
        subContent: [
          `Successful: Transaction is successful and this would be posted to the biller within 2 days.`,
          `Pending: Transaction is being processed with the biller. This should be updated to a Successful or Failed status within 15 minutes.`,
          `Failed: Transaction is failed and the transaction would be refunded back to the customer`,
        ],
      },
    ],
  },
  {
    title: `FOR FAILED TRANSACTIONS, HOW WILL THE TRANSACTION BE REFUNDED BACK TO ME?`,
    order: 11,
    content: [
      {
        title: `Bayad and its Payment Gateway Provider process the transaction in realtime. As a standard, the Card Issuers and Banks return or re-credit the amount to the customer's account within 7-14 Calendar Days. You may also get in touch with your bank to know more about their refund process.`,
        subContent: [],
      },
    ],
  },
  {
    title: `WHAT ARE THE REASONS FOR A FAILED PAYMENT?`,
    order: 12,
    content: [
      {
        title: `Failed payments could have multiple causes and this could be related to incorrect biller information provided, system related timeouts, incorrect card information, etc. Kindly ensure that all data you entered are correct before confirming your payment.`,
        subContent: [],
      },
    ],
  },
  {
    title: `HOW LONG ARE MY INFORMATION STORED WITHIN THE PORTAL?`,
    order: 13,
    content: [
      {
        title: `Based on our corporate guidelines, the customer data provided by an authorized merchant in the Bayad Express are stored in our system for 10 years.`,
        subContent: [],
      },
    ],
  },
  {
    title: `I OVERPAID MY BILL. WHAT SHOULD I DO?`,
    order: 14,
    content: [
      {
        title: `Overpayments are normally treated as an advance payment with the biller where the overpayment would be deducted from the next billing. You may directly coordinate with the biller for queries related to overpayment. We recommend that you also present the email "Bayad Express Email Acknowledgement Receipt" for the biller's reference on the ff: `,
        subContent: [`Timestamp`, `Biller Name`, `Transaction ID`, `Amount Paid`],
      },
    ],
  },
  {
    title: `I COMPLETED THE PAYMENT AND NOTICED I ENTERED AN INVALID OR INCORRECT ACCOUNT NUMBER. WHAT SHOULD I DO?`,
    order: 15,
    content: [
      {
        title: `Kindly coordinate directly with the biller immediately for them to make any adjustments.`,
        subContent: [],
      },
      {
        title: `We recommend that you also present the email "Bayad Express Email Acknowledgement Receipt" for the biller's reference on the ff: `,
        subContent: [`Timestamp`, `Biller Name`, `Transaction ID`, `Amount Paid`],
      },
      {
        title: `We highly encourage that you double check all entered biller information prior to proceeding with the payments.`,
        subContent: [],
      },
    ],
  },
  {
    title: `I USED MY CREDIT/DEBIT/PREPAID CARD DURING THE PAYMENTS, BUT THE TRANSACTION WAS DECLINED OR FAILED.`,
    order: 16,
    content: [
      {
        title: `Kindly check your issuing bank if the card is activated for online transactions or check your card setting for e-commerce. Please also ensure that the card details you enterd are correct.`,
        subContent: [],
      },
    ],
  },
  {
    title: `I DID NOT RECEIVE THE SMS OR EMAIL NOTIFICATIONS. WHAT SHOULD I DO?`,
    order: 17,
    content: [
      {
        title: `You may get in touch with our Customer Care (support@bayad.com or call us (02) 8672 5700) to assist you and have it checked in the platform. Thank you!`,
        subContent: [],
      },
    ],
  },
];

export default FAQData;
