export const PAYMENT_LIST = 'PAYMENT_LIST';

export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';

export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_FAILED = 'VERIFY_ACCOUNT_FAILED';

export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT';

export const BILLER_CONFIRMATION = 'BILLER_CONFIRMATION';
export const BILLER_CONFIRMATION_SUCCESS = 'BILLER_CONFIRMATION_SUCCESS';
export const BILLER_CONFIRMATION_FAILED = 'BILLER_CONFIRMATION_FAILED';
