import {
  GET_CATEGORY_BILLERS,
  GET_CATEGORY_BILLERS_FAILED,
  GET_CATEGORY_BILLERS_SUCCESS,
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_FAILED,
  GET_CATEGORY_LIST_SUCCESS,
} from './CategoryTypes';
import processActions from '../../utils/ProcessActions';
import { getCategoryList as getCategoryListService, getCategoryBillers as getCategoryBillersService } from './CategoryServices';
import { all, takeEvery } from '@redux-saga/core/effects';

function* getCategoryList() {
  yield processActions({
    params: null,
    service: getCategoryListService,
    success: GET_CATEGORY_LIST_SUCCESS,
    failed: GET_CATEGORY_LIST_FAILED,
  });
}

function* getCategoryBillers({ categoryId }) {
  const actions = Object.values({ categoryId }).map((item) => item);
  yield processActions({
    params: actions,
    service: getCategoryBillersService,
    success: GET_CATEGORY_BILLERS_SUCCESS,
    failed: GET_CATEGORY_BILLERS_FAILED,
  });
}

export default function* watchCategoryRequests() {
  yield all([takeEvery(GET_CATEGORY_LIST, getCategoryList), takeEvery(GET_CATEGORY_BILLERS, getCategoryBillers)]);
}
