import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import {
  ALPHABET,
  alphabetDashValidation,
  alphabetNumberPositionValidation,
  alphabetNumberSpaceValidation,
  alphabetNumberValidation,
  alphabetSpaceValidation,
  alphabetValidation,
  AMOUNT,
  numberValidation,
  NUMERIC,
  TEXT,
} from './Controls';

const myStyles = makeStyles((theme) => ({
  cssOutlinedInput: {
    //color: '#F16122',
  },
  notchedOutline: {
    borderColor: 'gray !important',
  },
  input: {
    '&::placeholder': {
      color: 'gray',
    },
  },
  inputLabel: {
    color: 'gray !important',
  },
  inputLabelError: {
    color: 'red !important',
  },
}));

const InputField = (props) => {
  const classes = myStyles();
  const { field, register, handlerFieldFormValid, fieldFormValid, btnLoading, selectedData, handlerFieldFormData, fieldFormData } = props;
  const [error, isError] = useState(false);
  const [value, setValue] = useState('');
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    let checkIfRequired = false;
    if (Object.keys(fieldFormValid).length > 0) {
      Object.keys(field.rules).forEach((prop) => {
        if (prop.includes('required_if:')) {
          checkIfRequired = true;
        } else if (prop === 'required') {
          checkIfRequired = true;
        }
      });
      if (!checkIfRequired) {
        fieldFormValid[field.name] = true;
        handlerFieldFormValid(fieldFormValid);
      }
    }
  }, [fieldFormValid]);

  useEffect(() => {
    if (Object.keys(selectedData).length > 0 && selectedData.comparedFields.includes(field.name)) {
      selectDataChange(selectedData);
    }
  }, [selectedData]);

  const selectDataChange = (selectedData) => {
    return Object.keys(field.rules).forEach((prop) => {
      if (prop.includes('required_if:')) {
        const fieldKey = prop.substring(prop.indexOf(':') + 1, prop.indexOf(','));
        const fieldValue = prop.substring(prop.indexOf(',') + 1);
        if (fieldKey === selectedData.name && fieldValue === selectedData.value) {
          if (!value || value === '') {
            isError(true);
            setHelperText(field.rules[prop]);
            fieldFormValid[field.name] = false;
          } else {
            fieldFormValid[field.name] = true;
          }
        } else {
          isError(false);
          setHelperText('');
          fieldFormValid[field.name] = true;
        }
        handlerFieldFormValid(fieldFormValid);
        if (fieldFormValid[field.name]) {
          fieldFormData[field.name] = value;
          handlerFieldFormData(fieldFormData);
        } else {
          fieldFormData[field.name] = '';
          handlerFieldFormData(fieldFormData);
        }
      }
    });
  };

  let checkFieldValid = [];
  const validate = (name, value) => {
    checkFieldValid = [];
    Object.keys(field.rules).forEach((prop) => {
      if (prop === 'required') {
        if (!value || value === '') {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else if (prop.includes('required_if:')) {
        const fieldKey = prop.substring(prop.indexOf(':') + 1, prop.indexOf(','));
        const fieldValue = prop.substring(prop.indexOf(',') + 1);
        if (fieldFormData[fieldKey] === fieldValue) {
          if (!value || value === '') {
            isError(true);
            setHelperText(field.rules[prop]);
            fieldFormValid[name] = false;
            checkFieldValid.push(false);
          } else {
            fieldFormValid[name] = true;
            checkFieldValid.push(true);
          }
        } else {
          isError(false);
          setHelperText('');
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else if (prop.includes('size:')) {
        const charLength = prop.substring(prop.indexOf(':') + 1);
        if (parseInt(value.length) > parseInt(charLength)) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else if (prop === 'sss_prefix') {
        let prefixes = field.rules[prop].substring(field.rules[prop].indexOf('['), field.rules[prop].indexOf(']') + 1);
        if (!prefixes.includes(value.substring(0, 1))) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else if (prop.includes('starts_with:')) {
        const startValue = prop.substring(prop.indexOf(':') + 1);
        const startValueLength = startValue.length;
        let valueStart = value.substring(0, startValueLength);
        if (valueStart !== startValue) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else if (prop.includes('ends_with:')) {
        const endValue = prop.substring(prop.indexOf(':') + 1);
        const endValueLength = endValue.length;
        if (value.length >= endValueLength) {
          let valueEnd = value.substring(value.length - endValueLength, value.length);
          if (valueEnd !== endValue) {
            isError(true);
            setHelperText(field.rules[prop]);
            fieldFormValid[name] = false;
            checkFieldValid.push(false);
          } else {
            fieldFormValid[name] = true;
            checkFieldValid.push(true);
          }
        }
      } else if (prop.includes('char_length:')) {
        const charLength = prop.substring(prop.indexOf(':') + 1);
        if (parseInt(value.length) !== parseInt(charLength) && parseInt(value.length) > 0) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else if (prop.includes('alphanum_position:')) {
        const position = prop.substring(prop.indexOf(':') + 1).split(',');
        const start = position.splice(0, 1).join('');
        const end = position.splice(0, 2).join('');

        if (value !== '' && !alphabetNumberValidation(value)) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          const position = alphabetNumberPositionValidation(start, end);
          if (value.length !== position.totalLength) {
            isError(true);
            setHelperText(field.rules[prop]);
            fieldFormValid[name] = false;
            checkFieldValid.push(false);
          } else {
            let valueStart = value.substring(position.startRange[0], position.startRange[1]);
            let valueEnd = value.substring(position.endRange[0], position.endRange[1]);
            if (position.startType === ALPHABET) {
              if (!alphabetValidation(valueStart)) {
                isError(true);
                setHelperText(field.rules[prop]);
                fieldFormValid[name] = false;
                checkFieldValid.push(false);
              } else {
                fieldFormValid[name] = true;
                checkFieldValid.push(true);
              }
            } else if (position.startType === NUMERIC) {
              if (!numberValidation(valueEnd)) {
                isError(true);
                setHelperText(field.rules[prop]);
                fieldFormValid[name] = false;
                checkFieldValid.push(false);
              } else {
                fieldFormValid[name] = true;
                checkFieldValid.push(true);
              }
            }

            if (position.endType === ALPHABET) {
              if (!alphabetValidation(valueEnd)) {
                isError(true);
                setHelperText(field.rules[prop]);
                fieldFormValid[name] = false;
                checkFieldValid.push(false);
              } else {
                fieldFormValid[name] = true;
                checkFieldValid.push(true);
              }
            } else if (position.endType === NUMERIC) {
              if (!numberValidation(valueEnd)) {
                isError(true);
                setHelperText(field.rules[prop]);
                fieldFormValid[name] = false;
                checkFieldValid.push(false);
              } else {
                fieldFormValid[name] = true;
                checkFieldValid.push(true);
              }
            }
          }
        }
      } else if (prop.includes('between:') && field.type === TEXT) {
        const between = prop.substring(prop.indexOf(':') + 1).split(',');
        const start = between.splice(0, 1).join('');
        const end = between.splice(0, 2).join('');
        if (parseInt(value.length) < parseInt(start)) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else if (parseInt(value.length) > parseInt(end)) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else if (prop === 'alpha') {
        if (value !== '' && !alphabetValidation(value)) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else if (prop === 'alpha_space') {
        if (value !== '' && !alphabetSpaceValidation(value)) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else if (prop === 'alpha_dash') {
        if (value !== '' && !alphabetDashValidation(value)) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else if (prop === 'alphanum_space') {
        if (value !== '' && !alphabetNumberSpaceValidation(value)) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else if (prop === 'alpha_num') {
        if (value !== '' && !alphabetNumberValidation(value)) {
          isError(true);
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
          checkFieldValid.push(false);
        } else {
          fieldFormValid[name] = true;
          checkFieldValid.push(true);
        }
      } else {
        isError(false);
        setHelperText('');
        fieldFormValid[name] = true;
        checkFieldValid.push(true);
      }
    });
    fieldFormValid[name] = checkFieldValid.includes(false) ? false : true;
    handlerFieldFormValid(fieldFormValid);
    if (fieldFormValid[name]) {
      fieldFormData[name] = value;
      handlerFieldFormData(fieldFormData);
    } else {
      fieldFormData[name] = '';
      handlerFieldFormData(fieldFormData);
    }
  };

  const handlerOnChange = (e) => {
    const { name, value } = e.target;
    setValue(value);
    isError(false);
    setHelperText('');
    if (Object.entries(field.rules).length !== 0) validate(name, value);
  };

  return (
    <>
      <TextField
        id={field.name}
        data-testid={field.name}
        autoComplete='off'
        label={field.label}
        {...register(field.name)}
        margin='dense'
        variant='outlined'
        fullWidth
        onChange={handlerOnChange}
        error={error && Boolean(error)}
        helperText={helperText}
        disabled={btnLoading}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            notchedOutline: !error ? classes.notchedOutline : '',
            input: classes.input,
          },
          startAdornment: field.name === AMOUNT ? <InputAdornment position='start'>Php</InputAdornment> : '',
        }}
        InputLabelProps={{
          className: error ? classes.inputLabelError : classes.inputLabel,
        }}
      />
    </>
  );
};

export default InputField;
