import { Grid, Container, makeStyles, Typography, TextField, InputAdornment } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BaseContainer from '../../containers/BaseContainer/BaseContainer';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import ViewListSharpIcon from '@material-ui/icons/ViewListSharp';
import ViewComfySharpIcon from '@material-ui/icons/ViewComfySharp';
import BillerListTable from '../../containers/BillerListTable/BillerListTable';
import BillerListTiled from '../../containers/BillerListTiled/BillerListTiled';
import { clearBillerForm, clearBillerList, getBiller, getBillerList } from '../../store/Biller/BillerAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ModalDialog from '../../containers/ModalDialog/ModalDialog';
import { isPrivacyNoticeDisplay, removeTransactionId } from '../../utils/Auth';
import { clearPayment, clearPaymentVerified } from '../../store/Payment/PaymentAction';
import clsx from 'clsx';

const myStyles = makeStyles((theme) => ({
  topContainter: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  merchantName: {
    fontWeight: 600,
    letterSpacing: '0.8px',
    color: '#373D3F',
    height: '1.4rem',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
  },
  bold: {
    fontWeight: 600,
    letterSpacing: '0.8px',
    color: '#373D3F',
  },
  iconSearch: {
    color: '#979797',
  },
  iconViewActive: {
    cursor: 'pointer',
    color: '#F16122',
    //marginRight: 8,
  },
  iconViewInActive: {
    cursor: 'pointer',
    color: '#979797',
    //marginRight: 8,
  },
  paddingTop: {
    paddingTop: 20,
  },
  viewIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  cssOutlinedInput: {
    color: '#F16122',
  },
  notchedOutline: {
    borderColor: 'gray !important',
  },
  input: {
    '&::placeholder': {
      color: 'gray',
    },
  },
  clear: {
    cursor: 'pointer',
  },
  merchantLogo: {
    verticalAlign: 'middle',
    width: '50px',
    height: '40px',
    margin: '5px',
  },
  billerListContainter: {
    paddingBottom: '60px',
  },
  divPaybillsWeb: {
    [theme.breakpoints.down('xl')]: {
      display: 'block',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  divPaybillsMobile: {
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  labelView: {
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingRight: 10,
  },
}));
const BillerList = () => {
  const classes = myStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const merchantBillers = useSelector((state) => state.biller.billerList);
  const errorMerchantBillers = useSelector((state) => state.biller.error);
  const [viewList, setViewList] = useState(true);
  const [viewTiled, setViewTiled] = useState(false);
  const [biller, setBiller] = useState({});
  const [searchText, setSearchText] = useState('');
  const [billerList, setBillerList] = useState([]);

  useEffect(() => {
    dispatch(getBillerList());
    setSearchText('');
  }, [dispatch]);

  useEffect(() => {
    if (merchantBillers && merchantBillers.length !== 0) {
      dispatch(clearPaymentVerified());
      dispatch(clearPayment());
      dispatch(clearBillerForm());
      removeTransactionId();
      setBillerList([...merchantBillers]);
    } else {
      setBillerList([]);
    }
  }, [merchantBillers]);

  useEffect(() => {
    setOpenModal(false);
    if (errorMerchantBillers.flag) {
      setOpenModal(true);
    }
  }, [errorMerchantBillers]);

  const handlerView = (type) => {
    setSearchText('');
    switch (type) {
      case 'list':
        setViewList(true);
        setViewTiled(false);
        break;
      case 'tiled':
        setViewList(false);
        setViewTiled(true);
        break;

      default:
        setViewList(true);
        setViewTiled(false);
        break;
    }
  };

  // const handleResize = () => {
  //   if (window.innerWidth < 720) {
  //     handlerView('tiled');
  //   } else {
  //     handlerView('list');
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  // });

  const handlerGetBiller = (billerData) => {
    if (isPrivacyNoticeDisplay() === 'false') {
      setBiller(billerData);
      dispatch(clearBillerForm());
      dispatch(getBiller(billerData));
      history.push('/v1/biller-form?billerCode=' + billerData.code);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  const handleModalClose = (modalClose) => {
    if (modalClose) {
      dispatch(clearBillerList());
    }
  };

  return (
    <>
      <BaseContainer disableBack={true} showFooter={true} contentWidth={'lg'}>
        <Container maxWidth='lg' className={classes.billerListContainter}>
          <div className={classes.divPaybillsWeb}>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant='h5' className={classes.bold}>
                  Pay Other Bills
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} className={classes.viewIcon}>
                <Typography variant='subtitle2' className={clsx(classes.bold, classes.labelView)}>
                  View:
                </Typography>
                <ViewListSharpIcon
                  onClick={() => handlerView('list')}
                  className={viewList ? classes.iconViewActive : classes.iconViewInActive}
                  fontSize='large'
                />
                <ViewComfySharpIcon
                  onClick={() => handlerView('tiled')}
                  className={viewTiled ? classes.iconViewActive : classes.iconViewInActive}
                  fontSize='large'
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <TextField
                  hiddenLabel
                  size='small'
                  fullWidth={true}
                  placeholder='Search Biller Here'
                  value={searchText}
                  onChange={handleSearchChange}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchSharpIcon className={classes.iconSearch} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='start' className={classes.clear} onClick={handleClearSearch}>
                        Clear
                      </InputAdornment>
                    ),
                  }}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.divPaybillsMobile}>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant='h5' className={classes.bold}>
                  Pay Other Bills
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} className={classes.viewIcon}>
                <Typography variant='subtitle2' className={clsx(classes.bold, classes.labelView)}>
                  View:
                </Typography>
                <ViewListSharpIcon
                  onClick={() => handlerView('list')}
                  className={viewList ? classes.iconViewActive : classes.iconViewInActive}
                  fontSize='large'
                />
                <ViewComfySharpIcon
                  onClick={() => handlerView('tiled')}
                  className={viewTiled ? classes.iconViewActive : classes.iconViewInActive}
                  fontSize='large'
                />
              </Grid>
            </Grid>
            <Grid container direction='column' justifyContent='center' alignItems='stretch' className={classes.paddingTop}>
              <Grid item>
                <TextField
                  hiddenLabel
                  size='small'
                  fullWidth={true}
                  placeholder='Search Biller Here'
                  value={searchText}
                  onChange={handleSearchChange}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchSharpIcon className={classes.iconSearch} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='start' className={classes.clear} onClick={handleClearSearch}>
                        Clear
                      </InputAdornment>
                    ),
                  }}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          </div>

          <Grid container className={classes.paddingTop}>
            {viewList ? (
              <Grid item xs={12} sm={12}>
                <BillerListTable handlerGetBiller={handlerGetBiller} selectedBiller={biller} searchBiller={searchText} billerList={billerList} />
              </Grid>
            ) : (
              <Grid item xs={12} sm={12}>
                <BillerListTiled handlerGetBiller={handlerGetBiller} selectedBiller={biller} searchBiller={searchText} billerList={billerList} />
              </Grid>
            )}
          </Grid>
          <ModalDialog modalOpen={openModal} addBtnCloseCallback={true} handleModalClose={handleModalClose} />
        </Container>
      </BaseContainer>
    </>
  );
};

export default BillerList;
