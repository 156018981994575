import { CLEAR_CATEGORY_BILLERS, GET_CATEGORY_BILLERS, GET_CATEGORY_LIST } from './CategoryTypes';

export const getCategoryList = () => {
  return {
    type: GET_CATEGORY_LIST,
  };
};

export const getCategoryBillers = (categoryId) => {
  return {
    type: GET_CATEGORY_BILLERS,
    categoryId,
  };
};

export const clearCategoryBillers = () => {
  return {
    type: CLEAR_CATEGORY_BILLERS,
  };
};
