import { makeStyles, Grid, Typography, Container } from '@material-ui/core';
import React from 'react';
import constants from '../../utils/Constants';
import bayadExpressLogo from '../../assets/image-logo/bayad-express-logo.png';

const myStyles = makeStyles((theme) => ({
  root: {
    minHeight: '80vh',
    textAlign: 'center',
  },
  bgColor: {
    backgroundColor: '#F4F4F4 ',
  },
  invalidLogo: {},
  bold: {
    fontWeight: 600,
  },
  heading: {
    opacity: 0.6,
    fontWeight: 600,
  },
  bayadExpressLogo: {},
}));

const NotFound = (props) => {
  const classes = myStyles();

  return (
    <>
      <Container disableGutters={true}>
        <Grid container spacing={3} direction='column' justifyContent='center' alignItems='center' className={classes.root}>
          <Grid item>
            {/* <img className={classes.invalidLogo} src={invalidLogo} alt={'invalidLogo'}></img> */}
            <Typography variant='h2' className={classes.heading}>
              {constants.not_found_header}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h5' className={classes.bold}>
              {constants.not_found_title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>{constants.not_found_body}</Typography>
          </Grid>
        </Grid>
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          <Grid item>
            <img className={classes.bayadExpressLogo} src={bayadExpressLogo} alt='bayadExpressLogo'></img>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default NotFound;
