import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../containers/Loader/Loader';
import { getTransactionId } from '../../utils/Auth';
import Constants from '../../utils/Constants';
import AcknowledgementCancelled from './AcknowledgementCancelled';
import AcknowledgementExpired from './AcknowledgementExpired';
import AcknowledgementFailed from './AcknowledgementFailed';
import AcknowledgementSuccess from './AcknowledgementSuccess';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Status = (props) => {
  const query = useQuery();
  const history = useHistory();
  const type = query.get('type');
  const transactionData = useSelector((state) => state.transaction.transactionData);
  const transactionIdStored = getTransactionId();

  useEffect(() => {
    if (!transactionIdStored) history.push('/v1/0?msg=Please check that URL is correct and and try again.');
  }, []);

  useEffect(() => {
    const validStatus = [Constants.status_success, Constants.status_failed, Constants.status_cancel, Constants.status_expired];
    if (transactionData) {
      if (type && validStatus.includes(type) && transactionData.status.includes(type.toUpperCase())) {
      } else {
        history.push('/v1/0?msg=Please check that URL is correct and and try again.');
      }
    }
  }, [transactionData]);

  return (
    <>
      {transactionData ? (
        type === Constants.status_success ? (
          <AcknowledgementSuccess transactionData={transactionData} paymentList={transactionData.biller_info} />
        ) : type === Constants.status_failed ? (
          <AcknowledgementFailed transactionData={transactionData} />
        ) : type === Constants.status_cancel ? (
          <AcknowledgementCancelled transactionData={transactionData} />
        ) : type === Constants.status_expired ? (
          <AcknowledgementExpired transactionData={transactionData} />
        ) : (
          ''
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Status;
