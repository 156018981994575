import React, { Card, CardContent, CardMedia, CircularProgress, Container, Divider, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Constants from '../../utils/Constants';
import dataNotFound from '../../assets/image-logo/no-data-found.png';
import imageDefault from '../../assets/image-logo/bayad-logo.png';

const myStyles = makeStyles((theme) => ({
  // billerLogo: {
  //   paddingTop: '35px',
  //   paddingLeft: '35px',
  //   paddingRight: '35px',
  //   paddingBottom: '15px !important',
  //   [theme.breakpoints.down('xs')]: {
  //     paddingTop: '15px',
  //     paddingLeft: '15px',
  //     paddingRight: '15px',
  //     paddingBottom: '10px',
  //   },
  // },
  cardContent: {
    borderTop: '1px solid rgb(0 0 0 / 23%)',
    textAlign: 'center',
    padding: '0px',
    '&:last-child': {
      paddingBottom: '5px !important',
      paddingTop: '5px !important',
    },
  },
  card: {
    border: '1px solid rgb(0 0 0 / 5%)',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 87%), 0px 1px 1px 0px rgb(0 0 0 / 87%), 0px 1px 3px 0px rgb(0 0 0 / 87%)',
    },
  },
  bold: {
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '9px',
    },
  },
  loading: {
    color: '#F16122',
  },
  loadingContainer: {
    textAlign: 'center',
  },
  notFoundContainer: {
    textAlign: 'center',
    marginTop: '25px',
    marginBottom: '25px',
  },
  tooltip: {
    backgroundColor: 'rgb(0 118 191)',
    color: 'white',
  },
  resultCount: {
    textAlign: 'end',
  },
  cardLogo: {
    height: 150,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 120,
    },
    [theme.breakpoints.down('xs')]: {
      height: 75,
    },
  },
  billerLogo: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

const BillerListTiled = (props) => {
  let { handlerGetBiller, searchBiller, billerList } = props;
  const classes = myStyles();
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    setTableList([...billerList.filter((row) => row.name.toUpperCase().includes(searchBiller.toUpperCase()))]);
  }, [searchBiller]);

  useEffect(() => {
    setTableList(billerList);
  }, [billerList]);

  return (
    <>
      {billerList.length !== 0 ? (
        // <div className={classes.resultCount}>
        //   <div>
        //     <p>
        //       <i>
        //         <b>{tableList.length}</b> biller/s found
        //       </i>
        //     </p>
        //   </div>
        <Grid container direction='row' spacing={3} data-testid='BillerListTiled'>
          {tableList.length !== 0 ? (
            //a.name.localeCompare(b.name) -> for string ordering
            tableList
              .sort((a, b) => (a.order > b.order ? 1 : -1))
              .map((row, index) => (
                <Grid item xs={3} sm={3} md={2} lg={2} key={index}>
                  <Tooltip
                    title={row.name}
                    placement='bottom'
                    classes={{
                      tooltip: classes.tooltip,
                    }}>
                    <Card onClick={() => handlerGetBiller(row)} className={classes.card}>
                      {/* <CardMedia className={classes.billerLogo} component='img' image={row.logo} alt={row.code} /> */}
                      <CardContent className={classes.cardLogo}>
                        <img className={classes.billerLogo} src={row.logo} onError={(e) => (e.target.src = imageDefault)} alt={row.code}></img>
                      </CardContent>
                      <CardContent className={classes.cardContent}>
                        <Typography variant='subtitle2' noWrap={true} className={classes.bold}>
                          {row.name}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Tooltip>
                </Grid>
              ))
          ) : searchBiller !== '' ? (
            <>
              <Container className={classes.notFoundContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <img className={classes.dataNotFound} src={dataNotFound} alt={'dataNotFound'}></img>
                  <Typography variant='subtitle1'>{Constants.search_not_found}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <br />
                  <Divider />
                </Grid>
              </Container>
            </>
          ) : (
            ''
          )}
        </Grid>
      ) : (
        // </div>
        <Container className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />{' '}
        </Container>
      )}
    </>
  );
};

export default BillerListTiled;
