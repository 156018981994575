export const GET_BILLER_LIST = 'GET_BILLER_LIST';
export const GET_BILLER_LIST_SUCCESS = 'GET_BILLER_LIST_SUCCESS';
export const GET_BILLER_LIST_FAILED = 'GET_BILLER_LIST_FAILED';

export const GET_BILLER = 'GET_BILLER';

export const GET_BILLER_FORM = 'GET_BILLER_FORM';
export const GET_BILLER_FORM_SUCCESS = 'GET_BILLER_FORM_SUCCESS';
export const GET_BILLER_FORM_FAILED = 'GET_BILLER_FORM_FAILED';

export const GET_BILLER_INFO = 'GET_BILLER_INFO';
export const GET_BILLER_INFO_SUCCESS = 'GET_BILLER_INFO_SUCCESS';
export const GET_BILLER_INFO_FAILED = 'GET_BILLER_INFO_FAILED';

export const CLEAR_BILLER_FORM = 'CLEAR_BILLER_FORM';

export const CLEAR_BILLER_LIST = 'CLEAR_BILLER_LIST';

export const SET_BILLER_FORM = 'SET_BILLER_FORM';

export const BILLER_FORM_DATA = 'BILLER_FORM_DATA';

export const CLEAR_BILLER_FORM_DATA = 'CLEAR_BILLER_FORM_DATA';
