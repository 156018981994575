import { Grid, makeStyles, Typography, TextField, InputAdornment } from '@material-ui/core';
import React, { useState } from 'react';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import ViewListSharpIcon from '@material-ui/icons/ViewListSharp';
import ViewComfySharpIcon from '@material-ui/icons/ViewComfySharp';
import clsx from 'clsx';

const myStyles = makeStyles((theme) => ({
  billerCategpryContainter: {
    paddingBottom: '60px',
  },
  bold: {
    fontWeight: 600,
    letterSpacing: '0.8px',
    color: '#373D3F',
  },
  iconSearch: {
    color: '#979797',
  },
  iconViewActive: {
    cursor: 'pointer',
    color: '#F16122',
    //marginRight: 8,
  },
  iconViewInActive: {
    cursor: 'pointer',
    color: '#979797',
    //marginRight: 8,
  },
  paddingTop: {
    paddingTop: 20,
  },
  viewIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  cssOutlinedInput: {
    color: '#F16122',
  },
  notchedOutline: {
    borderColor: 'gray !important',
  },
  input: {
    '&::placeholder': {
      color: 'gray',
    },
  },
  clear: {
    cursor: 'pointer',
  },
  merchantLogo: {
    verticalAlign: 'middle',
    width: '50px',
    height: '40px',
    margin: '5px',
  },
  billerListContainter: {
    paddingBottom: '60px',
  },
  divPaybillsWeb: {
    [theme.breakpoints.down('xl')]: {
      display: 'block',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  divPaybillsMobile: {
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  labelView: {
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingRight: 10,
  },
}));

const ListAndTiledView = (props) => {
  const {
    title = 'Pay Other Bills',
    viewType = 'list',
    searchValue = '',
    showClear = true,
    searchClick,
    disableSearchClick = true,
    enableSearchFocus = false,
    searchClear,
  } = props;
  const classes = myStyles();
  const [viewList, setViewList] = useState(true);
  const [viewTiled, setViewTiled] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handlerView = (type) => {
    setSearchText('');
    viewType(type);
    switch (type) {
      case 'list':
        setViewList(true);
        setViewTiled(false);
        break;
      case 'tiled':
        setViewList(false);
        setViewTiled(true);
        break;

      default:
        setViewList(true);
        setViewTiled(false);
        break;
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    searchValue(event.target.value);
  };

  const handleClearSearch = () => {
    if (showClear) {
      setSearchText('');
      searchClear(true);
    }
  };

  const handleSearchClick = () => {
    if (!disableSearchClick) searchClick(true);
  };

  const focusUsernameInputField = (input) => {
    if (input && enableSearchFocus) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };

  focusUsernameInputField();

  return (
    <>
      <div className={classes.divPaybillsWeb}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography variant='h5' className={classes.bold}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.viewIcon}>
            <Typography variant='subtitle2' className={clsx(classes.bold, classes.labelView)}>
              View:
            </Typography>
            <ViewListSharpIcon
              data-testid='ViewListSharpIcon'
              onClick={() => handlerView('list')}
              className={viewList ? classes.iconViewActive : classes.iconViewInActive}
              fontSize='large'
            />
            <ViewComfySharpIcon
              data-testid='ViewComfySharpIcon'
              onClick={() => handlerView('tiled')}
              className={viewTiled ? classes.iconViewActive : classes.iconViewInActive}
              fontSize='large'
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <TextField
              hiddenLabel
              size='small'
              id='searchText'
              data-testid={'ViewWebSearchText'}
              fullWidth={true}
              placeholder='Search Biller Here'
              value={searchText}
              onChange={handleSearchChange}
              onMouseDown={handleSearchClick}
              inputRef={focusUsernameInputField}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchSharpIcon className={classes.iconSearch} />
                  </InputAdornment>
                ),
                endAdornment: showClear ? (
                  <InputAdornment position='start' className={classes.clear} onClick={handleClearSearch}>
                    Clear
                  </InputAdornment>
                ) : (
                  ''
                ),
              }}
              variant='outlined'
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.divPaybillsMobile}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography variant='h5' className={classes.bold}>
              Pay Other Bills
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.viewIcon}>
            <Typography variant='subtitle2' className={clsx(classes.bold, classes.labelView)}>
              View:
            </Typography>
            <ViewListSharpIcon
              onClick={() => handlerView('list')}
              className={viewList ? classes.iconViewActive : classes.iconViewInActive}
              fontSize='large'
            />
            <ViewComfySharpIcon
              onClick={() => handlerView('tiled')}
              className={viewTiled ? classes.iconViewActive : classes.iconViewInActive}
              fontSize='large'
            />
          </Grid>
        </Grid>
        <Grid container direction='column' justifyContent='center' alignItems='stretch' className={classes.paddingTop}>
          <Grid item>
            <TextField
              hiddenLabel
              size='small'
              fullWidth={true}
              placeholder='Search Biller Here'
              value={searchText}
              data-testid={'ViewMobileSearchText'}
              onChange={handleSearchChange}
              onMouseDown={handleSearchClick}
              inputRef={focusUsernameInputField}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchSharpIcon className={classes.iconSearch} />
                  </InputAdornment>
                ),
                endAdornment: showClear ? (
                  <InputAdornment position='start' className={classes.clear} onClick={handleClearSearch}>
                    Clear
                  </InputAdornment>
                ) : (
                  ''
                ),
              }}
              variant='outlined'
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ListAndTiledView;
