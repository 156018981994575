import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { getCommaSeparatedTwoDecimalsNumber } from '../BillerFields/Controls/Controls';

const myStyles = makeStyles((theme) => ({
  container: {
    marginTop: '30px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  textLeft: {
    textAlign: 'left',
    font: 'normal normal normal 16px/30px Poppins',
    letterSpacing: '0.69px',
    color: ' #494949',
    opacity: 1,
  },
  textRight: {
    textAlign: 'right',
    font: 'normal normal normal 16px/30px Poppins',
    letterSpacing: '0.69px',
    color: ' #58595B',
    opacity: 1,
  },
}));

const PaymentList = (props) => {
  const classes = myStyles();
  const { paymentData } = props;

  return (
    <Grid container direction='row' alignItems='center' spacing={2}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography variant='subtitle1' className={classes.textLeft}>
          Account Name
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography variant='subtitle1' noWrap={true} className={classes.textRight}>
          {paymentData.name}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography variant='subtitle1' className={classes.textLeft}>
          Account No.
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography variant='subtitle1' noWrap={true} className={classes.textRight}>
          {paymentData.account_number}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography variant='subtitle1' className={classes.textLeft}>
          Amount
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography variant='subtitle1' noWrap={true} className={classes.textRight}>
          PHP {getCommaSeparatedTwoDecimalsNumber(paymentData.amount)}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography variant='subtitle1' className={classes.textLeft}>
          Biller Fee
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Typography variant='subtitle1' noWrap={true} className={classes.textRight}>
          PHP {getCommaSeparatedTwoDecimalsNumber(paymentData.service_fee)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default PaymentList;
