import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import BaseContainer from '../../containers/BaseContainer/BaseContainer';
import BaseBackTop from '../../containers/BaseBackTop/BaseBackTop';
import contactUsBg from '../../assets/background/contactUs-bg.png';
import contactUsBgMobile from '../../assets/background/contactUs-bg-mobile.png';
import { useHistory } from 'react-router-dom';
import { getBillerCount, isPrivacyNoticeDisplay } from '../../utils/Auth';
import { useEffect, useState } from 'react';

const myStyles = makeStyles((theme) => ({
  contactLogo: {
    top: '0px',
    left: '0px',
    paddingTop: 15,
    paddingBottom: 10,
  },
  bold: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontWeight: 600,
      fontSize: '0.9rem',
    },
  },
  container: {
    paddingLeft: '30px',
    paddingTop: '40px',
  },
  mail: {
    color: '#188dad',
    '&:hover': {
      color: 'black',
    },
  },
  contentTitle: {
    fontWeight: 600,
    color: '#188dad',
  },
  divContainer: {
    backgroundImage: `url(${contactUsBg})`,
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${contactUsBgMobile})`,
      backgroundPosition: 'center',
    },
    height: '100vh',
    minHeight: '100%',
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    overflow: 'auto',
  },
}));

const PrivacyStatement = () => {
  const classes = myStyles();
  const history = useHistory();
  const [isPrivacyNoticeDisplayed, setPrivacyNoticeDisplayed] = useState(true);
  const privacyNoticeDisplay = isPrivacyNoticeDisplay();

  useEffect(() => {
    if (privacyNoticeDisplay === 'true') {
      setPrivacyNoticeDisplayed(true);
    } else {
      setPrivacyNoticeDisplayed(false);
    }
  }, [privacyNoticeDisplay]);

  const handleBackBtn = (action) => {
    if (action) {
      if (!isPrivacyNoticeDisplayed) {
        if (getBillerCount() > 20) {
          history.push('/v1/biller-category');
        } else {
          history.push('/v1/merchant-biller?listType=all');
        }
      } else {
        history.goBack();
      }
    }
  };
  return (
    <>
      <BaseContainer disableBack={false} contentWidth={'lg'} showHeader={!isPrivacyNoticeDisplayed}>
        <Container maxWidth='lg'>
          <BaseBackTop disableBack={true} addBackBtnCallback={true} handleBackBtn={handleBackBtn} headerTitle={''} />
        </Container>
        <Container maxWidth='lg' className={classes.container}>
          <Grid container direction='row' justifyContent='center' alignItems='flex-start' spacing={2}>
            <Grid item>
              <Typography variant='h4' align='center' className={classes.contentTitle}>
                Privacy Statement
              </Typography>
              <br />
            </Grid>
            <Grid item>
              <Typography variant='subtitle2'>
                Bayad adheres to data privacy principles of transparency, legitimate purpose, and proportionality.
                <br />
                <br />
                <b>WE COLLECT ONLY DATA THAT MATTERS.</b>
                <br />
                Bayad collects personal and sensitive personal information ranging from name, age, and address, to government issued IDs from our
                biller and channel partners, suppliers, customers, employees, and guests/visitors thru physical and/or digital means to process
                transactions or fulfill obligations, and to improve our services.
                <br />
                <br />
                <b>WE PROTECT THE DATA THAT WE COLLECT.</b>
                <br />
                We have appointed data stewards and information asset custodians to ensure that your data is properly handled and kept secure, in
                accordance with our Data Handling Policy, data privacy laws, and industry approved standards. We will keep your personal data for 1
                year from date of transaction unless the law requires a longer period. At the end of the retention period, your personal data shall be
                deleted and made unrecoverable using approved secure disposal methods (e.g. data sanitization, secure shredding). This includes
                physical media (e.g. printed, removable media, back-up tapes, etc.). We keep a record of how, when, and by whom the information was
                destroyed, to provide an audit trail.
                <br />
                <br />
                <b>WE UPHOLD YOUR RIGHTS.</b>
                <br />
                <b></b>We are an advocate of your rights under the data privacy law. These are the rights to information, access, data portability,
                rectification, erasure or blocking, as well as to object, to file a complaint, and to indemnification.
                <br />
                <br />
                <b>WE ARE AT YOUR SERVICE.</b>
                <br />
                We update our privacy statement and practices regularly to reflect changes in applicable laws, government and regulatory requirements,
                technological, industry and business practices. We may notify you on these changes whenever applicable.
                <br />
                <br />
                For questions and more information regarding the exercise of your rights as a data subject, please contact:
                <br />
                <br />
                Data Protection Officer CIS Bayad Center, Inc.
                <br />
                Email:{' '}
                <a className={classes.mail} href='mailto:dpo@bayad.com'>
                  {' '}
                  dpo@bayad.com
                </a>{' '}
                <br />
                Contact Number: (02)8672-5710
                <br />
                Office Address: Ground Floor, Business Solutions Center, Meralco Complex, Ortigas Ave., Pasig City
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </BaseContainer>
    </>
  );
};

export default PrivacyStatement;
