export const SET_TRANSACTION_DATA = 'SET_TRANSACTION_DATA';

export const PAYMENT_ACKNOWLEDGEMENT = 'PAYMENT_ACKNOWLEDGEMENT';
export const PAYMENT_ACKNOWLEDGEMENT_SUCCESS = 'PAYMENT_ACKNOWLEDGEMENT_SUCCESS';
export const PAYMENT_ACKNOWLEDGEMENT_FAILED = 'PAYMENT_ACKNOWLEDGEMENT_SUCCESS';

export const GET_CHECKOUT_URL = 'GET_CHECKOUT_URL';
export const GET_CHECKOUT_URL_SUCCESS = 'GET_CHECKOUT_URL_SUCCESS';
export const GET_CHECKOUT_URL_FAILED = 'GET_CHECKOUT_URL_FAILED';

export const CLEAR_TRANSACTION = 'CLEAR_TRANSACTION';

export const GET_TRANSACTION_INQUIRY = 'GET_TRANSACTION_INQUIRY';
export const GET_TRANSACTION_INQUIRY_SUCCESS = 'GET_TRANSACTION_INQUIRY_SUCCESS';
export const GET_TRANSACTION_INQUIRY_FAILED = 'GET_TRANSACTION_INQUIRY_FAILED';
