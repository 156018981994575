import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { getRedirectCheckout, getTransactionId, storeRedirectCheckout } from '../utils/Auth';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CheckoutRedirect = (props) => {
  const query = useQuery();
  const history = useHistory();
  const transactionId = getTransactionId();
  const redirectCheckout = getRedirectCheckout();

  useEffect(() => {
    if (redirectCheckout === null) {
      if (query.get('redirectCheckout')) {
        storeRedirectCheckout(true);
      }
      window.location.assign(query.get('q'));
    } else if (redirectCheckout === 'true') {
      history.push('/v1/acknowledgement/cancel?transactionId=' + transactionId);
    }
  });

  return <div></div>;
};

export default CheckoutRedirect;
