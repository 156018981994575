import { GET_TOKEN, GET_TOKEN_FAILED, GET_TOKEN_SUCCESS } from './TokenTypes';
import { all, takeEvery } from '@redux-saga/core/effects';
import processActions from '../../utils/ProcessActions';
import { authentication as authenticationService } from './TokenServices';
import { terminateSession } from '../../utils/Auth';
import { select } from 'redux-saga/effects';
import * as selectors from '../selectors';
import history from '../../utils/history';

function* authenticationToken({ merchantId, sessionId }) {
  const actions = Object.values({ merchantId, sessionId }).map((item) => item);
  yield processActions({
    params: [...actions],
    service: authenticationService,
    success: GET_TOKEN_SUCCESS,
    failed: GET_TOKEN_FAILED,
  });

  let tokenError = yield select(selectors.tokenError);
  if (tokenError.flag) {
    tokenFailed();
  }
}

function tokenFailed() {
  terminateSession();
  history.push('/v1/0?msg=Please check that URL is correct and and try again.');
}

export default function* watchTokenRequests() {
  yield all([takeEvery(GET_TOKEN, authenticationToken)]);
}
