import { MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';
import theme from './assets/RootTheme';
import PublicRoute from './routes/PublicRoute';
import { Router, Switch, useHistory } from 'react-router-dom';
import history from './utils/history';
import PrivateRoute from './routes/PrivateRoute';
import Maintenance from './components/Maintenance/Maintenance';
import Categories from './components/Categories/Categories';
import BillerList from './components/BillerList/BillerList';
import BillerForm from './components/BillerForm/BillerForm';
import ExpressPay from './components/ExpressPay/ExpressPay';
import InvalidPage from './components/InvalidPage/InvalidPage';
import NotFound from './components/NotFound/NotFound';
import PaymentSummary from './components/PaymentSummary/PaymentSummary';
import Acknowledgement from './components/Acknowledgement/Acknowledgement';
import RedirectTo from './routes/RedirectTo';
import Status from './components/Acknowledgement/Status';
import ContactUs from './components/ContactUs/ContactUs';
import PrivacyStatement from './components/PrivacyStatement/PrivacyStatement';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import EmailInquiry from './components/EmailInquiry/EmailInquiry';
import TransactionDetails from './containers/TransactionDetails/TransactionDetails';
import CheckoutRedirect from './routes/CheckoutRedirect';
import FrequentlyAskQuestion from './components/FrequentlyAskQuestion/FrequentlyAskQuestion';
import PrivacyNoticeDisagree from './components/PrivacyNotice/PrivacyNoticeDisagree';
import MerchantBiller from './components/MerchantBiller/MerchantBiller';
import PageScrollToTop from './utils/PageScrollToTop';
import AcknowledgementProcessing from './components/Acknowledgement/AcknowledgementProcessing';
import { useIdleTimer } from 'react-idle-timer';
import { useEffect, useState } from 'react';
import SessionExpire from './containers/SessionExpire/SessionExpire';
import { getTransactionId } from './utils/Auth';
import { checkUrlContains } from './utils/ApiUrl';

function App() {
  // initial timeout used 900000 == 15 mins
  const timeout = 900000;
  const [isIdle, setIsIdle] = useState(false);
  const history = useHistory();
  const sessionTransactionId = getTransactionId();

  const handleOnActive = () => {
    setIsIdle(false);
  };
  const handleOnIdle = () => {
    setIsIdle(true);
    history.push('/v1/session-expire?msg=This page has expired due to inactivity.');
  };

  const { reset, pause, resume, getRemainingTime, getLastActiveTime, getElapsedTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  useEffect(() => {
    if (sessionTransactionId || checkUrlContains('session-expire') || checkUrlContains('acknowledgement')) {
      pause();
    }
  });

  return (
    <MuiThemeProvider theme={theme}>
      <div className='App'>
        <PageScrollToTop>
          <Switch>
            <PublicRoute path={'/v1'} exact component={ExpressPay} />
            <PublicRoute path={'/v1/maintenance'} component={Maintenance} />
            <PublicRoute path={'/v1/0'} component={InvalidPage} />
            <PublicRoute path={'/v1/1'} component={PrivacyNoticeDisagree} />
            <PrivateRoute path={'/v1/categories'} component={Categories} />
            <PublicRoute path={'/v1/privacy-statement'} component={PrivacyStatement} />
            <PrivateRoute path={'/v1/terms-condition'} component={TermsAndConditions} />
            <PrivateRoute path={'/v1/contact-us'} component={ContactUs} />
            <PrivateRoute path={'/v1/frequently-ask-question'} component={FrequentlyAskQuestion} />
            <PrivateRoute path={'/v1/biller-category'} component={Categories} />
            <PrivateRoute path={'/v1/merchant-biller'} component={MerchantBiller} />
            <PrivateRoute path={'/v1/biller-list'} component={BillerList} />
            <PrivateRoute path={'/v1/biller-form'} component={BillerForm} />
            <PrivateRoute path={'/v1/payment-summary'} component={PaymentSummary} />
            <PublicRoute path={'/v1/acknowledgement'} component={Acknowledgement} />
            <PublicRoute path={'/v1/acknowledgement-status'} component={Status} />
            <PublicRoute path={'/v1/email-inquiry'} component={EmailInquiry} />
            <PublicRoute path={'/v1/transaction-details'} component={TransactionDetails} />
            <PublicRoute path={'/v1/redirect'} component={RedirectTo} />
            <PublicRoute path={'/v1/acknowledgement-processing'} component={AcknowledgementProcessing} />
            <PublicRoute path={'/v1/checkout-redirect'} component={CheckoutRedirect} />
            <PublicRoute path={'/v1/session-expire'} component={SessionExpire} />
            <PublicRoute path={'/v1/404'} component={NotFound} />
            <PublicRoute component={InvalidPage} />
          </Switch>
        </PageScrollToTop>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
