import { all, takeEvery } from '@redux-saga/core/effects';
import processAction from '../../utils/ProcessActions';
import {
  GET_CHECKOUT_URL,
  GET_CHECKOUT_URL_FAILED,
  GET_CHECKOUT_URL_SUCCESS,
  GET_TRANSACTION_INQUIRY,
  GET_TRANSACTION_INQUIRY_FAILED,
  GET_TRANSACTION_INQUIRY_SUCCESS,
  PAYMENT_ACKNOWLEDGEMENT,
  PAYMENT_ACKNOWLEDGEMENT_FAILED,
  PAYMENT_ACKNOWLEDGEMENT_SUCCESS,
} from './TransactionTypes';
import {
  paymentAcknowledgement as paymentAcknowledgementService,
  getCheckoutUrl as getCheckoutUrlService,
  getTransactionInquiry as getTransactionInquiryService,
} from './TransactionServices';

function* paymentAcknowledgement({ transactionId }) {
  const actions = Object.values({ transactionId }).map((item) => item);
  yield processAction({
    params: actions,
    service: paymentAcknowledgementService,
    success: PAYMENT_ACKNOWLEDGEMENT_SUCCESS,
    failed: PAYMENT_ACKNOWLEDGEMENT_FAILED,
  });
}

function* getCheckoutUrl({ billerCode, billsPayId }) {
  const actions = Object.values({ billerCode, billsPayId }).map((item) => item);
  yield processAction({
    params: actions,
    service: getCheckoutUrlService,
    success: GET_CHECKOUT_URL_SUCCESS,
    failed: GET_CHECKOUT_URL_FAILED,
  });
}

function* getTransactionDetails({ transactionId, emailAddress }) {
  const actions = Object.values({ transactionId, emailAddress }).map((item) => item);
  yield processAction({
    params: actions,
    service: getTransactionInquiryService,
    success: GET_TRANSACTION_INQUIRY_SUCCESS,
    failed: GET_TRANSACTION_INQUIRY_FAILED,
  });
}

export default function* watchTransactionRequests() {
  yield all([
    takeEvery(PAYMENT_ACKNOWLEDGEMENT, paymentAcknowledgement),
    takeEvery(GET_CHECKOUT_URL, getCheckoutUrl),
    takeEvery(GET_TRANSACTION_INQUIRY, getTransactionDetails),
  ]);
}
