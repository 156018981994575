import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Constants from '../../utils/Constants';
import errorLogo from '../../assets/image-logo/invalid-logo.png';

const myStyles = makeStyles((theme) => ({
  errorLogo: {},
  dialogTitle: {
    textAlign: 'center',
    marginTop: 25,
  },
  titleText: {
    marginTop: 20,
    fontWeight: 600,
  },
  dialogContent: {
    textAlign: 'center',
  },
  dialogAction: {
    maxWidth: '50%',
    paddingTop: 25,
    paddingBottom: 25,
    margin: 'auto',
  },
  btnAction: {
    backgroundColor: '#E05A29 !important',
    color: 'white',
    letterSpacing: '0.5px',
  },
}));

const ModalDialog = (props) => {
  const classes = myStyles();
  const {
    modalOpen = true,
    clickCloseOutside = false,
    modalLogo,
    modalTitle,
    modalContent,
    modalContent2,
    modalButtonLabel,
    handleModalClose,
    addBtnCloseCallback = false,
    showBtnClose = true,
    maxWidth = 'sm',
  } = props;
  const [open, setOpen] = useState(modalOpen);
  const theme = createTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    if (clickCloseOutside) setOpen(false);
  };

  const handleBtnClose = () => {
    setOpen(false);
    if (addBtnCloseCallback) handleModalClose(true);
  };

  useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen]);
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        scroll={'body'}
        fullWidth={true}
        maxWidth={maxWidth}>
        <DialogTitle id='responsive-dialog-title' className={classes.dialogTitle}>
          <img className={classes.errorLogo} src={modalLogo ? modalLogo : errorLogo} alt={'errorLogo'}></img>
          <br />
          <Typography variant='h5' component={'span'} className={classes.titleText}>
            {modalTitle ? modalTitle : Constants.error_title}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            <Typography variant='subtitle1' component={'span'}>
              {modalContent ? modalContent : Constants.error_content}
            </Typography>
          </DialogContentText>
          <DialogContentText>
            <Typography variant='subtitle1' component={'span'}>
              {modalContent2 ? modalContent2 : Constants.error_content2}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          {showBtnClose ? (
            <Button variant='contained' fullWidth={true} onClick={handleBtnClose} className={classes.btnAction}>
              {modalButtonLabel ? modalButtonLabel : Constants.error_btn_label}
            </Button>
          ) : (
            ''
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalDialog;
