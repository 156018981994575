import processActions from '../../utils/ProcessActions';
import { all, takeEvery } from '@redux-saga/core/effects';
import { verifyAccount as verifyAccountServices, billerConfirmation as billerConfirmationServices } from './PaymentServices';
import {
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_FAILED,
  BILLER_CONFIRMATION,
  BILLER_CONFIRMATION_SUCCESS,
  BILLER_CONFIRMATION_FAILED,
} from './PaymentTypes';

function* verifyAccount({ billerCode, accountNumber, data }) {
  const actions = Object.values({ billerCode, accountNumber, data }).map((item) => item);
  yield processActions({
    params: actions,
    service: verifyAccountServices,
    success: VERIFY_ACCOUNT_SUCCESS,
    failed: VERIFY_ACCOUNT_FAILED,
  });
}

function* billerConfirmation({ billerCode, billsPayId }) {
  const actions = Object.values({ billerCode, billsPayId }).map((item) => item);
  yield processActions({
    params: actions,
    service: billerConfirmationServices,
    success: BILLER_CONFIRMATION_SUCCESS,
    failed: BILLER_CONFIRMATION_FAILED,
  });
}

export default function* watchPaymentRequests() {
  yield all([takeEvery(VERIFY_ACCOUNT, verifyAccount), takeEvery(BILLER_CONFIRMATION, billerConfirmation)]);
}
