import axios from 'axios';
import axiosRetry from 'axios-retry';
import { authentication as authenticationService } from '../store/Token/TokenServices';
import { getMerchantClientId, getMerchantData, getMerchantName, getSessionId, getTokenData, removeSession, removeToken } from './Auth';
import { getSignature as getSignatureService } from '../store/Session/SessionServices';
import { AUTHENTICATE, PAYMENT_INQUIRY, VERIFY_URL, SIGNATURE } from './ApiUrl';
import Cookies from 'js-cookie';

let instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use((config) => {
  const token = getTokenData();
  const sessionId = getSessionId(); // || Cookies.get('sessionId'); //for cookie storage
  const noAuthToken = [AUTHENTICATE, VERIFY_URL, PAYMENT_INQUIRY, SIGNATURE];
  if (!noAuthToken.includes(config.url)) {
    try {
      let body = {};
      if (config.data === undefined) {
        body = {
          sessionId: sessionId,
        };
      } else {
        if (typeof config.data === 'string' || config.data instanceof String) {
          body = JSON.parse(config.data);
        } else {
          body = config.data;
        }
        body['sessionId'] = `${sessionId}`;
      }
      //config.params = { ...config.params, sessionId: sessionId };
      config.data = JSON.stringify(body);
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    } catch (error) {}
  }
  return config;
});

instance.interceptors.response.use(null, (error) => {
  if (401 === error.response.status) {
    const merchantClientId = getMerchantClientId(); // || Cookies.get('merchantClientId'); //for cookie storage
    const sessionId = getSessionId(); // || Cookies.get('sessionId'); //for cookie storage
    authenticationService(merchantClientId, sessionId);
  }

  if (400 === error.response.status) {
    removeSession();
    removeToken();
    const id = getMerchantClientId();
    const name = getMerchantName();
    const data = getMerchantData();
    getSignatureService(id, name, data, true);
  }
  return Promise.reject(error);
});

axiosRetry(instance, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 3500;
  },
  retryCondition: (error) => {
    const codes = [200, 400, 404, 422, 500, 440, 502];
    return !codes.includes(error.response.status);
  },
});

export default instance;
