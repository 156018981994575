import { FormControl, FormHelperText, InputLabel, makeStyles, MenuItem, Select, OutlinedInput as MuiOutlinedInput } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';

const myStyles = makeStyles((theme) => ({
  inputLabel: {
    color: 'gray !important',
  },
  inputLabelError: {
    color: 'red !important',
  },
}));

const OutlinedInput = withStyles((theme) => ({
  notchedOutline: {
    borderColor: 'gray !important',
  },
}))(MuiOutlinedInput);

const SelectField = (props) => {
  const classes = myStyles();
  const {
    field,
    register,
    handlerFieldFormValid,
    fieldFormValid,
    btnLoading,
    selectedData,
    handlerSelectedData,
    handlerFieldFormData,
    fieldFormData,
  } = props;
  const [error, isError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [selected, setSelected] = useState('');

  useEffect(() => {
    let checkIfRequired = false;
    if (Object.keys(fieldFormValid).length > 0) {
      Object.keys(field.rules).forEach((prop) => {
        if (prop.includes('required_if:')) {
          checkIfRequired = true;
        } else if (prop === 'required') {
          checkIfRequired = true;
        }
      });
      if (!checkIfRequired) {
        fieldFormValid[field.name] = true;
        handlerFieldFormValid(fieldFormValid);
      }
    }
  }, [fieldFormValid]);

  useEffect(() => {
    if (Object.keys(selectedData).length > 0 && selectedData.comparedFields.includes(field.name)) {
      selectDataChange(selectedData);
    }
  }, [selectedData]);

  const selectDataChange = (selectedData) => {
    return Object.keys(field.rules).forEach((prop) => {
      if (prop.includes('required_if:')) {
        const fieldKey = prop.substring(prop.indexOf(':') + 1, prop.indexOf(','));
        const fieldValue = prop.substring(prop.indexOf(',') + 1);
        if (fieldKey === selectedData.name && fieldValue === selectedData.value) {
          if (!selected || selected === '') {
            isError(true);
            setHelperText(field.rules[prop]);
            fieldFormValid[field.name] = false;
          } else {
            fieldFormValid[field.name] = true;
          }
        } else {
          isError(false);
          setHelperText('');
          fieldFormValid[field.name] = true;
        }
        handlerFieldFormValid(fieldFormValid);
        if (fieldFormValid[field.name]) {
          fieldFormData[field.name] = selected;
          handlerFieldFormData(fieldFormData);
        } else {
          fieldFormData[field.name] = '';
          handlerFieldFormData(fieldFormData);
        }
      }
    });
  };

  const validate = (name, value) => {
    Object.keys(field.rules).map((prop) => {
      if (prop === 'required') {
        if (!value || value === '' || value === 'default-option') {
          isError(Boolean(true));
          setHelperText(field.rules[prop]);
          fieldFormValid[name] = false;
        } else {
          fieldFormValid[name] = true;
        }
      } else {
        isError(false);
        setHelperText('');
        fieldFormValid[name] = true;
      }
    });

    handlerFieldFormValid(fieldFormValid);
    if (fieldFormValid[name]) {
      handlerSelectedData({ name, value, comparedFields: field.comparedFields });
      fieldFormData[name] = value;
      handlerFieldFormData(fieldFormData);
    } else {
      handlerSelectedData({ name, value, comparedFields: field.comparedFields });
      fieldFormData[name] = '';
      handlerFieldFormData(fieldFormData);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelected(value);
    isError(false);
    setHelperText('');
    if (Object.entries(field.rules).length !== 0) validate(name, value);
  };

  return (
    <>
      <FormControl fullWidth error={error && Boolean(error)} margin='dense' variant='outlined'>
        <InputLabel id={field.name} className={error ? classes.inputLabelError : classes.inputLabel}>
          {field.label}
        </InputLabel>
        <Select
          className={classes.select}
          labelId={field.name}
          label={field.label}
          id={field.name}
          data-testid={field.name}
          required={true}
          {...register(field.name)}
          disabled={btnLoading}
          value={selected}
          onChange={handleChange}
          input={!error ? <OutlinedInput label={field.label} /> : null}>
          {/* <MenuItem key='default' value='default-option'>
            ---
          </MenuItem> */}
          {field.options
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((data) => (
              <MenuItem key={data.value} value={data.value}>
                {data.name}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </>
  );
};

export default SelectField;
