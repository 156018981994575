import {
  GET_BILLER_LIST,
  GET_BILLER_LIST_FAILED,
  GET_BILLER_LIST_SUCCESS,
  GET_BILLER_FORM,
  GET_BILLER_FORM_SUCCESS,
  GET_BILLER_FORM_FAILED,
  GET_BILLER_INFO,
  GET_BILLER_INFO_SUCCESS,
  GET_BILLER_INFO_FAILED,
} from './BillerTypes';
import processActions from '../../utils/ProcessActions';
import {
  getBillerList as getBillerListService,
  getBillerForm as getBillerFormService,
  getBillerDetails as getBillerDetailsService,
} from './BillerServices';
import { all, takeEvery } from '@redux-saga/core/effects';

function* getBillerList() {
  yield processActions({
    params: null,
    service: getBillerListService,
    success: GET_BILLER_LIST_SUCCESS,
    failed: GET_BILLER_LIST_FAILED,
  });
}

function* getBillerForm({ billerCode }) {
  const actions = Object.values({ billerCode }).map((item) => item);
  yield processActions({
    params: actions,
    service: getBillerFormService,
    success: GET_BILLER_FORM_SUCCESS,
    failed: GET_BILLER_FORM_FAILED,
  });
}

function* getBillerInfo({ billerCode }) {
  const actions = Object.values({ billerCode }).map((item) => item);
  yield processActions({
    params: actions,
    service: getBillerDetailsService,
    success: GET_BILLER_INFO_SUCCESS,
    failed: GET_BILLER_INFO_FAILED,
  });
}

export default function* watchBillerRequests() {
  yield all([takeEvery(GET_BILLER_LIST, getBillerList), takeEvery(GET_BILLER_FORM, getBillerForm), takeEvery(GET_BILLER_INFO, getBillerInfo)]);
}
