import { makeStyles, Grid, Typography, Container } from '@material-ui/core';
import React from 'react';
import bayadExpressLogo from '../../assets/image-logo/bayad-express-logo.png';
import contactUsBg from '../../assets/background/contactUs-bg.png';
import contactUsBgMobile from '../../assets/background/contactUs-bg-mobile.png';

const myStyles = makeStyles((theme) => ({
  root: {
    minHeight: '80vh',
    margin: 'auto',
    paddingLeft: '30px',
    paddingRight: '30px',
    textAlign: 'center',
  },
  mail: {
    color: 'blue',
  },
  bold: {
    fontWeight: 600,
  },
  bayadExpressLogo: {},
  divContainer: {
    backgroundImage: `url(${contactUsBg})`,
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${contactUsBgMobile})`,
      backgroundPosition: 'center',
    },
    height: '100vh',
    minHeight: '100%',
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
  },
}));

const PrivacyNoticeDisagree = (props) => {
  const classes = myStyles();

  return (
    <>
      <Container className={classes.divContainer} maxWidth={false}>
        <Grid container component='main' className={classes.root} direction='column' spacing={3} justifyContent='center' alignItems='center'>
          {/* <Grid item>
            <img className={classes.invalidLogo} src={invalidLogo} alt={'invalidLogo'}></img>
          </Grid> */}
          <Grid item>
            <Typography variant='h5' className={classes.bold}>
              We regret to inform you that you aren't allowed to proceed.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1'>
              You may reach to out to us at{' '}
              <a className={classes.mail} href='mailto:dpo@bayad.com'>
                {' '}
                dpo@bayad.com
              </a>{' '}
              for any clarification regarding our Data Privacy Policy. Thank you.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          <Grid item>
            <img className={classes.bayadExpressLogo} src={bayadExpressLogo} alt='bayadExpressLogo'></img>
          </Grid>
        </Grid>
        {/* <Grid container spacing={3} direction='column' justifyContent='center' alignItems='center' className={classes.root}>
          <Grid item>
            <img className={classes.invalidLogo} src={invalidLogo} alt={'invalidLogo'}></img>
          </Grid>
          <Grid item>
            <Typography variant='h5' className={classes.bold}>
              {constants.notice_disagree_title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>{constants.notice_disagree_body}</Typography>
          </Grid>
        </Grid>
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          <Grid item>
            <img className={classes.bayadExpressLogo} src={bayadExpressLogo} alt='bayadExpressLogo'></img>
          </Grid>
        </Grid> */}
      </Container>
    </>
  );
};

export default PrivacyNoticeDisagree;
