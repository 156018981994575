export default {
  /**
   * Maintenance Page
   */

  maintenance_mode_header: 'This site is currently under maintenance.',
  maintenance_mode_body: `We're preparing something to enhance your Bayad Express experience.`,
  maintenance_mode_body1: '',
  maintenance_mode_body2: 'Please check back soon!',

  /**
   *  Loader constants
   */
  loader_title: 'Please do not close or refresh this page.',
  loader_body: 'Please wait a moment while we redirect you to Bayad Express page.',

  /**
   *  Invalid page constants
   */
  invalid_title: `The address you provided is invalid.`,
  invalid_body: 'Please check and try again.',

  /**
   *  Privacy Notice Page for Disagree constants
   */
  notice_disagree_title: `We regret to inform you that you aren't allowed to proceed.`,
  notice_disagree_body: 'Please make sure to agree. Thank you.',

  /**
   *  404 page constants
   */
  not_found_header: `404`,
  not_found_title: `Page not found.`,
  not_found_body: 'Please check that URL is correct and and try again.',

  /**
   *  Email Inquiry
   */
  inquiry_header: 'INQUIRY PROTECTION',
  inquiry_content: `To view your transaction details we need your email address to verify for security purposes.`,

  /**
   *  Modal Error Message
   */
  error_title: `Something's not right..`,
  error_content: `Sorry we're experiencing some issues at the moment.`,
  error_content2: `Please try again after a few minutes.`,
  error_btn_label: `Okay`,
  verify_account_content: 'Please provide the correct Account Number.',

  /**
   *  Modal Confirmation Message
   */
  confirmation_title: `Header Title.`,
  confirmation_content: `Header Content .`,
  confirmation_btn_yes: `Yes`,
  confirmation_btn_no: `No`,
  confirmation_btn_cancel: `Cancel`,

  /**
   *  Search Message
   */
  search_not_found: `No Result Found`,

  /**
   *  Payment List Duplicate
   */
  account_duplicate_content: `Account number already exist.`,
  account_duplicate_btn_label: `Okay`,

  /**
   *  Biller Information not found
   */
  biller_not_found_content: `information not found.`,
  biller_not_found_btn_label: `Okay`,

  /**
   *  Status
   */
  status_pending: 'PENDING',
  status_success: 'SUCCESS',
  status_failed: 'FAILED',
  status_cancel: 'CANCEL',
  status_cancelled: 'CANCELLED',
  status_expired: 'EXPIRED',
  status_completed_payment: 'COMPLETED_PAYMENT',
  status_failed_payment: 'FAILED_PAYMENT',
  status_pending_posting: 'PENDING_POSTING',

  /**
   *  Email Inquiry
   */
  inquiry_in_progress: 'IN PROGRESS',
  inquiry_success: 'SUCCESSFUL',
  inquiry_failed: 'FAILED',
  title_status_in_progress: 'still in progress',
  title_status_success: 'successful',
  title_status_failed: 'failed',

  /**
   *  Acknowledgement Success
   */
  success_title: 'Payment Successful',
  failed_title: 'Payment Unsuccessful',
  cancelled_title: 'Payment Cancelled',
  status_redirect_count: "You will be redirected back to the Bayad Express's page after",

  /**
   *  Multiple Browser Detection
   */
  multiple_browser_title: 'Hold On!',
  multiple_browser_content: 'This payment page is already active in another window.',
  multiple_browser_content2: ' Please close this window and resume your previous session to proceed with your transaction.',

  /**
   *  Copy to Clipboard
   */
  copy_to_clipboard: 'Copied to clipboard',

  /**
   *  Acknowledgement Page For Wallet (Mobile)
   */
  content_title: 'Thank you KaBayad!',
};
