import React, { CircularProgress, Container, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import { getCategoryLogo } from '../../utils/HelperUtil';

const myStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    //borderTop: '1px solid rgb(224 224 224)',
  },
  categoryLogo: {
    backgroundColor: 'rgb(255 255 255)',
    width: 75,
    height: 75,
    verticalAlign: 'inherit',
    margin: 10,
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 87%), 0px 1px 1px 0px rgb(0 0 0 / 87%), 0px 1px 3px 0px rgb(0 0 0 / 87%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 70,
      height: 70,
    },
    [theme.breakpoints.down('xs')]: {
      width: 55,
      height: 55,
    },
  },
  iconArrowRight: {
    cursor: 'pointer',
    color: '#979797',
  },
  bold: {
    fontWeight: 600,
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCellLogo: {
    width: 90,
    height: 90,
    [theme.breakpoints.down('xs')]: {
      width: 65,
      height: 65,
    },
    [theme.breakpoints.down('sm')]: {
      width: 65,
      height: 65,
    },
  },
  tableCellBiller: {
    paddingLeft: 30,
  },
  loading: {
    color: '#F16122',
  },
  loadingContainer: {
    textAlign: 'center',
  },
  dataNotFound: {},
}));

const CategoryListTable = (props) => {
  const classes = myStyles();
  const { categoryList, handlerGetCategory } = props;

  return (
    <>
      {categoryList ? (
        <TableContainer data-testid='CategoryListTable'>
          <Table className={classes.tableContainer}>
            <TableBody>
              {categoryList
                .sort((a, b) => (a.category_order > b.category_order ? 1 : -1))
                .map((row, index) => (
                  <TableRow
                    className={classes.tableRow}
                    hover={true}
                    selected={false}
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => handlerGetCategory(row)}>
                    <TableCell align='left' padding='none' className={classes.tableCellLogo}>
                      <img className={classes.categoryLogo} src={getCategoryLogo(row.category, false)} alt={row.category}></img>
                    </TableCell>
                    <TableCell align='left' padding='none' className={classes.tableCellBiller}>
                      <Typography variant='subtitle1' className={classes.bold}>
                        {row.category}
                      </Typography>
                    </TableCell>
                    <TableCell align='right' padding='none'>
                      <KeyboardArrowRightSharpIcon className={classes.iconArrowRight} fontSize='large' />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Container className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />
        </Container>
      )}
    </>
  );
};

export default CategoryListTable;
