import { combineReducers } from 'redux';
import session from '../Session/SessionReducer';
import token from '../Token/TokenReducer';
import biller from '../Biller/BillerReducer';
import payment from '../Payment/PaymentReducer';
import transaction from '../Transaction/TransactionReducer';
import category from '../Category/CategoryReducer';

export default combineReducers({
  session,
  token,
  biller,
  payment,
  transaction,
  category,
});
