import processActions from '../../utils/ProcessActions';
import { GET_SESSION, GET_SESSION_SUCCESS, GET_SESSION_FAILED, GET_SIGNATURE_SUCCESS, GET_SIGNATURE_FAILED, GET_SIGNATURE } from './SessionTypes';
import { verifyUrl as verifyUrlService, getSignature as getSignatureService } from './SessionServices';
import { all, takeEvery, select } from '@redux-saga/core/effects';
import history from '../../utils/history';
import * as selectors from '../selectors';
import { terminateSession } from '../../utils/Auth';

function* verifyUrl({ id, name, timestamp, data, signature }) {
  const actions = Object.values({ id, name, timestamp, data, signature }).map((item) => item);
  yield processActions({
    params: [...actions],
    service: verifyUrlService,
    success: GET_SESSION_SUCCESS,
    failed: GET_SESSION_FAILED,
  });

  let sessionError = yield select(selectors.sessionError);
  if (sessionError.flag) {
    authFailed();
  }
}

function* getSignature({ id, name, data }) {
  const actions = Object.values({ id, name, data }).map((item) => item);
  yield processActions({
    params: [...actions],
    service: getSignatureService,
    success: GET_SIGNATURE_SUCCESS,
    failed: GET_SIGNATURE_FAILED,
  });
}

function authFailed() {
  terminateSession();
  history.push('/v1/0?msg=Please check that URL is correct and and try again.');
}

export default function* watchSessionRequests() {
  yield all([takeEvery(GET_SESSION, verifyUrl), takeEvery(GET_SIGNATURE, getSignature)]);
}
